<template>
    <div class="collection-manager pt-4 ps-4 pe-4 container">
        <div class="row">
            <div class="col-12 col-md-3">
               <div class="card">
                    <div class="card-header bg-primary text-white">
                        <h3 class="card-title">Accounts</h3>
                    </div>
                    <div class="card-body">

                        <p class="text-muted small" v-if="isMovingCollections">
                            To move your collection to a folder in another account, select the account first.
                        </p>
                        <div class="list-group">
                            <a 
                                href="#"
                                @click.prevent="setCurrentAccount({account})" 
                                class="list-group-item small" 
                                v-for="account in accounts" 
                                :key="account.id"
                                :class="{'current' : currentAccount && currentAccount.id == account.id}"
                            >
                                <i v-if="currentAccount && currentAccount.id == account.id" class="fa fa-check-circle text-primary"/>&nbsp;
                                {{account.name}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-9">
                <div class="card">
                    <div class="card-body">
                            <div class="d-flex align-items-center" v-if="selectedCollections.length">
                                <h6 class="mb-2">{{selectedCollections.length}} collection<span v-if="selectedCollections.length > 1">s</span> selected</h6>
                                <span v-if="selectedCollections.length && !isReviewer" class="ms-auto mb-2 collection-actions">
                                    <button class="btn btn-link btn-sm my-0" @click="isMovingCollections = !isMovingCollections">
                                        <span v-if="isMovingCollections">
                                            <i class="fa fa-times-circle"></i> Cancel Move
                                        </span>
                                        <span v-else>
                                            <i class="fa fa-arrow-right"></i> Move Collections
                                        </span>
                                    </button>
                                    <button class="btn btn-link btn-sm my-0" @click="destroySelectedCollections"><i class="fa fa-trash"></i> Delete Collections</button>
                                    
                                    
                                </span>
                            </div>
                            <h6 v-else>Collections in {{currentAccount.name}}</h6>
                            <recursive-folder-container 
                                :folder="rootFolder"
                                :is-root="true"
                                :level="0"
                                :is-moving-collections="isMovingCollections"
                                @collectionAdded="addCollection"
                                @collectionRemoved="removeCollection"
                                @createFolderWithNameInFolder="createFolderWithNameInFolder"
                                @deleteCollection="destroy"
                            />

                    </div>
                </div>

                <deactivated-collections class="mt-5" v-if="features.deactivatedCollections">
                </deactivated-collections>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import RecursiveFolderContainer from "@/components/Folders/CollectionFolderDisplay"
import DeactivatedCollections from '@/components/Collections/DeactivatedCollections'
import Roles from '@/utilities/Roles'

export default {
    mixins : [Roles],
    components : {
        RecursiveFolderContainer,
        DeactivatedCollections
    },
    computed : {
        ...mapGetters({
            accounts : 'allAccounts',
            currentAccount : 'currentAccount',
            folders : 'folders',
            features: 'accountFeatures',
            collections : 'collections'
        }),
        rootFolder(){
            return this.folders.find(f => this.currentAccount.root.includes(f.id));
        }
    },
    data(){
        return {
            isMovingCollections : false,
            selectedCollections : []
        }
    },
    mounted(){
        window.Events.$on('folderSelectedForCollections', ({folder}) => {
            let collections = this.selectedCollections.map(cId => {
                var collection = this.collections.find(c => cId.includes(c.id));
                return {...collection, folder : folder.id}
            })
            this.$store.dispatch('moveCollectionsToFolder', {collections, folder})

            collections.forEach(collection => {
                var url = '/collections/'+collection.id+'/parent';
                if ( ! collection.account && collection.account.includes(this.currentAccount.id)){
                    url = '/collections/'+collection.id+'/account';
                }

                this.$api.put(url, {folder : folder.id, account : this.currentAccount.id} )
                .catch(e => {
                    if (e.response && e.response.data){
                        this.networkError = e.response.data.description;
                    }
                })
            })

            this.isMovingCollections = false;
            this.selectedCollections = [];

            window.Events.$emit('selectedCollectionsMovedToNewFolder');
        })

    },
    methods : {
        ...mapActions(['setCurrentAccount']),
        destroy(collection){
            this.$store.dispatch('deleteCollection', {collection});            
            this.$api.delete("/collections/"+collection.id)
            .catch(e => {
                this.$store.dispatch('addCollection', {collection});
                if (e.response && e.response.data){
                    this.networkError = e.response.data.description;
                }
            });
            this.selectedCollections = this.selectedCollections.filter(selectedId => collection.id !== selectedId);
        },
        destroySelectedCollections(){
            this.selectedCollections.map(cId => this.collections.find(c => c.id === cId)).forEach(this.destroy);
            this.selectedCollections = [];
        },
        addCollection(collection){
            this.selectedCollections.push(collection.id);
        },
        removeCollection(collection){
            this.selectedCollections.splice(this.selectedCollections.indexOf(collection.id), 1)
        },
        createFolderWithNameInFolder({name, folder}){
            
            let parentFolder = folder;

            this.$api.post('/accounts/'+this.currentAccount.id+"/folders", {
                    name,
                    parent : parentFolder.id
                })
                .then(response => {
                    let folder = {
                        ...response.data, 
                        account : this.currentAccount.id
                    };

                    this.$store.dispatch('addFolder', {folder});
                    this.$store.dispatch('addFolderToFolder', {
                            folder : folder,
                            parent: parentFolder,
                            account : this.currentAccount
                    });

                }).catch(e => {
                    if (e.response && e.response.data){
                        this.networkError = e.response.data.description;
                    }
                });
        },
    }
}
</script>

<style>
</style>
