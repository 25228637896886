<template>

</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "CollectionQualityChecksBase",
    props: {
        collectionId: String
    },
    computed: {
        ...mapGetters({
            apiHost: 'apiHost',
            account: 'account',
            learnUrls: 'learnUrls'
        })
    },
    data() {
        return {
            checks: [],
            checksEnabled: []
        }
    },
    watch: {
        collectionId() {
            this.load()
        }
    },
    methods: {
        async load() {
            this.checks = await this.$api.get(`/accounts/${this.account.id}/quality-checks`).then(r=>r.data)
            try {
                this.checksEnabled = await this.$api.get(`/collections/${this.collectionId}/quality-checks`).then(r=>r.data) || []
            } catch(e) {
                console.error(e)
            }
        },
        async save() {
            await this.$api.put(`/collections/${this.collectionId}/quality-checks`, {checks:this.checksEnabled})
        },
    },
    mounted() { this.load() }
}
</script>

<style scoped>

</style>
