<template>
    <div>
        <folder-navigation-actions type="collections" v-if="accountFeatures.newCollectionUx"></folder-navigation-actions>
        <template v-else>
        <sidebar-new-collection-button v-if="accountFeatures.newCollectionUx" />
        <h3 class="subtitle d-flex">
            <span>
                Collections
            </span>
            <router-link 
                to="/collections" 
                class="text-danger sidebar-link ms-auto"
            >
                Manage
            </router-link>
        </h3>
        </template>
        <collections-by-folder :folder="folder" :is-root="true" />

        <sidebar-new-collection-button v-if="!accountFeatures.newCollectionUx" />
    </div>
</template>

<script>
import CollectionsByFolder from "./CollectionsByFolder"
import { mapGetters } from 'vuex';
import Roles from '@/utilities/Roles'
import SidebarNewCollectionButton from "@/components/Collections/SidebarNewCollectionButton";
import FolderNavigationActions from "@/components/Folders/FolderNavigationActions";

export default {
    name: "SidebarList",
    mixins : [Roles],
    components : {
        FolderNavigationActions,
        CollectionsByFolder, SidebarNewCollectionButton

    },
    computed : {
        ...mapGetters({
            folders : 'folders',
            account : 'currentAccount',
            accountFeatures: 'accountFeatures'
        }),
        folder(){
            return this.folders.find(f => this.account.root.includes(f.id));
        }
    },
    methods : {
        showCollectionBuilder(){
            window.Events.$emit('showCollectionCreationModal');
        }
    }
}
</script>

