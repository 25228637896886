<template>
    <div>
        <div class="mb-3">
            <label class="label-calm">
                Brand
            </label>
            <uc-select v-model="brandId" :items="brandOptions"></uc-select>
            <progress-bar :percent="brandsLoadedPercent" v-if="brandsLoadedPercent < 100"></progress-bar>
        </div>
        <div v-if="campaign" class="mb-3">
            <span>Currently using legacy Sniply Campaign: {{campaign.name}}, please consider selecting a CTA from below.</span>
        </div>
        <div class="mb-3">
            <label class="label-calm">
                CTA
            </label>
            <spinner v-if="loadingCtas" class="dropdown"></spinner>
            <uc-select :items="ctaOptions" v-model="ctaId"
                                    class="mt-2">
            </uc-select>
            <div v-if="cta && cta.image_action && cta.image_action.image_url">
                <img :src="cta.image_action.image_url" style="max-width: 100%"/>
            </div>
            <div v-else-if="cta && cta.image_url">
                <img :src="cta.data.image_url"/>
            </div>
        </div>
        <div class="mb-3">
            <label class="label-calm">
                Smart Seller Mapping
            </label>
            <uc-select :items="mappingOptions" v-model="mappingId"
                       class="mt-2">
            </uc-select>
        </div>
    </div>
</template>

<script>
import Spinner from '@/components/Controls/Spinner.vue'
import {mapActions, mapGetters} from "vuex";
import {SniplyConnection} from "@/services/Sniply";
import UcSelect from "@/components/Controls/UpcontentSelect.vue";
import ProgressBar from "@/components/Controls/PercentProgressBar.vue";
export default {
    name: "SniplyPowerup",
    components: {ProgressBar, UcSelect, Spinner},
    props: {
        value: {type: Object}
    },
    created() {
          this.Sniply = new SniplyConnection(this)
    },
    data() {
        return {
            sniplyCampaignsLoaded: false,
            sniplyCampaigns: [],
            brandId: null,
            ctaId: null,
            mappingId: null,
            sniplyCampaign: null
        }
    },
    computed: {
        ...mapGetters({
            apis: "externalApis",
            account: 'account',
            collection: 'currentCollection',
            brands: "sniplyBrands",
            brandsLoadedPercent: "sniplyBrandLoadPercent",
            mappings: "sniplyMappings",
            legacyCampaignsById: "sniplyLegacyCampaignsById",
            loadingCtas: "sniplyLoadingCtas",
            allCtas: "sniplyCtasByBrand"
        }),
        ctas() {
            return this.allCtas[this.brandId] || []
        },
        brandOptions() {
            return this.brands.map(x => ({
                value: x.id,
                description: x.name,
                iconUrl: x.photo_url
            }))
        },
        ctaOptions() {
            return this.ctas.map(x => ({
                value: x.id,
                description: x.name,
                iconUrl: x.image_url,
                iconClass: x.image_url ? '' : 'fa fa-circle-o px24'
            }))
        },
        cta() {
            return this.ctas && this.ctas.find(x => x.id === this.ctaId)
        },
        campaign() {
            return this.legacyCampaignsById[this.campaignId]
        },
        mappingOptions() {
            return this.mappings.map(x => ({
                value: x.id,
                description: x.name,
            }))
        },
        convertedSniplySettings() {
            return {
                sniply_cta_id: this.ctaId,
                sniply_campaign: this.campaignId,
                brand: this.brandId,
                param_mapping_id: this.mappingId
            }
        },
    },
    methods: {
        async loadSniplySettings() {
            if (!(this.apis['sniply'] && this.collection && this.collection.id)) {
                return Promise.resolve();
            }
            this.networkActivity++;
            try {
                let response = await this.$api.get(`/collections/${this.collection.id}/sniply-settings`);
                if (response && response.data) {
                    let settings = response.data.settings;
                    this.on = !!settings && !!(settings.sniply_campaign_id ||settings.sniply_cta_id);
                    this.campaignId = settings && settings.sniply_campaign_id;
                    this.ctaId = settings && settings.sniply_cta_id;
                    this.mappingId = settings && settings.param_mapping_id;
                    this.brandId = settings && settings.brand;
                } else {
                    this.sniplyOn = false;
                    this.ctaId = null;
                    this.campaignId = null;
                    this.mappingId = null;
                    this.brandId = null;
                }
            } finally {
                this.networkActivity--;
            }
        },
        ...mapActions({
            loadBrands: "loadSniplyBrands",
            loadCtas: "loadSniplyCtas",
            loadMappings: "loadSniplyMappings",
            loadLegacyCampaign: "loadSniplyLegacyCampaign"
        }),

        async saveSniply() {
            if (!this.features.sniply) return Promise.resolve();
            if (this.sniplyOn && (this.sniplyCampaign || this.sniplyCta))
                return this.$api.post('/collections/' + this.collection.id + '/sniply-settings',
                    this.convertedSniplySettings);
            if (this.userRemovedSniply())
                return this.$api.delete('/collections/' + this.collection.id + '/sniply-settings').catch(() => {
                });
            else return Promise.resolve(); // User either
        },
        userRemovedSniply() {
            return !this.sniplyOn;
        },
        validate() {
            this.errorMessages = [];
            if (this.sniplyOn) {
                if (!this.sniplyCampaign) this.errorMessages.push("Must select a Sniply campaign");
            }

        },
    },
    watch: {
        brandId() {
            return this.loadCtas({brand: this.brandId})
        },
        value() {
            if (this.value) {
                let settings = this.value;
                this.on = !!settings && !!(settings.sniply_campaign_id ||settings.sniply_cta_id);
                this.campaignId = settings && settings.sniply_campaign_id;
                this.ctaId = settings && settings.sniply_cta_id;
                this.mappingId = settings && settings.param_mapping_id;
                this.brandId = settings && settings.brand;
            } else {
                this.sniplyOn = false;
                this.ctaId = null;
                this.campaignId = null;
                this.mappingId = null;
                this.brandId = null;
            }
            if (this.campaignId) {
                this.loadLegacyCampaign({
                    campaignId: this.campaignId
                })
            }
        }
    },
    async mounted() {
        await this.loadBrands({});
        await this.loadMappings();
    }
}
</script>

<style>
    .px24 {
        font-size: 24px;
    }
</style>
