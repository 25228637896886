<script>

import zxcvbn from 'zxcvbn'
import { mapActions, mapGetters } from 'vuex';
import {extensionHomeRoute} from "@/services/BrowserExtension";
import {isBrowserExtension} from "@/services/Context";

export default {
    props : ['iToken'],
    data(){
        return {
            password : '',
            name : '',
            email : '',
            du: '',
            newPassword : '',
            networkActivity : false,
            errorMessage : false,
        }
    },
    computed : {
        goodPassword(){
            return zxcvbn(this.password).score > 2
        },
        passesValidation(){
            return this.goodPassword && this.name && this.email
        },
        accountTopics(){
            if (this.currentAccount)
                return this.topics.filter(t => t.account.includes(this.currentAccount.id));

            return [];
        },
        ...mapGetters({
            currentTopic : 'currentTopic',
            currentAccount : 'currentAccount',
            topics : 'topics',
            signupPlan: 'signupPlan',
        })
    },
    mounted(){
        if (this.iToken){
            this.setInviteToken({token: this.iToken});
        } else {
            this.setInviteToken({token: false});
        }
    },
    methods : {
        ...mapActions({
            setToken : 'setAuthToken',
            setInviteToken : 'setInviteToken'
        }),
        updatePassword(){

            this.networkActivity = "Resetting password..."
            this.$api.put('/password', {
                new_password : this.newPassword,
                code : this.token
            })
            .then((response) => {
                this.networkMessage = "Done!"
                let token = response.data;
                setTimeout(() => {
                    this.logUserInWithToken(token);
                }, 500)
            })
            .catch(this.handleError);
        },
        logUserInWithToken(token){
            this.setToken({token});

            this.networkActivity = "Syncing folders and topics..."
            this.$api.get('/profile').then(async resp => {
                let user = resp.data
                await this.$store.dispatch('userLoggedInWithToken', { user, token });

                if (window.profitwell)
                    window.profitwell('start', { 'user_email': user.email })

            }).catch(this.handleError)
        },
        logIn(){
            this.errorMessage = '';
            this.networkActivity = "Logging in..."
            this.$api.post('/sessions', this.$data).then(response => {
                let token = response.data;
                this.logUserInWithToken(token);

            }).catch(this.handleError)
        },
        attemptToSetCurrentTopic(){

            if (this.currentTopic && this.$route.path == "/login") {
                let route = isBrowserExtension() ? extensionHomeRoute() : "/";
                this.$router.push(route);
                return;
            }

            if (this.$route.path != "/login"){
                return;
            }


            setTimeout(() => {
                if ( ! this.currentTopic && this.accountTopics.length > 0){
                    this.$store.dispatch('setCurrentTopic', {topic : this.accountTopics[0]});
                    let route = isBrowserExtension() ? extensionHomeRoute() : "/";
                    this.$router.push(route);
                } else {
                    this.attemptToSetCurrentTopic();
                }
            }, 1000);


        },
        handleError(e){
            if (e.response && e.response.data){
                switch(e.response.status){
                    case 401:
                        this.errorMessage = "Incorrect password"
                    break;
                    default:
                        this.errorMessage = e.response.data.description
                    break;
                }


            }

            this.password = '';
            this.newPassword = '';
            this.networkActivity = false;

        },
        createAccount(){
            this.networkActivity = "Creating account..."
            this.$api.post('/users', {name : this.name, email: this.email, password : this.password})
            .then(response => {
                let token = response.data;
                this.setToken({token});
                this.networkActivity = "Planning your welcome party..."
                this.$api.get('/profile').then(resp => {
                    let user = resp.data

                    this.$gtm.trackEvent({
                        category: 'UserActivity',
                        event : 'Sign Up',
                        action: 'click',
                        label: 'New User Signed Up',
                        value: user.id
                    });

                    this.$ga.event({
                        eventCategory: 'UserActivity',
                        eventAction: 'Sign Up',
                        eventLabel: 'Sign Up',
                        eventValue: user.id
                    })

                    this.$store.dispatch('userLoggedInWithToken', { user, token })
                    this.registerSignupForReferral(user)
                })
            })
            .catch(this.handleError)
        },
        registerSignupForReferral(user) {
            try {
                // eslint-disable-next-line no-undef
                $FPROM.trackSignup({
                    email: user.email,
                },() => {console.log(`Sign up logged for referral program (${user.email})`)});
            } catch (e) {
                console.error(e)
            }
        },
        sendResetEmail(){
            this.networkActivity = "Sending request for reset email..."
            this.$api.put('/password-reset', {email : this.email})
            .then(() => {
                this.networkActivity = 'Done! Check your email for the reset link';
            })
            .catch(this.handleError)
        }
    },
    render(h){
        return h()
    }
}
</script>
