<template>
    <nav class="navbar navbar-expand-lg newUx justify-content-between"
         v-show="navMode !== 'none'">
        <div class="container ms-4 me-4 ps-0 pe-0">
            <a :href="marketingSiteUrl" class="navbar-brand text-decoration-none" to="/">
                <span>
                    <app-logo></app-logo>
                </span>
            </a>
        </div>
    </nav>
</template>

<script>


import {mapGetters} from 'vuex';
import UserNavigationItems from "@/components/Navigation/UserNavigationItems"
import UpcontentLogo from '@/components/Navigation/Logo'
import RouteContext from '@/utilities/RouteContext'
import BackToButton from "../components/Suggestions/ManageSuggestion/BackToButton";
import AppLogo from "../components/Navigation/AppLogo";

export default {
    name: "NavigationForAuthentication",
    components: {
        AppLogo,
        UpcontentLogo
    },
    mixins: [RouteContext],
    computed: {
        ...mapGetters({
            isDealerVenom: 'isDealerVenom',
            isSocialAdvisors: 'isSocialAdvisors',
            vendor: 'vendor',
            subvendor: 'subvendor',
            loggedInUser: 'currentUser',
            currentAccount: 'account',
            accounts: 'allAccounts',
            loadingAccounts: 'accountsBeingLoaded',
            subscriptions: 'subscriptions',
            features: 'features',
            appByUpcontent: 'appByUpcontent',
            subscriptionPlan: 'subscriptionPlan',
            isAmplify: 'isAmplify',
            isEmbed: 'isEmbed',
            isHearsay: 'isHearsay',
            accountFeatures: 'accountFeatures',
            marketingSiteUrl: 'marketingSiteUrl'
        }),
        isNonFreeAccount() {
            return this.subscriptionPlan;
        },
        // showBackToCollectionLink() {
        //     return this.$route.name !== 'Suggestions' && this.hasSuggestions && this.hasSuggestions.id;
        // },
    },
    methods: {}
}
</script>

<style scoped>
.navbar {
    transition: background-color 600ms ease-out;
}

.dealer-venom-logo {
    width: 198px;
    height: 25px;
}

.social-adv-logo {
    width: 152px;
    height: 30px;
}
</style>
