let state = {
    classifiers: [],
    contentPolicies: [],
    contentPolicy: {},
    classifierRules: [],
    collectionContentPolicies: {},
}

const getters = {
    currentClassifiers: state => state.classifiers,
    currentContentPolicies: state => state.contentPolicies,
    currentContentPolicy: state => state.contentPolicy,
    currentclassifierRules: state => state.classifierRules,
    currentCollectionContentPolicies: state => state.collectionContentPolicies,
}

const actions = {
    async loadAccountClassifiers({commit, getters})  {
        const resp = await window.$app.apis.contentPolicy.accountsAccountIdContentClassifiersGet(getters.currentAccount.id);
        const mappedClassifiers = resp.data.map(c => ({
            id: c.id,
            name: c.name,
            ruleCombinationOperator: c.rule_combination_operation
        }));

        await commit(mutations.SET_CLASSIFIERS.name, {classifiers: mappedClassifiers});
        return mappedClassifiers;
    },
    async loadRulesForClassifier({commit, getters}, classifierId) {
        const resp = await window.$app.apis.contentPolicy.contentClassifiersClassifierIdRulesGet(classifierId);
        console.log('* resp', resp)
        const mappedRules = resp.data.map(r => ({
            id: r.id,
            field: r.field,
            operation: r.operation,
            ruleValue: r.value,
        }));

        await commit(mutations.SET_CLASSIFIER_RULES.name, {classifierRules: mappedRules});
        return mappedRules;
    },
    async loadAccountContentPolicies({commit, getters}) {
        const resp = await window.$app.apis.contentPolicy.accountsAccountIdContentPoliciesGet(getters.currentAccount.id);
        const mappedContentPolicies = resp.data.map(p => ({
            id: p.id,
            name: p.name
        }));
        await commit(mutations.SET_CONTENT_POLICIES.name, {contentPolicies: mappedContentPolicies});
        return mappedContentPolicies;
    },
    async loadContentPolicy({commit, getters}, selectedPolicyId) {
        const resp = await window.$app.apis.contentPolicy.contentPoliciesPolicyIdGet(selectedPolicyId);
        const data = resp.data
        await commit(mutations.SET_CONTENT_POLICY.name, {contentPolicy: data});
        return data;
    },
    async loadCollectionContentPolicies({commit, getters}, collectionId) {
        const resp = await window.$app.apis.contentPolicy.collectionsCollectionIdContentPolicyGet(collectionId);
        const data = resp.data
        await commit(mutations.SET_COLLECTION_CONTENT_POLICIES.name, {collectionContentPolicies: data});
        return data;
    },
}

const mutations = {
    SET_CLASSIFIERS: (state, {classifiers}) => {
        state.classifiers = classifiers;
    },
    SET_CLASSIFIER_RULES: (state, {classifierRules}) => {
        state.classifierRules = classifierRules;
    },
    SET_CONTENT_POLICIES: (state, {contentPolicies}) => {
        state.contentPolicies = contentPolicies;
    },
    SET_CONTENT_POLICY: (state, {contentPolicy}) => {
        state.contentPolicy = contentPolicy;
    },
    SET_COLLECTION_CONTENT_POLICIES: (state, {collectionContentPolicies}) => {
        state.collectionContentPolicies = collectionContentPolicies;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
