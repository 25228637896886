const state = {
    appVersion : false
}

const getters = {
    appVersion : state => state.appVersion
}

const actions = {
    setAppVersion : ({commit}, {number, requiresReload}) => {
        commit("SET_CURRENT_APP_VERSION", {number, requiresReload})
    }
}

const mutations = {
    SET_CURRENT_APP_VERSION : (state, { number, requiresReload }) => {
        state.appVersion = {number, requiresReload}
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}