<template>
    <div>
        <h4>Create a new account</h4>

        <input class="form-control mb-3" placeholder="What should we name this account?" v-model="name" />

        <div v-if="selectedPlan" id="add-payment-info">
            <h4>Add your payment information</h4>
            <br/>
            <credit-card 
                class="credit-card-entry-box"
                ref="creditCard"
                v-if="!goodCard"
                :account="newAccount"
                @savingCard="networkActivity = true"
                @cardError="handleError"
                button-text="Start Free Trial" 
                @cardResponseReceived="cardSaved"
            />
            <br/>
            <div class="alert alert-danger" v-if="networkError">
                {{networkError}}
            </div>
        </div>

        <div class="alert alert-blue" v-if="networkActivity">
            {{networkActivity}}
        </div>

        <plan-selector 
            v-if="name"
            :starting-group-index="1"
            :hideFreePlan="true"
            :hideChangeButton="true"
            @planSelected="planSelected" 
        />


           
    </div>
</template>

<script>

import PlanSelector from '@/components/Subscriptions/PlanSelector'
import CreditCard from '@/components/Payments/CreditCardEntry'
import CreditCardDisplay from '@/components/Payments/CreditCardDisplay'

export default {
    props : [],
    data(){
        return {
            name : '',
            selectedPlan : false,
            newAccount : false,
            networkError : '',
            goodCard : false,
            networkActivity : false
        }
    },
    components : {
        PlanSelector, CreditCard, CreditCardDisplay
    },
    mounted(){

    },
    methods : {
        planSelected(plan){
            this.selectedPlan = plan;
        },
        cardSaved({response}){
            this.networkError = false;
            this.addAccount(response)
        },
        addAccount(data){
            this.$api.post('/accounts', {name : this.name}).then(accountResp => {
                let account = accountResp.data;
                this.newAccount = account;
                this.networkActivity = "Checking card status...";
                this.$api.put(`/accounts/${account.id}/payment-method`, {token : data.token.id})
                .then(  () => {
                    this.networkActivity = "Creating account..."
                    this.goodCard = true;
                    this.setSubscriptionPlan().then(response => {
                        let subscription = response.data;
                        let newAccount = {...account, plan: subscription.plan_id};
                        this.$store.dispatch('addAccount', {account : newAccount});
                        this.$store.dispatch('addSubscriptionToAccount', {account: newAccount, subscription});
                        this.networkActivity = "Success! Preparing the Welcome Screen...";
                        this.$api.get(newAccount.root).then(response => {
                            let folder = response.data;
                            this.$store.dispatch('addFolder', {folder});
                            setTimeout( () => {
                                this.$emit('accountCreated', {account: newAccount})
                            }, 500);
                        })
                    });
                })
                .catch(this.handleError);
            })
        },
        setSubscriptionPlan(){
            var planArray = this.selectedPlan.split('_');
            let interval = planArray.pop();
            let pckge = planArray.join('_');
            let request = { package : pckge, interval }

            return this.$api.put(`/accounts/${this.newAccount.id}/subscription`, request)
        },
        handleError(error){
            if (this.$refs.creditCard)
                this.$refs.creditCard.networkActivity = false;

            this.networkActivity = false;
            if(error.response){
                switch(error.response.status){
                    case 404:
                        this.noCard = true;
                    break
                }
            } else {
                this.networkError = "We cannot process your card at this time :("
            }

            if (this.newAccount){
                this.$api.delete(`/accounts/${this.newAccount.id}`);
                this.newAccount = false;
            }

        }
    }
}
</script>

<style>
</style>
