<template>
    <main class="ms-4 me-4 mt-4">
        <div class="fixed-list-sidebar-with-max-details">
            <div class="sidebar rounded overflow-x-hidden me-2" style="overflow-y: auto">
                <details open>
                    <summary>Email Templates</summary>
                    <button class="btn btn-primary full-width" @click="createNewTemplate()">New Template</button>
                    <ul class="list-group">
                        <li v-for="template in templates" :key="template.id" class="list-group-item small ps-2 pe-2">
                            <div class="mb-2">
                                {{template.name}}
                                <button class="btn btn-sm btn-primary float-end" type="button" @click="openEditor(template)"  >
                                    <i class="fa fa-edit"></i> Edit
                                </button>
                            </div>
                            <div>
                                <button class="btn btn-sm btn-link text-secondary ms-0 ps-0 me-2 pe-0" type="button" @click="rename(template)">
                                    <i class="fa fa-edit"></i> Rename
                                </button>
                                <button class="btn btn-sm btn-link text-secondary ms-0 ps-0 me-2 pe-0" type="button" @click="copy(template)" >
                                    <i class="fa fa-copy"></i> Copy
                                </button>
                                <button class="btn btn-sm btn-link text-danger me-0  ms-0 ps-0 pe-0" style="vertical-align: center" type="button" @click="del(template)">
                                    <i class="fa fa-trash"></i> Delete
                                </button>
                            </div>
                        </li>
                    </ul>
                </details>
                <details>
                    <summary>Merge Tags</summary>
                    <ul class="list-group">
                        <li v-for="field in fields" :key="field.value" class="list-group-item">
                            <label class="small">
                                {{field.label}}
                                <div class="input-group">
                                    <input type="text" readonly :value="field.value" class="form-control form-control-sm">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary btn-sm" @click="copyField"><i class="fa fa-copy"></i></button>
                                    </div>
                                </div>
                            </label>
                        </li>
                    </ul>
                </details>
            </div>
            <BeeEditor class="details rounded overflow-hidden" :template-id="currentTemplate && currentTemplate.id"></BeeEditor>
        </div>
    </main>
</template>

<style lang="scss">
    .fixed-list-sidebar-with-max-details {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        .sidebar {
            width: 200px;
            min-width: 250px;
            flex-grow: 1;
            @media screen and (min-width: 950px) {
            }
            max-height: calc(100vh - 92px);
            /*&[open]>summary {*/

            /*}*/
        }
        .details {
            flex-grow: 100;
            min-width: 600px;
            min-height: calc(100vh - 250px);
            max-height: calc(100vh - 92px);
            overflow: scroll;
        }
    }
</style>

<script>
    import Modal from "@/components/Controls/Modal";
    import BeeEditor from "@/components/Integrations/Bee/BeeEditor";
    import HasBeeTemplates from '@/components/Integrations/Bee/HasBeeTemplates'
    import {mapGetters} from 'vuex'
    export default {
        mixins: [HasBeeTemplates],
        components: {BeeEditor, Modal},
        computed: {
            ...mapGetters({
                account: 'account',
            })
        },
        data() {
            return {
                currentTemplate: false,
                fields: [
                    {value: '{{article.title}}', label: 'Article Title'},
                    {value: '{{article.summary}}', label: 'Article Summary'},
                    {value: '{{article.publisher}}', label: 'Article Publisher'},
                    {value: '{{article.dd}}', label: 'Article Day (01)'},
                    {value: '{{article.d}}', label: 'Article Day (1)'},
                    {value: '{{article.mm}}', label: 'Article Month (01)'},
                    {value: '{{article.m}}', label: 'Article Month (1)'},
                    {value: '{{article.Month}}', label: 'Article Month (January)'},
                    {value: '{{article.Mon}}', label: 'Article Month (Jan)'},
                    {value: '{{article.DayOfWeek}}', label: 'Article Day of Week (Sunday)'},
                    {value: '{{article.amplify_link}}', label: 'Article Amplify Link'},
                    {value: '{{article.link}}', label: 'Article Shortened URL'},
                    {value: '{{article.source_link}}', label: 'Article Raw Source URL'},
                    {value: '{{dd}}', label: 'Newsletter Day (01)'},
                    {value: '{{d}}', label: 'Newsletter Day (1)'},
                    {value: '{{mm}}', label: 'Newsletter Month (01)'},
                    {value: '{{m}}', label: 'Newsletter Month (1)'},
                    {value: '{{Month}}', label: 'Newsletter Month (January)'},
                    {value: '{{Mon}}', label: 'Newsletter Month (Jan)'},
                    {value: '{{DayOfWeek}}', label: 'Newsletter Day of Week (Sunday)'},
                    {value: '{{article.encoded_title}}', label: 'URL Encoded Article Title'},
                    {value: '{{article.encoded_summary}}', label: 'URL Encoded Article Summary'},
                    {value: '{{article.encoded_link}}', label: 'URL Encoded Article Shortened URL'},
                ]
            }
        },
        mounted() {
            console.log('ContentTemplates.mounted')
            this.refresh();
        },
        methods: {
            openEditor(template) {
                this.currentTemplate = template;
            },
            async del(template) {
                if (confirm(`Are you sure you want to delete the template ${template.name}`)){
                    try {
                        await this.$api.delete(`/content-templates/${template.id}`);
                    } catch (e) {
                        if (e.response.status == 409) {
                            alert('This template is in use on a collection delete the collection campaign first')
                        }
                    }
                    this.refresh();
                }
            },
            async rename(template) {
                template.name = prompt("Enter a name for this template");
                await this.$api.put(`/content-templates/${template.id}`, template);
                this.refresh();

            },
            async copy(template) {
                let newTemplate = await this.createNewTemplate(template);
                let templateData = await this.$api.get(`/content-templates/${template.id}/bee/data`).then(r => r.data);
                let templateContent = await this.$api.get(`/content-templates/${template.id}/template`).then(r => r.data);
                await this.saveJson(newTemplate, JSON.stringify(templateData));
                await this.saveHtml(newTemplate, templateContent);
                await this.refresh();
                this.openEditor(newTemplate);
            },
            async createNewTemplate(fromTemplate){
                let template = {
                    name: '',
                    content_type: 'selections',
                    content_destination: 'email',
                    template_type: 'bee',
                    file_type: 'html',
                    config: {
                        read_article_text: '',
                        show_read_button: false,
                        see_more_button_text: '',
                        show_see_more_button: false,
                        show_email_button: false,
                        email_article_text: '',
                        // email_article_subject: '',
                    },
                    layout: 1,
                    account_id: this.account.id,
                };
                Object.assign(template, fromTemplate)
                template.name =  prompt("Enter a name for this template");
                let resp = await this.$api.post(`/accounts/${this.account.id}/content-templates`, template);
                template.id = resp.data.id;
                await this.refresh();
                if (!fromTemplate) {
                    await this.refresh();
                    this.openEditor(template);
                }
                return template;

            },
            async saveJson(template, jsonFile) {
                console.log(jsonFile)
                await this.saveFile('email.json', jsonFile, `/content-templates/${template.id}/bee/data`)
            },
            async saveHtml(template, htmlFile) {
                console.log(htmlFile)
                await this.saveFile('email.html', htmlFile, `/content-templates/${template.id}/template`)
            },
            async saveFile(filename, content, path) {
                let blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
                let file = new File([blob], filename);
                const formData = new FormData();
                const fileName = filename.replace(/[\W.]+/g, '-');
                formData.append('file', file, fileName);
                let result = await this.$api.post(path, formData);
                return result
            },
            copyField(event) {
                let input = event.target.parentElement.previousElementSibling;
                input.select();
                document.execCommand("Copy");

            }
        },
    }
</script>
