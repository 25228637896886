<template>
    <div>
        <div class="padding-0 margin-0">
            <div class="row" v-if="tags && tags.length < tags.length">
                <div class="col">
                    <a
                        href="#"
                        @click.prevent.stop="clearSearch"
                        class="sidebar-link ms-auto">Show All</a>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label class="mb-1">
                        <!-- I know this looks stupid but for the sake of your
                        sanity don't try to fix it without asking matt why it's here.
                        Being real he doesn't know why it's needed but he can tell
                         you what breaks when you take it out. -->
                        <input type="checkbox"
                               class="input-checkbox margin-right-5"
                               :checked="favoritesOnly"
                               @change="favoritesOnly = !favoritesOnly"/>&nbsp;
                        <span>
                            <i v-if="favoritesOnly" class="fa fa-star"></i>
                            <i v-else class="fa fa-star-o me-1"></i>
                            Show Favorites Only
                        </span>
                    </label>
                </div>
            </div>
            <div v-if="features.imageOnlyInSuggestions" class="row">
                <div class="col-12">
                    <label class="mb-1">
                        <input type="checkbox"
                               class="input-checkbox margin-right-5"
                               v-model="required"
                               :value="'image'"/>&nbsp;
                        <span>
                    <i class="fa fa-image me-1"></i>
                    Only Show Sources with Images
                    </span>
                    </label>
                </div>
                <div class="col-12 ps-4 pe-4 text-muted small" v-if="required.includes('image')">
                    * The ability to filter sources without images will vary on
                    image type and publisher restrictions.
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="mb-1">
                        <input type="checkbox"
                               class="input-checkbox margin-right-5"
                               v-model="minFullTextLength"
                               >&nbsp;
                        <span>
                        <i class="fa fa-font me-1"></i>
                        Exclude Thin Content
                        </span>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="mb-1">
                        <input type="checkbox"
                               class="input-checkbox margin-right-5"
                               v-model="excludePaywalled"
                        >&nbsp;
                        <span>
                        <i class="fa fa-usd me-1"></i>
                        Exclude Paywall Content
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="filter-suggestion-tags">
            <progress-bar v-if="isLoadingTags"></progress-bar>
            <ul class="no-bullets ps-0" v-else>
                <li class="filter-item d-flex align-items-start"
                    v-for="tag in pagedTags"
                    :key="'publisher-'+tag.key">
                    <label @click.stop style="color: var(--bs-primary)" class="mb-1">
                            <input
                                @change="update()"
                                type="checkbox"
                                class="input-checkbox margin-right-5"
                                :value="tag.key"
                                v-model="tags"
                                slot="before"

                            >&nbsp;
                        <i :class="`icon-dynamic ${tag.iconClass}`" class="me-1"></i>
                        {{ tag.filterLabel || ((tag.label || '') + ' Only') }}
                    </label>
                    <span class="ms-auto badge bg-secondary">{{ tag.count }}</span>
                </li>
            </ul>
            <paginator :extraSmall="true"
                       :small="true"
                       :size="pageSize"
                       :items="filteredTags"
                       @update="pagedTags = $event"/>
            <fieldset v-for="group in groupedTags" :key="group.groupKey" class="radio-group filter-item">
                <legend>{{ group.groupLabel }}</legend>
                <ul class="no-bullets ps-0">
                    <li class="d-flex align-items-start" v-for="tag in group.tags" :key="'publisher-'+tag.key">
                        <label @click.stop
                               style="color: var(--bs-primary)"
                               class="mb-1"
                               :class="tag.tagClass && tag.tagClass.replace('bg', 'text') || ''">
                            <input
                                @change="update()"
                                type="checkbox"
                                class="input-checkbox margin-right-5"
                                :value="tag.key"
                                v-model="tags"
                                slot="before"

                            >&nbsp;
                            <i :class="`icon-dynamic ${tag.iconClass}`" class="me-1"></i>
                            {{ tag.filterLabel || (tag.label || '') }}
                        </label>
                        <span class="ms-auto badge bg-secondary">{{ tag.count }}</span>
                    </li>
                </ul>
            </fieldset>

        </div>
    </div>
</template>

<script>

// @click.right.prevent="tagShowingMessage = publisher" <-- Use this for the right-click-to-remove functionality

import {mapGetters} from 'vuex';

import Base from './FilterMixin'
import ProgressBar from "@/utilities/ProgressBar"
import Paginator from '@/utilities/Paginator'
import SuggestionTag from "@/components/Suggestions/SuggestionTag";
import {formatTag} from "../../../services/entity-tags";

export default {
    mixins: [Base],
    components: {SuggestionTag, ProgressBar, Paginator},
    props: ['value', 'hasContentId', 'hasContentType'],
    data() {
        return {
            allTags: [],
            tagsSearch: '',
            pageSize: 10,
            tags: this.value.tags || [],
            required: this.value && this.value.required || [],
            tagShowingMessage: false,
            pagedTags: [],
            searchQuery: '',
            isLoadingTags: false,
            favoritesOnly: this.value.favorites || false,
            valueChanging: false,
            minFullTextLength: Boolean(this.value && this.value.minFullTextLength),
            excludePaywalled: Boolean(this.value && this.value.excludePaywalled),
        }
    },
    watch: {
        value() {
            //console.debug('tags.value.change.before', this.value, this)
            this.valueChanging = true;
            this.tags = this.value.tags || [];
            this.required = this.value.required || [];
            this.favoritesOnly = this.value.favorites || false;
            this.valueChanging = false;
            this.minFullTextLength = Boolean(this.value.minFullTextLength);
            this.excludePaywalled = Boolean(this.value.excludePaywalled);
            //console.debug('tags.value.change.after', this.value, this)
        },
        required() {
            this.update()
        },
        favoritesOnly() {
            this.update()
        },
        hasContentId() {
            this.load()
        },
        minFullTextLength() {
            this.update()
        },
        excludePaywalled() {
            this.update()
        },
    },
    computed: {
        ...mapGetters({
            features: 'features',
            account: 'account'
        }),
        // calculatedTagList(){
        //     return this.allSoloTagsSorted.filter(tag => {
        //         return this.tags.indexOf(tag.key) !== -1
        //     })
        // },

        filteredTags() {
            var tags = this.allSoloTagsSorted;

            if (this.searchQuery) {
                tags = tags.filter(s => s.label.toLowerCase().includes(this.searchQuery.toLowerCase()));
            }

            return tags;
        },

        soloTags() {
            return this.allTags.filter(t => !t.group_key).filter(t => t.key !== 'zest_content')
        },

        allSoloTagsSorted() {
            return this.$sort.alphabetize(this.soloTags, 'label').reverse();
        },

        showSearchBar() {
            return this.allTags.length > this.pageSize
        },
        groupedTags() {
            let tagsWithGroup = this.allTags.filter(t => t.group_key)
            return tagsWithGroup.reduce((groups, tag) => {
                let group = groups.find(g => g.groupKey === tag.group_key);
                if (group) {
                    group.tags.push(tag)
                    return groups;
                } else {
                    return [
                        ...groups,
                        {
                            groupKey: tag.group_key,
                            groupLabel: tag.group_label,
                            tags: [tag]
                        }
                    ]
                }
            }, [])
        },
    },
    mounted() {
        this.load(this.hasContentType, this.hasContentId)
    },
    methods: {
        update() {
            if (this.valueChanging) return;
            let filters = {...this.value};
            filters.tags = this.tags;
            filters.favorites = this.favoritesOnly;
            filters.required = this.required;
            filters.minFullTextLength = this.minFullTextLength ? 300 : 0;
            filters.excludePaywalled = this.excludePaywalled;
            // console.debug('tags.update', filters)
            this.$emit('input', filters);
        },
        handleClick() {
            if (this.tagShowingMessage) {
                this.tagShowingMessage = false;
            }
        },
        async fetchTagList(type, id) {

            let [accountTags, contentSetTags] = await Promise.all([
                this.$api.get(`/accounts/${this.account.id}/tags`),
                this.$api.get(`/${type}s/${id}/tags`).catch(() => {
                }).then(() => ({data: {tags: []}}))
            ]).then(responses => responses.map(resp => (resp.data.tags || []).map(formatTag)));
            // console.log('tags acquired', accountTags, contentSetTags)
            accountTags.forEach(tag => {
                let inContentSet = contentSetTags.find(x => x.key === tag.key)
                tag.count = inContentSet && inContentSet.count || 0;
            })
            return accountTags
        },
        async load() {
            this.allTags = await this.fetchTagList(this.hasContentType, this.hasContentId);
        }
    }
}
</script>

<style scoped>
.tag input {
    font-size: 1.25em
}

.radio-group > legend {
    font-size: 1em;
    font-weight: var(--font-weight-semibold);
}
</style>
