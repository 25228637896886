<template>
    <div>
        <div v-if="features.selectFilters">
            <editable-select
                :items="options"
                v-model="filterViewId"
                :map="x => ({
                    value: x.id,
                    description: (x.name) ? x.name : 'Unsaved Filter',
                    subtitle: filterString(x)
                })"
                :use-big-mode="true"
                ref="select"
                editSelectedEvent="filterViewEdit"
                :editSelectedTarget="editSelectedTarget"
                :edit-props="{hasContentId, hasContentType, currentUsage}"
                @saved="saved"
                @edit="edit"
                placeholder="Select a Filter View"
                :required="true"
            >
                <!--                <filter-view-edit v-model="filterViewId"-->
                <!--                                  :hasContentType="hasContentType"-->
                <!--                                  :hasContentId="hasContentId"-->
                <!--                                  ref="filterViewEdit">-->
                <!--                </filter-view-edit>-->
                <!--                <div>-->
                <!--                    <button class="btn btn-primary btn-sm" type="button" @click="save()">Save</button>-->
                <!--                    <button class="btn btn-secondary btn-sm" type="button" @click="cancelEdit()">Cancel</button>-->
                <!--                </div>-->
                <filter-usages-link ref="usages"></filter-usages-link>
                <template v-slot:actionsAndStatus>
                    <span>
                        <filter-usages-link
                            :filter-view-id="filterViewId"
                            ref="usages"
                            class="btn btn-link btn-sm">
                        </filter-usages-link>
                        <excluded-filters-link
                            :filters="filters"
                            ref="excludes"
                            :hasContentType="hasContentType"
                            class="btn btn-link btn-sm">
                        </excluded-filters-link>
                    </span>
                </template>
            </editable-select>
        </div>
        <div v-else>
            <filter-view-edit v-model="filterViewId"
                              :hasContentType="hasContentType"
                              :hasContentId="hasContentId"
                              ref="filterViewEdit">

            </filter-view-edit>
        </div>
    </div>
</template>

<script>
import EditableSelect from "../Controls/EditableSelect";
import {mapGetters} from "vuex";
import FilterViewEdit from "./FilterViewEdit";
import {filterString} from "@/services/Filters";
import {Filters} from "../../models/Filters";
import {filterQueryString} from "../../services/RssLinks";
import PopoverButton from "../Controls/PopoverButton";
import FilterUsagesLink from "./FilterUsagesLink";
import {validateForCollection, validateForTopic} from "../../services/Filters";
import {sanitizeHtml} from "../../utilities/utils";
import ExcludedFiltersLink from "./ExcludedFiltersLink";

const env = process.env;

export default {
    name: "SelectFilterView",
    components: {ExcludedFiltersLink, FilterUsagesLink, PopoverButton, FilterViewEdit, EditableSelect},
    props: {
        hasContentId: String,
        hasContentType: String,
        editSelectedTarget: String,
        value: String,
        showUntitledFilters: {Boolean, default: false},
        currentUsage: {type: String, default: null}
    },
    data() {
        return {
            current: {},
            filterViewId: this.value,
            filters: []
        };
    },
    watch: {
        hasContentId() {
            this.reset();
            this.load();
        },
        filterViewId() {
            this.$emit('input', this.filterViewId)
            this.$emit('dataChange', this.data)
        },
        value() {
            this.filterViewId = this.value;
        }
    },
    computed: {
        ...mapGetters({
            account: 'account',
            features: 'accountFeatures',
            feedsDomain: 'feedsDomain'
        }),
        data() {
            return this.filters.find(c => c.id === this.filterViewId)
        },
        options() {
            let validator = this.hasContentType === 'topic' ? validateForTopic : validateForCollection
            return this.filters.filter(f => validator(f.toApi()).length === 0)
        },
        filterQueryString() {
            if (this.data)
                return filterQueryString(this.data, !!this.data.favorites, this.data.includeAllSources,
                    this.data.includeNewSources, this.data.limit);
            else
                return ''
        },
        feedUrl() {
            if (this.hasContentType && this.hasContentId) {
                const qa = env['VUE_APP_API_BASE_URL'].includes('qa');
                const domain = this.feedsDomain;
                const root = `https://${domain}/suggestions`;
                const qs = `?${this.hasContentType}=${this.hasContentId}&${this.filterQueryString}`
                return root + qs;
            } else {
                return null;
            }
        },
    },
    methods: {
        filterString,
        reset() {
            Object.assign(this, {
                filterViewId: null,
                filters: [],
            });
        },
        async fetch() {
            return await this.$api.get(`/accounts/${this.account.id}/filter-views`).then(response => {
                return response.data
                    .filter(filterView => filterView.name !== null || this.filterViewId === filterView.id)
                    .map(Filters.fromApi);
            });
        },
        async load() {
            if (this.hasContentId)
                return await this.fetch().then(resp =>
                    this.filters = resp.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                )
        },
        edit(item) {
            this.current = item;
        },
        saved() {
            this.load()
        },
        async save() {
            let result = await this.$refs.filterViewEdit.save();
            if (this.$refs.select) {
                await this.$refs.select.closeEdit();
            }
            this.current = null;
            return result
        },
        async cancelEdit() {
            if (this.$refs.select) {
                await this.$refs.select.closeEdit();
            }
            this.current = null;
        }
    },
    async mounted() {
        await this.load();
    },
}
</script>

<style scoped>
</style>
