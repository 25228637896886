import * as types from '../mutation-types'
import moment from 'moment'

const state = {
    currentTopic: false,
    allTopics: []
    
}

const getters = {
    currentTopic: state => {
        if (state.currentTopic)
            return state.allTopics.find(t => t.id == state.currentTopic.id)
    },
    currentTopicRole: (state, getters) => getters.currentTopic && getters.currentTopic.role,
    canManageTopic: (state, getters) => ['manager', 'administrator'].includes(getters.currentTopicRole),
    topics : state => state.allTopics
}

const actions = {

}

const mutations = {
    
    [types.SET_CURRENT_TOPIC](state, {topic}){
        state.currentTopic = state.allTopics.find(t => t.id == topic.id);
    },
    [types.ADD_TOPICS](state, {topics}){
        state.allTopics = state.allTopics.concat(topics.filter(t => ! state.allTopics.find(e => e.id == t.id)));
    },
    [types.ADD_TOPIC](state, {topic}){
        let existing = state.allTopics.find(t => topic.id == t.id)
        if ( ! existing){
            state.allTopics.push(topic)
        } else if(existing.updated < topic.updated || ! existing.updated) {
            let index = state.allTopics.indexOf(existing);
            state.allTopics.splice(index, 1, topic);
        }
        refreshCurrentTopic(state);
    },
    [types.USER_DID_LOG_OUT](state) {
        state.allTopics = [];
        state.currentTopic = false;
    },
    [types.DELETE_TOPIC](state, {topic}){
        state.allTopics = state.allTopics.filter(t => {
            return t.id !== topic.id
        })
        refreshCurrentTopic(state);
    },
    [types.UPDATE_TOPIC](state, {topic}){
        var currentTopic = state.currentTopic;
        state.allTopics = state.allTopics.map(t => {
            if (t.id === topic.id){
                
                let topicToReturn = {...topic, updated : moment.utc(), folder : t.folder}
                
                if (topic.id == currentTopic.id) {
                    currentTopic = topicToReturn;
                }
                return topicToReturn;
            }
            return t;
        })


        state.currentTopic = currentTopic;
    },
    [types.MOVE_TOPICS_TO_FOLDER](state, {topics, folder}){
        topics.forEach(topic => {
            let existing = state.allTopics.find(t => t.id == topic.id);
            if (existing){
                let updated = {...topic, folder: folder.id}
                state.allTopics.splice(state.allTopics.indexOf(existing), 1, updated);
            }
        })
    }
}

function refreshCurrentTopic(state) {
    if (state.currentTopic)
        state.currentTopic = state.allTopics.find(t => t.id == state.currentTopic.id);
}

export default {
    state,
    getters,
    actions,
    mutations
}
