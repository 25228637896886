<template>
    <editable-select
        v-model="campaignId"
        :items="campaigns"
        :required="required"
        :map="x => ({value: x.id, description: x.name})"
        ref="select"
        @edit="edit"
    >
        <collection-content-campaign
                @saved="saved"
                :collection-id="collection && collection.id"
                :campaign="currentCampaign">
        </collection-content-campaign>
    </editable-select>
</template>

<style>

</style>

<script>
    import {mapGetters} from 'vuex';
    import CollectionContentCampaign from "@/components/Collections/powerups/CollectionContentCampaign";
    import EditableSelect from "@/components/Controls/EditableSelect";
    export default {
        components: {EditableSelect, CollectionContentCampaign},
        props: {
            collection: Object,
            campaign: Object,
            value: String,
            required: {type: Boolean, default: false}
        },
        data() {
            return {
                currentCampaign: {},
                campaignId: this.campaign && this.campaign.contentCampaignId || null,
                campaigns: []
            };
        },
        watch: {
            collection() {
                this.reset();
                this.loadServerData();
            },
            campaignId() {
                this.$emit('input', this.campaignId)
                this.$emit('campaign-selected', this.htmlCampaign)
            },
            value() {
                this.campaignId = this.value;
            }
        },
        computed: {
            ...mapGetters({
                account: 'account'
            }),
            htmlCampaign() { return this.campaigns.find(c => c.id === this.campaignId) },
        },
        methods: {
            reset() {
                Object.assign(this, {
                    campaignId: this.campaign && this.campaign.contentCampaignId || null,
                    campaigns: [],
                });
            },
            fetchCampaigns() {
                return this.$api.get(`/collections/${this.collection.id}/content-campaigns`)
            },
            loadCampaigns() {
                return this.fetchCampaigns().then(resp =>
                    this.campaigns = resp.data.map(x => x)
                )
            },
            loadServerData() {
                this.loadCampaigns()
            },
            edit(item) {
                this.currentCampaign = item;
            },
            async saved(item) {
                await this.loadCampaigns();
                this.campaignId = item.id;
                this.$refs.select.closeEdit()
            }
        },
        mounted() {
            this.loadServerData()
        },

    }
</script>
