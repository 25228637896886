<template>
    <div class="ms-4 me-4 d-flex flex-column"
         :class="{'feature-flag-off': isNotNewCollectionUx}"
         v-if="account"
         :id="suggestionViewerType+'ArticleViewer'">

        <!-- Two conditional classes on main-content: -->
        <!--    1. Is the sidebar showing?            -->
        <!--    2. Is this a collection or topic?     -->
        <div class="main-content d-flex main-view-main-row  position-relative flex-grow"
             :class="[$refs.expandableSidebar && $refs.expandableSidebar.expand
                ? 'sidebar-is-showing'
                : 'sidebar-is-not-showing',
                `suggestion-type-is-${hasSuggestions.type}`
        ]">
            <expandable-sidebar ref="expandableSidebar">
                <sidebar/>
            </expandable-sidebar>
            <div class="main-viewport pt-2 ps-2 d-flex flex-column flex-nowrap">
                <div class="top-bars position-relative">

                    <controls
                        :display-type="displayType"
                        @displayTypeChange="setDisplayType"
                        @searchTextChange="setSearchText"
                        :suggestion-viewer-type="suggestionViewerType"
                        :collection-batch="collectionBatch"
                        :disable-refresh="alreadyRefreshing"
                        @collectionBatchUsed="clearCollectionBatch"
                    />

                    <filter-display v-if="!accountFeatures.newFilterDropdown" :display-type="displayType" @displayTypeChange="setDisplayType" class=""/>

                </div>
                <div class="d-flex full-width flex-grow">
                    <div :class="{'narrow' : !! readingSuggestion, 'd-none d-md-block' : !! readingSuggestion}"
                         class="suggestions-block full-width position-relative">
                        <div v-if="queueIsBlocked" class="d-flex justify-content-center">
                            <div class="download-message mt-3">
                                Downloading account...
                            </div>
                        </div>
                        <div tabindex="0" id="scrolling-suggestions-container" class="pb-5" v-else @scroll="onScroll">
                            <div class="position-relative">
                                <subscription-display/>
                                <list
                                    :suggestions="suggestions"
                                    :collectionBatch="collectionBatch"
                                    @toggleCardInCollectionBatch="toggleCardInCollectionBatch"
                                    :stillBuilding="stillBuilding"
                                    :display-type="displayType"
                                />
                            </div>
                        </div>
                    </div>

                    <article-reader
                        class="article-reader-container ms-md-3"
                        v-if="readingSuggestion"
                        :suggestion="readingSuggestion"
                        :collection-batch="collectionBatch"
                        @toggleCardInCollectionBatch="toggleCardInCollectionBatch"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Controls from '@/components/Suggestions/Controls'
import Sidebar from "@/components/Suggestions/Sidebar"
import ArticleReader from '@/components/Suggestions/ArticleReader'
import NavSuggestionsManager from "@/components/Navigation/SuggestionsManager"
import FilterDisplay from "@/components/FilteringAndSorting/Horizontal"
import SubscriptionDisplay from '@/components/Subscriptions/Display'

import List from '@/components/Suggestions/List'

import {mapGetters, mapActions} from 'vuex';
import * as $ from 'jquery'
import moment from 'moment'
import RouteContext from '@/utilities/RouteContext'

import Vue from 'vue'
import ExpandableSidebar from "@/utilities/ExpandableSidebar";

export default {
    props: {},
    mixins: [RouteContext],
    components: {
        ExpandableSidebar,
        Controls,
        SubscriptionDisplay,
        Sidebar,
        ArticleReader,
        List,
        NavSuggestionsManager,
        FilterDisplay
    },
    data() {
        return {
            displayType: 'grid',
            searchText: '',
            newPageOfSuggestionsHasBeenRequested: false,
            alreadyRefreshing: false,
            collectionBatch: []
        }
    },
    computed: {
        ...mapGetters({
            readingSuggestion: 'readingSuggestion',
            suggestionUrls: 'suggestions',
            suggestionCache: 'suggestionsCache',
            showingFavorites: 'showFavoriteSuggestions',
            preferences: 'preferences',
            suggestionViewerType: 'suggestionViewerType',
            topic: 'currentTopic',
            hasSuggestions: 'hasSuggestions',
            filters: 'currentFilters',
            account: 'currentAccount',
            accountFeatures: 'accountFeatures',
            accountsQueue: 'accountsBeingLoaded',
        }),
        collectionId() {
            return this.$route.query.collection
        },
        queueIsBlocked() {
            return this.accountsQueue.length > 0;
        },
        suggestions() {
            return this.suggestionUrls.map(s => this.suggestionCache.find(c => s.includes(c.id)))
                .filter(s => s);
        },
        stillBuilding() {
            if (!this.topic || this.suggestionViewerType !== 'topics') return false;

            let createdDifference = moment().diff(moment.utc(this.topic.created), 'seconds');
            let updatedDifference = moment().diff(moment.utc(this.topic.updated), 'seconds');
            let limit = 60 * 4;

            let stillBuildingAfterCreation = createdDifference < limit && this.suggestionUrls.length < 20;
            let stillBuildingAfterUpdated = updatedDifference < limit && this.suggestionUrls.length < 20;

            return stillBuildingAfterCreation || stillBuildingAfterUpdated
        },
        isNotNewCollectionUx() {
            return this.accountFeatures && !this.accountFeatures.newCollectionUx;
        },
    },
    watch: {
        readingSuggestion(current, previous) {
            Vue.nextTick(() => {
                if (current) {
                    this.scrollToSuggestion(current);
                } else {
                    this.scrollToSuggestion(previous);
                }
            })

            if (current) {
                this.listenForEscape();
            } else {
                this.stopListeningForEscape();
            }

            if (this.readingSuggestion) {
                this.$refs.expandableSidebar.contractSidebar();
            } else {
                this.$refs.expandableSidebar.setSidebarToUserPreference();
            }
        },
        suggestionUrls() {
            this.newPageOfSuggestionsHasBeenRequested = false;
        },
        displayType() {
            Vue.nextTick(() => {
                this.watchScrolling();
                this.newPageOfSuggestionsHasBeenRequested = false;
            })
        },
        hasSuggestions() {
            this.collectionBatch = [];
        },
        filters() {
            this.newPageOfSuggestionsHasBeenRequested = false;
        },
        queueIsBlocked() {
            if (!this.queueIsBlocked && this.hasSuggestions && this.hasSuggestions.id) { // If url param points at specific collection
                console.log('loading suggestion view on account queue empty')
                this.loadSuggestionView()
            } else {
                console.log('skipping suggestion view on account queue empty', this.hasSuggestions && this.hasSuggestions.id, this.hasSuggestions)
            }
        },
    },
    mounted() {
        this.loadInitial();
        if (this.preferences.suggestionDisplayType) {
            this.displayType = this.preferences.suggestionDisplayType
        }

        if (this.$mobileCheck.isMobile()) {
            this.displayType = 'grid';
        }

        this.watchScrolling();

    },
    methods: {
        ...mapActions(['loadSuggestions', 'loadNextPageOfSuggestions', 'loadSuggestionView', 'resetSuggestionLoadingState', 'loadFilterViewList']),
        async loadInitial() {
            await this.loadFilterViewList()
            await this.resetSuggestionLoadingState()
            if (!this.goToCollection() && this.hasSuggestions && this.hasSuggestions.id) { // If url param points at specific collection
                console.log('loading suggestion view on page load/mount')
                await this.loadSuggestionView()
            } else {
                console.log('skipping suggestion view on page load/mount', this.hasSuggestions && this.hasSuggestions.id, this.hasSuggestions)
            }
        },
        listenForEscape() {
            document.addEventListener('keyup', this.handleKeyup)
        },
        stopListeningForEscape() {
            document.removeEventListener('keyup', this.handleKeyup);
        },
        handleKeyup(e) {
            if (e.keyCode == 27) {
                this.$store.dispatch('setReadingSuggestion', {suggestion: false});
            }
        },
        watchScrolling() {

            // $("#scrolling-suggestions-container").on('scroll', () => {
            //
            // });

        },
        onScroll() {
            let el = document.getElementById('scrolling-suggestions-container');
            let scrollOffset = el.scrollTop;
            let outerHeight = $(el).height();
            // console.log('height', outerHeight, el.clientHeight, el.offsetHeight, el.scrollHeight)
            let innerHeight = el.scrollHeight;
            let cardHeight = $('#scrolling-suggestions-container .suggestion-card:last').height();
            let reachedBottom = (scrollOffset + outerHeight + cardHeight + 1) >= innerHeight

            // console.log('scroll', reachedBottom, this.newPageOfSuggestionsHasBeenRequested)

            if (reachedBottom && !this.newPageOfSuggestionsHasBeenRequested) {
                this.fetchNextPage()
            }
        },
        fetchNextPage() {
            console.log('fetchNextPage')
            this.newPageOfSuggestionsHasBeenRequested = true;
            this.loadNextPageOfSuggestions();
        },
        handleScroll() {
            this.offset = this.$refs.suggestionsContainer.scrollTop
        },
        setDisplayType(type) {
            this.displayType = type
            this.$store.dispatch('setUserPreference', {key: "suggestionDisplayType", value: type});
        },
        setSearchText(text) {
            this.searchText = text;
        },
        scrollToSuggestion(suggestion) {
            let el = document.getElementById('suggestion-' + suggestion.id)
            if (el) {
                let offset = el.offsetTop;
                $('#scrolling-suggestions-container').animate({scrollTop: offset}, 500);
            }
        },
        toggleCardInCollectionBatch({card}) {
            let index = this.collectionBatch.indexOf(card);
            if (index == -1) {
                this.collectionBatch.push(card)
            } else {
                this.collectionBatch.splice(index, 1);
            }
        },
        clearCollectionBatch() {
            this.collectionBatch = [];
        },
    }
}
</script>
<style lang="scss">
// Do not scope cause sidebar stuff.
.feature-flag-off {
    .main-content {

        // Small devices (landscape phones, less than 768px)
        @media (max-width: 767.98px) {
            #scrolling-suggestions-container {
                height: calc(100vh - 225px) !important;
            }
            .article-body-container {
                max-height: calc(100vh - 263px) !important;
            }
        }
        @media (max-width: 767.98px) and (max-height: 575.98px) {
            #scrolling-suggestions-container {
                height: auto !important;
                overflow: initial !important;
            }
            .article-body-container {
                max-height: initial;
                overflow: initial;
                margin-bottom: 30px;
            }
        }
    }
}
</style>
<style lang="scss">
// Do not scope .expandable-sidebar-content or the styles won't apply.
.main-content {
    #scrolling-suggestions-container {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        //height: calc(100vh - 182px);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    .expandable-sidebar-content {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        height: calc(100vh - 64px) !important;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .article-body-container {
        max-height: calc(100vh - 204px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background: #fff;
    }

    // X-Small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        .article-body-container {
            max-height: calc(100vh - 165px) !important;
        }
        // No sidebar for mobile devices.
        .expandable-sidebar {
            display: none;
        }
    }


    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
        #scrolling-suggestions-container {
            height: calc(100vh - (182px + 78px));
        }

        .expandable-sidebar-content {
            height: calc(100vh - (30px + 78px)) !important;
        }

        .article-body-container {
            max-height: calc(100vh - (222px + 78px));
        }
    }

    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
        #scrolling-suggestions-container {
            height: calc(100vh - (162px + 41px));
        }

        .expandable-sidebar-content {
            height: calc(100vh - (65px + 41px)) !important;
        }

        .article-body-container {
            max-height: calc(100vh - 240px);
        }
        .article-reader-container {
            position: absolute;
            left: .25rem;
            top: 0;
            width: 100%;
            max-width: unset;
            z-index: 99;
        }
    }

    @media(max-width: 575.98px) {
        .expandable-sidebar-content {
            height: calc(100vh - (98px)) !important;
        }
    }

    // Obnoxious Height and Medium devices (tablets, 768px and up)
    @media (min-width: 768px) and (max-height: 575.98px) {
        #scrolling-suggestions-container {
            height: auto !important;
            overflow: initial !important;
        }
        .expandable-sidebar-content {
            height: 100% !important;
            overflow: initial !important;

        }
        .article-body-container {
            max-height: initial;
            overflow: initial;
        }
    }


}
</style>

<style lang="scss" scoped>
.main-viewport {
    width: 100%;
    flex-shrink: 1000;
    flex-grow: 100;
    overflow-x: hidden;
}

// Used when the article reader is visible.
.suggestions-block.narrow {
    flex: 1;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .article-reader-container {
        position: relative;
        top: 0!important;
    }
}
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .article-reader-container {
        max-width: calc(100vw - 2.25em)!important;
    }
}

.article-reader-container {
    top: 2.75em;
    flex: 3;
    max-width: 800px;
}
//@media screen and (min-width: 1200px) {
//    .main-viewport {
//        margin-top: -8px;
//    }
//}
//@media screen and (min-width: 1200px) {
//    .main-view-main-row {
//        margin-left: -1rem;
//    }
//}
</style>
