<template>
    <div class="powerup-config ps-3 pe-2">
        <div class=" d-flex flex-column" v-if="powerup">
            <div class="d-flex justify-content-between align-items-end">
                <h3 class="mb-0">
                    <i :class="iconClass"/> {{title}}
                </h3>
                <div class="font-size-18">
                    <div v-if="name !== 'mail'" class="powerup-action">
                        <toggle-switch v-if="isAuthenticated && canTurnOnOff && usesToggle" v-model="active" class="powerup-toggle" :aria-label="`Connected to ${title}`"/>
                        <connect-disconnect-button v-if="!isAuthenticated" :service="service" :team="account" :beforeAuth="() => {}"></connect-disconnect-button>
                    </div>
                </div>
            </div>
            <hr class="mt-1 mb-1">
            <div class="small text-muted mb-2">
                <p>{{description}} <a :href="learnUrl" target="_blank" rel="noopener">Learn More</a></p>
            </div>
            <component
                v-if="(isAuthenticated && (active || !usesToggle)) || name === 'mail'"
                :is="component"
                :value="savedSettings"
                :collectionId="currentCollection.id"
                :collection="currentCollection"
                :team="account"
                @settingsChanged="settingsChanged"
                class="flex-grow"></component>
            <div v-else-if="name !== 'mail'" class="d-flex justify-content-center p-5">
                <button v-if="isAuthenticated && canTurnOnOff && usesToggle" @click="active=true" class="btn btn-primary">
                    Activate on this Collection
                </button>
                <connect-disconnect-button v-if="!isAuthenticated" :service="service" :team="account" :beforeAuth="() => {}"></connect-disconnect-button>
            </div>
            <hr>
            <div class="d-flex justify-content-end" v-if="active && isAuthenticated">
                <button type="button" class="btn btn-primary">Save {{title}} Settings</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import AmplifyCollectionSettings from "./integrations/AmplifyCollectionSettings.vue";
import {AmplifyConnection} from "@/services/Amplify";
import ConnectDisconnectButton from "@/components/Settings/Team/ConnectDisconnectButton.vue";
import ToggleSwitch from '@/components/Controls/ToggleSwitch.vue'
import ConstantContactCollectionSettings from "@/components/Integrations/ConstantContact/ConstantContactCollectionSettings.vue";
import CollectionQualityChecks from "../CollectionQualityChecks.vue";
import ContentCampaigns from "../ContentCampaigns.vue";
import HubspotEmailPowerup from "./integrations/HubspotEmailPowerup.vue";
import MailchimpEmailPowerup from "./integrations/MailchimpEmailPowerup.vue";
import LatelyPowerup from "./integrations/LatelyPowerup.vue";
import SniplyPowerup from "./integrations/SniplyPowerup.vue";
import PowerupAuth from '../../V2/PowerupAuth.vue'
import PowerupMeta from "../../V2/PowerupMeta.vue";
import EveryoneSocialCollectionSettings
    from "@/components/Integrations/EveryoneSocial/EveryoneSocialCollectionSettings.vue";

export default {
    name: "PowerupConfig",
    components: {ConnectDisconnectButton, AmplifyCollectionSettings, ToggleSwitch},
    mixins: [PowerupAuth, PowerupMeta],
    props: ['powerup'],
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection',
            account: 'account',
            apis: 'externalApis',
            features: 'features',
            accountFeatures: 'accountFeatures',
        }),
        component() {
            switch (this.name) {
                case 'amplify': return AmplifyCollectionSettings;
                case 'constant_contact': return ConstantContactCollectionSettings;
                case 'hubspot': return HubspotEmailPowerup;
                case 'mailchimp': return MailchimpEmailPowerup;
                case 'proofpoint': return CollectionQualityChecks;
                case 'lately': return LatelyPowerup;
                case 'sniply': return SniplyPowerup;
                case 'icebreaker': return null;
                case 'mail': return ContentCampaigns;
                case 'everyoneSocial':
                case 'everyone-social':
                    return EveryoneSocialCollectionSettings;
                case 'rss':
                default:
                    return null
            }
        },

    },
    data() {
        return {
            name: this.powerup && this.powerup.name,
            title: this.powerup && this.powerup.title,
            isAuthenticated: this.powerup && this.powerup.isAuthenticated,
            active: this.powerup && this.powerup.active,
            currentSettings: {},
            savedSettings: {},
            loading: false,
            disableReason: false,
            canTurnOnOff: true,
        };
    },
    created() {
        this.amplifyService = AmplifyConnection
    },
    watch: {
        powerup() {
            console.log('powerup changed')
            Object.assign(this, {
                name: this.powerup.name,
                title: this.powerup.title,
                isAuthenticated: this.powerup.isAuthenticated,
                active: this.powerup.active
            })
            this.load()
        },
        currentCollection() {
            this.load()
        }
    },
    methods: {
        settingsChanged(changedSettings) {
            this.currentSettings = Object.assign({}, this.currentSettings, changedSettings)
        },
        async load() {
            console.log('load', this.powerup, this.currentCollection)
            if (this.powerup && this.currentCollection) {
                this.loading = true;
                // if (['amplify', 'lately', 'sniply'].includes(this.name)) {
                //     let result = await this.$api.get(`/collections/${this.currentCollection.id}/${this.name}-currentSettings`);
                //     this.currentSettings = result.data || {}
                //     this.on = this.currentSettings.on;
                //     this.loading = false;
                // } else {
                    let result = await this.$api.get(`/collections/${this.currentCollection.id}/integrations/${this.name}/settings`);
                    this.savedSettings = result.data && result.data.settings || {};
                    this.currentSettings = {...this.savedSettings};
                    this.on = this.currentSettings.on || this.currentSettings.sniply_campaign_id;
                    this.loading = false;
                // }
            }
        },
    }
}
</script>

<style scoped>
    .powerup-config {
        min-width: 400px;
        width: 530px;
        max-height: 100% !important;
        overflow-y: auto;
    }
    .icon-dynamic {
        vertical-align: baseline;
        margin-bottom: -0.1em;
    }
</style>
<style>
.powerup-config label {
    display: block;
    font-size: var(--font-size-12);
    font-weight: 500;
    color: var(--water-0);
    text-transform: uppercase;
}
</style>
