<template>
    <uc-select class="input-group-prepend m-0 p-0 full-width" data-lpignore="true"
               :value="fvId"
               @input="value => { if (!loadingSelected)filterViewSelected({filterViewId: value}) }"
               :use-big-mode="true"
               :use-short-mode="true"
               :searchable="true"
               :filterable="true"
               layout="main-page-filter-view"
               placeholder="Unsaved Filter"
               :items="options"
    >
    </uc-select>
</template>
<style lang="scss">
// Do not scope these styles or they will not work.
.add-save-filter-view {
    .vs__selected {
        margin-top: 0;
    }
    .add-save-filter-primary-btn, .vs__selected-options {
        padding: 1px .75em!important;
    }
    .vs__dropdown-toggle, .vs__search {
        padding: 0!important;
        margin: 0!important;
    }
    .vs__actions {
        position: relative;
        bottom: 2px;
    }
    .vs__search {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .uc-select .vs__search::placeholder {
        font-size: var(--font-size-12);
    }
    .uc-select-option-subtitle, .uc-select-option-subtitle * {
        font-weight: 300!important;
        font-size: var(--font-size-11)!important;
    }
}
</style>
<script>
import UcSelect from "@/components/Controls/UpcontentSelect";
import {mapActions, mapGetters} from 'vuex';
import {Filters} from "@/models/Filters";
import {filterString} from "@/services/Filters";
import {arrayHasValues, hasValue} from "@/utilities/utils";
import * as toastr from "toastr";
import FilterUsagesLink from "../FilterUsagesLink";
import {validateForCollection, validateForTopic} from "@/services/Filters";
import {unique} from "@/utilities/Arrays";

export default {
    name: "AddSaveFilterView",
    components: {FilterUsagesLink, UcSelect},
    data() {
        console.log('SelectFilterView.data.init', {fvId: this.filterViewId})
        return {
            networkActivity: false,
            networkError: null,
            lastCollection: null,
            lastTopic: null,
            fvId: this.filterViewId,
            loadingSelected: this.loadingSelected,
            toastOptions:  {
                preventDuplicates: true,
                timeOut: 0,
                extendedTimeOut: 0,
                clickClose: true,
                closeOnHover: false,
            }
        };
    },
    computed: {
        ...mapGetters({
            account: 'account',
            filterViewId: 'currentFilterViewId',
            selectedFilters: 'currentFilterView',
            selectedSources: 'selectedSources',
            excludedSources: 'excludedSources',
            selectedTags: 'selectedTags',
            includeNewSources: 'includeNewSources',
            currentFilters: 'currentFilters',
            hasSuggestions: 'hasSuggestions',
            currentCollection: 'currentCollection',
            currentTopic: 'currentTopic',
            favorites : 'showFavoriteSuggestions',
            filterViews: 'filterViewOptions',
            features: 'accountFeatures',
            required: 'required'
        }),
        options() {
            console.log('filterViews.type', this.filterViews)
            return this.$sort.alphabetize(this.filterViews.map(x => ({
                value: x.id,
                description: (x.name) ? x.name : 'Unsaved Filter: ' + filterString(x),
                subtitle: filterString(x)
            })), 'description').filter(unique(x => x.value));
        },
        dataToSave() {
            if (!this.currentFilters) return null;

            // This logic will handle if include new sources has not been set yet and default it to true.
            const includeNewSources = this.includeNewSources !== false;


            let includeLimit = !!(this.selectedFilters && this.selectedFilters.limit);
            let includeOffset = !!(this.selectedFilters &&  this.selectedFilters.offset);
            let filters = Filters.fromUi({
                ...this.currentFilters,
                includeNewSources,
                includeAllSources: includeNewSources && !arrayHasValues(this.excludedSources),
                limit: this.selectedFilters && this.selectedFilters.limit || null,
                offset: this.selectedFilters && this.selectedFilters.offset || 0,
                tags: this.selectedTags,
                excludes: this.excludedSources,
                sources: this.selectedSources,
                favorites: this.favorites,
                required: this.required,
            }, includeLimit, includeOffset)
            return filters.toApi()
        },
        selectedFiltersApi() {
            return this.selectedFilters && this.selectedFilters.toApi();
        },
        dataHasChanged() {
            try {
                if (!this.currentFilters || !this.selectedFilters) {
                    return false;
                }
                return this.selectedFilters &&
                    (JSON.stringify(this.selectedFilters.toApi()) !== JSON.stringify(this.dataToSave));
            } catch(e) {
                console.log(e)
                return null
            }
        },
    },
    watch: {
        async filterViewId() {
            console.log('filterViewId.change', this.filterViewId)
            this.loadingSelected = true;
            this.fvId = this.filterViewId;
            this.loadingSelected = false;
        },
        async selectedFilters() {
            if (this.selectedFilters && this.filterViewId) {
                let validationMessages = this.validateFiltersForType(this.selectedFilters.toApi())
                if (validationMessages.length) {
                    console.log('validationMessages', validationMessages, this.dataToSave);
                    let validations = validationMessages.join(' ');
                    // let isSort = validationMessages.some(x => x.includes('sort'))
                    let message = '<div>';
                    message += `<p>This filter view is not configured for use with ${this.hasSuggestions.type}s.</p>`;
                    message += `<p>For reliable results, the following filters need to be updated:</p>`;
                    message += `<p>${validations}</p>`
                    message += `</div>`;
                    // if (isSort) message += ', and this may cause articles to appear in a random order'
                    toastr.warning(message, '<span>Incompatible Filter View</span>',
                        this.toastOptions);
                }
            }
        }
    },
    methods: {
        ...mapActions(['setUserPreference', 'loadPublishersFilters', 'filterViewSelected', 'resetFiltersToView',
            'loadFilterViewList', 'loadFilterView',
            'setIncludedSuggestionSourceList', 'setSelectedTags','toggleShowFavorites', 'setRequired', 'clearFilterViewSelection']),
        async saveExisting() {
            this.networkActivity = true;
            if (!this.validateExisting()) {
                this.networkActivity = false;
                return;
            }
            let data = this.dataToSave;
            data.id = this.currentFilters && this.currentFilters.filterViewId;
            data.name = this.selectedFilters.name;
            if (data.id) {
                // May not need Filters.fromUi(data).toApi() because dataToSave is already formatted.
                await this.$api.put(`/filter-views/${data.id}/`, data).then(async () => {
                        toastr.success(`Your new filter view '${data.name}' is all set!`,'Saved');
                        await this.loadFilterViewList();
                        await this.loadFilterView({filterViewId: this.filterViewId});
                        this.networkActivity = false;
                    }
                ).catch(e => {
                    this.networkError = e;
                    console.error('Network Error', `/filter-views/${data.id}/`,
                        e);
                    let message = `<p>An error was detected that prevented this filter view from being saved.</p>
                                   <p>Check your network connection and try again.  If this error persist, please
                                   contact support.</p>`;
                    toastr.error(message,'Network Error');
                    setTimeout(() => {this.networkActivity = false}, 3000);
                });
            }
        },
        async saveNew() {
            this.networkActivity = true;
            if (!this.validateNew()) {
                this.networkActivity = false;
                return;
            }
            let data = this.dataToSave;
            data.id = this.currentFilters && this.currentFilters.filterViewId;
            data.name = prompt('Name this filter');

            // Set a default value for limit.
            if(!data.limit) {
                data.limit = 100;
            }

            const isValid = this.validateFilterViewName(data.name);

            if (isValid) {
                await this.$api.post(`/accounts/${this.account.id}/filter-views`, data).then(async resp => {
                        toastr.success(`Your changes to the filter view '${data.name}' are good to go!`,'Saved');
                        await this.loadFilterViewList();
                        await this.filterViewSelected({filterViewId: resp.data.id});
                        this.networkActivity = false;
                    }
                ).catch(e => {
                    this.networkError = e;
                    console.error('Network Error', `/accounts/${this.account.id}/filter-views/`,
                        e);
                    let message = `<p>An error was detected that prevented this filter view from being saved.</p>
                                   <p>Check your network connection and try again.  If this error persist, please
                                   contact support.</p>`;
                    toastr.error(message,'Network Error');
                });
            }

            setTimeout(() => {this.networkActivity = false}, 3000);
        },
        validateFiltersForType(data) {
            let isTopic = this.hasSuggestions.type === 'topic';
            let validationMessages = isTopic ? validateForTopic(data) : validateForCollection(data)
            return validationMessages
        },
        validateNew() {
            let validationMessages = this.validateFiltersForType(this.dataToSave);
            if (validationMessages.length) {
                let validations = validationMessages.join('\n');
                let message =   `<div>`;
                message +=      `<p>The following filters won't work with ${this.hasSuggestions.type}s`;
                message +=      ` and need to be updated in order to save:</p>`;
                message +=      `<p>${validations}</p>`
                message +=      `</div>`
                toastr.warning(message, '<span>Invalid Filters in Filter View</span>',
                    this.toastOptions);
            }
            return validationMessages.length === 0
        },
        validateExisting() {
            let usageMessages = this.usages ? this.usages.validate(this.dataToSave) : [];
            let localValidation = this.validateFiltersForType(this.dataToSave);
            let validationMessages = [...usageMessages, ...localValidation].filter(unique(x => x))
            if (validationMessages.length) {
                let validations = validationMessages.join('\n');
                let message =   `<div class="font-size-13">`;
                message +=      `<p>The following filters won't work with ${this.hasSuggestions.type}s`;
                message +=      ` and need to be updated in order to save:</p>`;
                message +=      `<p>${validations}</p>`
                message +=      `</div>`
                toastr.warning(message, 'Invalid Filters in Filter View', this.toastOptions);
            }
            return validationMessages.length === 0
        },
        validateFilterViewName(filterViewName) {
            if (!hasValue(filterViewName)) {
                // Probably cancelled, don't say anything.
                return false;
            }

            if (filterViewName === "") {
                toastr.warning(`You'll need to enter a name for your filter view.`,
                    'Missing Filter View Name');
                return false;
            }

            if (filterViewName.toLowerCase() === "none" || filterViewName.toLowerCase() === "null" ||
                filterViewName.toLowerCase() === "default" ||
                filterViewName.toLowerCase() === "default filter view" ||
                filterViewName.toLowerCase() ===
                "undefined") {
                toastr.warning(`Please use a name for this filter view that is not: '${filterViewName}'.`,
                    'Invalid Filter View Name');
                return false;
            }

            if (filterViewName.toLowerCase().startsWith("unsaved filter")) {
                toastr.warning(`Please use a name for this filter view that does not start with 'Unsaved Filter'.`,
                    'Invalid Filter View Name');
                return false;
            }

            if (this.filterViews.findIndex(x => x.name === filterViewName) >= 0) {
                toastr.warning(
                    `The name '${filterViewName}' is already in use by another filter view.  Try a different name.`,
                    'Invalid Filter View Name');
                return false;
            }

            return true;
        },
    },
    created() {
        console.log('SelectFilterView.created', {fvId: this.fvId,  filterViewId: this.filterViewId})
    },
    mounted() {
        this.fvId = this.filterViewId
        console.log('SelectFilterView.mounted', {fvId: this.fvId,  filterViewId: this.filterViewId})
    }
}
</script>
<style lang="scss" scoped>
.suggestion-filter-views-info-icons {
    height: 20px; // Need to use px because the page height calculations use px;
    box-sizing: border-box;
    font-size: var(--font-size-11);
}
.btn-group > .btn, .btn-group-vertical > .btn {
    flex:0 1 auto !important;
}
</style>
