<template>
    <modal ref="modal" :no-footer="true" class="hubspot-share-modal" size="lg">
        <template v-slot:header>
            <span><i class="integration-logo icon-dynamic hubspot-icon me-1"></i> Share to Hubspot</span>
        </template>
        <form v-if="!hubspotLink" @submit.stop.prevent="submit" class="form">
            <fieldset class="small-fieldset">
                <legend>Select Social Networks</legend>
                <social-selector v-model="selectedChannels" :all-channels="allChannels" multiple></social-selector>
            </fieldset>
            <div class="mt-3 mb-5">
                <post-editor v-model="postText" :content-item="contentItem"></post-editor>
            </div>
            <hr>
            <button type="submit" class="btn btn-primary" :disabled="!(selectedChannels&&selectedChannels.length)">
                Create Draft{{ plural ? 's' : '' }}
            </button>
        </form>
        <div v-else>
            <div class="alert alert-green">Draft post{{ plural ? 's' : '' }} created in Hubspot successfully!</div>
            <a class="btn btn-primary" :href="hubspotLink" target="_blank">Edit and Schedule Post{{ plural ? 's' : '' }}
                in Hubspot</a>
        </div>
    </modal>
</template>

<style>
.hubspot-share-modal .modal-dialog {
    max-width: 800px;
}

</style>

<script>
import Modal from "@/components/Controls/Modal";
import {mapGetters} from 'vuex'
import SocialProfile from "@/components/Controls/SocialProfile";
import HighlightCheckbox from "@/components/Controls/HighlightCheckbox";
import SocialSelector from "@/components/Controls/SocialSelector";
import HeroImage from "@/components/Suggestions/HeroImage";
import PostEditor from "@/components/Controls/PostEditor";

export default {
    components: {PostEditor, HeroImage, SocialSelector, HighlightCheckbox, SocialProfile, Modal},
    computed: {
        ...mapGetters({
            account: 'account'
        }),
        plural() {
            return this.selectedChannels.length > 1
        },
    },
    watch: {
        // account() {
        //     this.load()
        // }
    },
    methods: {
        async submit() {
            let result = await this.$api.post(`/accounts/${this.account.id}/share`, {
                channels: this.selectedChannels,
                post_text: this.postText,
                [this.type]: this.contentItem.id
            });
            let data = result.data;

            this.hubspotLink = data.redirect;
            // window.open(data.redirect, '_blank');
        },
        async load() {
            try {
                if (this.account) {
                    let result = await this.$api.get(
                        `/accounts/${this.account.id}/integrations/hubspot/resources/channels`);
                    this.allChannels =
                        result.data.filter(x => x.shared && x.active && !x.hidden && x.canPublish).map(c => ({
                            ...c,
                            id: c.channelGuid,
                            icon: `fa fa-${c.accountType.toLowerCase()}`,
                            profileName: c.name || c.username,
                            networkName: c.accountType,
                        }));
                    this.hubspotLink = '';
                }
                // this.selectedChannels = this.allChannels.filter(x => x.default)
            } catch (e) {
                console.error(e)
            }
        }
    },
    data() {
        return {
            allChannels: [],
            selectedChannels: [],
            hubspotLink: '',
            postText: '',
            contentItem: null,
            type: null
        }
    },
    async mounted() {
        window.Events.$on('hubspotShare', ({type, contentItem}) => {
            this.type = type;
            this.contentItem = contentItem;
            let summary = this.contentItem.summary || this.contentItem.page && this.contentItem.page.summary;
            this.postText = `${summary}\n${this.contentItem.link_url}`;
            this.hubspotLink = '';
            this.selectedChannels = [];
            this.load()
            this.$refs.modal.show();
        })
    }
}
</script>
