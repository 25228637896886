import * as types from './mutation-types'
// import {ucAccountSplitClient} from "@/utilities/split";

export const beginDownloadingSuggestionList = ({commit}) => {
    commit(types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST)
}

export const beginDownloadingSuggestions = ({ commit }) => {
    commit(types.BEGIN_DOWNLOADING_SUGGESTIONS)
}

export const completeDownloadingSuggestionList = ({ commit }) => {
    commit(types.COMPLETE_DOWNLOADING_SUGGESTIONS_LIST)
}

export const completeDownloadingSuggestions = ({ commit }) => {
    commit(types.COMPLETE_DOWNLOADING_SUGGESTIONS)
}

export const setReadingSuggestion = ({commit}, {suggestion, scrollToNotes}) => {
    commit(types.SET_READING_SUGGESTION, {
        suggestion,
        scrollToNotes
    })
}

export const incrementSuggestionsQueryPageOffset = ({commit}) => {
    commit(types.INCREMENT_SUGGESTIONS_QUERY_PAGE_OFFSET)
}

export const resetSuggestionsQueryPageOffset = ({ commit }) => {
    commit(types.RESET_SUGGESTIONS_QUERY_PAGE_OFFSET)
}

export const resetScrollToNotes = ({commit}) => {
    commit(types.RESET_SCROLL_TO_NOTES);
}

export const toggleAccountNotesReader = ({commit}) => {
    commit(types.TOGGLE_ACCOUNT_NOTES_READER)
}



export const removeCollectionFromSuggestionContexts = ({commit}, {suggestion, collectionId}) => {
    commit(types.REMOVE_COLLECTION_FROM_SUGGESTION_CONTEXTS, {suggestion, collectionId});
}

export const setSuggestionSourceList = ({commit}, {sources, hasSuggestionsId}) => {
    commit(types.SET_SUGGESTION_SOURCE_LIST, {
        sources, hasSuggestionsId
    })
}

export const setSuggestionTagList = ({commit}, {tags, hasSuggestionsId}) => {
    commit(types.SET_SUGGESTION_TAG_LIST, {
        tags, hasSuggestionsId
    })
    // TODO: This may be needed for tags, but I need a proofpoint account to test.
    // commit(types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST);
    // dispatch('loadSuggestions', {keep: true});
}


export const addSourceForSuggestion = ({commit}, {source, hasSuggestionsId}) => {
    commit(types.ADD_SOURCE_FOR_SUGGESTION, {
        source, hasSuggestionsId
    })
}

export const setExcludedSuggestionSourceList = ({commit}, {sources, hasSuggestionsId, includeNew}) => {
    commit(types.SET_EXCLUDED_SUGGESTION_SOURCE_LIST, {
        sources, hasSuggestionsId, includeNew
    })
}

export const setIncludedSuggestionSourceList = ({commit}, {sources, hasSuggestionsId, includeNew}) => {
    commit(types.SET_INCLUDED_SUGGESTION_SOURCE_LIST, {
        sources, hasSuggestionsId, includeNew
    })
}

export const setSourceSelection = ({commit}, {sources, excludedSources, includeNew, hasSuggestionId}) => {
    commit(types.SET_SOURCE_SELECTION, {sources, excludedSources, includeNew, hasSuggestionId})
}

export const excludeSource = ({commit}, {source, hasSuggestionsId}) => {
    commit(types.EXCLUDE_SOURCE, {
        source, hasSuggestionsId
    })
}

export const setSelectedTags = ({commit}, {tags, hasSuggestionsId}) => {
    commit(types.SET_SELECTED_TAGS, {
        tags, hasSuggestionsId
    })
}

export const setRequired = ({commit, dispatch}, {required, hasSuggestionsId}) => {
    commit(types.SET_REQUIRED_FILTER, {
        required, hasSuggestionsId
    })
    commit(types.BEGIN_DOWNLOADING_SUGGESTIONS_LIST);
    dispatch('loadSuggestions', {keep: true});
}

export const toggleSourceItemInSelectedSourceList = ({commit}, sourceName) => {
    commit(types.TOGGLE_ITEM_IN_SELECTED_SOURCE_LIST, {
        sourceName
    })
}


export const setSourceFilterList = ({commit}, {list}) => {
    commit(types.SET_SELECTED_SOURCE_LIST, {
        list
    })
}

export const toggleSuggestionFavorite = ({commit}, suggestion) => {
    commit(types.TOGGLE_SUGGESTION_FAV_STATE, {
        suggestion
    })
}

export const setCurrentTopic = async ({commit, dispatch}, {topic, switchTo, isNew}) => {
    await commit(types.SET_CURRENT_TOPIC, {
        topic
    });
    if (switchTo === false) return;
    await dispatch('setSuggestionViewerType', {type : 'topics'})
    await dispatch('checkForNewSuggestions', {});
}

export const checkForNewSuggestions = async ({commit, dispatch, getters}, {timer}) => {
    if (timer) clearTimeout(timer);

    await dispatch('loadSuggestionView');

    if (!getters.stillBuildingTopic){
        return;
    }

    timer = setTimeout(() => {
        dispatch('checkForNewSuggestions', {timer});
    }, 15000);
}

export const userLoggedInWithToken = ({commit}, {user, token}) => {
    commit(types.USER_DID_LOG_IN, {
        user, token
    })
}

export const setAuthToken = ({commit}, {token}) => {
    commit(types.SET_AUTH_TOKEN, {token})
}

export const setInviteToken = ({ commit }, { token }) => {
    commit(types.SET_INVITE_TOKEN, { token })
}

export const addAccount = ({commit}, {account}) => {
    commit(types.ADD_ACCOUNT, {account});
}

export const addAccountOverview = ({commit}, {overview}) => {
    commit(types.ADD_ACCOUNT_OVERVIEW, {overview});
}

export const updateAccount = ({commit}, {account}) => {
    commit(types.UPDATE_ACCOUNT, {account});
}

export const deleteAccount = ({commit}, {account}) => {
    commit(types.DELETE_ACCOUNT, {
        account
    })
}


export function setShareProvider({commit}, {shareProvider, account}) {
    commit("SET_SHARE_PROVIDER", {shareProvider, account});
}

export const addSubscriptionToAccount = ({commit}, {subscription, account}) => {
    commit(types.ADD_SUBSCRIPTION_TO_ACCOUNT, {
        account, subscription
    })
}

export const addFolder = ({commit}, {folder}) => {
    commit(types.ADD_FOLDER, {
        folder
    })
}

export const addFolderToFolder = ({commit}, {folder, parent, account}) => {
    commit(types.ADD_FOLDER_TO_FOLDER, {
        folder, parent, account
    })
}

export const moveTopicsToFolder = ({commit}, {topics, folder}) => {
    commit(types.MOVE_TOPICS_TO_FOLDER, {
        topics, folder
    })
}

export const moveCollectionsToFolder = ({commit}, {collections, folder}) => {
    commit(types.MOVE_COLLECTIONS_TO_FOLDER, {
        collections, folder
    })
}

export const addFolderToAccount = ({commit}, {folder, account}) => {
    commit(types.ADD_LOCAL_FOLDER_TO_ACCOUNT, {
        folder,
        account
    })
}

export const addTopic = ({commit}, {topic}) => {
    commit(types.ADD_TOPIC, {
        topic
    })
}


export const addTopics = ({ commit }, { topics }) => {
    commit(types.ADD_TOPICS, {
        topics
    })
}

export const addCollections = ({commit}, {collections}) => {
    commit(types.ADD_COLLECTIONS, {collections});
}

export const addFolders = ({ commit }, { folders }) => {
    commit(types.ADD_FOLDERS, { folders });
}

export const deleteTopic = ({commit}, {topic}) => {
    commit(types.DELETE_TOPIC, {
        topic
    })
}

export const removeTopic = ({ commit }, { topic }) => {
    commit(types.DELETE_TOPIC, {
        topic
    })
}

export const deleteFolder = ({commit}, {folder}) => {
    commit(types.DELETE_FOLDER, {
        folder
    })
}

export const removeFolder = ({ commit }, { folder }) => {
    commit(types.DELETE_FOLDER, {
        folder
    })
}

export const deleteNote = ({commit, rootGetters}, {note}) => {
    const noteURI = `${rootGetters.apiHost}/notes/${note.id}`
    commit(types.DELETE_NOTE, {note, noteURI})
}

export const removeNote = ({ commit, rootGetters }, { note }) => {
    const noteURI = `${rootGetters.apiHost}/notes/${note.id}`
    commit(types.DELETE_NOTE, { note, noteURI })
}

export const deleteCollection = ({commit}, {collection}) => {
    commit(types.DELETE_COLLECTION, {collection});
}

export const selectFirstTopicInCurrentFolder = ({commit}) => {
    commit(types.SELECT_FIRST_TOPIC_IN_CURRENT_FOLDER);
}

export const updateTopic = ({commit}, {topic}) => {
    commit(types.UPDATE_TOPIC, {
        topic
    })
}

export const logoutUser = ({commit}) => {
    commit(types.USER_DID_LOG_OUT);
}

export const setLoginMessage = ({commit}, {message}) => {
    commit(types.SET_LOGIN_MESSAGE, {message});
}

export const setCurrentAccount = async ({ commit, getters }, { account }) => {
    commit(types.SET_ACCOUNT, {
        account
    });

    if (localStorage) {
        let existing = JSON.parse(localStorage.getItem('defaultAccounts')||'{}');
        let newData = {...existing, [getters.currentUser.id]: account.id};
        localStorage.setItem('defaultAccounts', JSON.stringify(newData))
    }

    // ucAccountSplitClient.switchAccount(account.id, {
    //     email: state.user.user.email
    // });
    // const featureData = await ucAccountSplitClient.getAllFlags();
    // commit('SET_FEATURE_DATA', {accountId: account.id, featureData})

}

export const setAccount = setCurrentAccount;

export const setCurrentFolder = ({ commit }, {folder}) => {
    commit(types.SET_FOLDER, {
        folder
    });
}

export const setUserArticleReaderPreferences = ({commit}, {preferences}) => {
    commit(types.SET_USER_ARTICLE_READER_PREFERENCES, {
        preferences
    })
}

export const setSuggestionFilters = ({commit}, {filters}) => {
    commit(types.SET_SUGGESTION_FILTERS, {
        filters
    })
}

export const beginDownloadingAccount = ({commit}, {url}) => {
    commit(types.BEGIN_DOWNLOADING_ACCOUNT, {
        url
    })
}

export const completeDownloadingAccount = ({ commit }, { account }) => {
    commit(types.COMPLETE_DOWNLOADING_ACCOUNT, {
        account
    })
}

export const setAccountsLastFetchedAtDate = ({commit}, {date}) => {
    commit(types.SET_LATEST_ACCOUNTS_FETCHED_AT, {
        date
    })
}

export const beginDownloadingNotes = ({commit}) => {
    commit(types.BEGIN_DOWNLOADING_NOTES)
}

export const completeDownloadingNotes = ({commit}) => {
    commit(types.COMPLETE_DOWNLOADING_NOTES)
}

export const addNote = ({commit, rootGetters}, {note}) => {
    // TODO(casio): discuss w/ Matt
    const noteURI = `${rootGetters.apiHost}/notes/${note.id}`
    commit(types.ADD_NOTE, {
        note,
        noteURI
    })
}

export const addNoteToTopic = ({commit}, {note, topic}) => {
    commit(types.ADD_NOTE_TO_TOPIC, {
        note, topic
    })
}

export const addTeamMember = ({commit}, {user}) => {
    commit(types.ADD_TEAM_MEMBER, {
        user
    })
}

export const addUser = ({commit}, {user}) => {
    commit(types.ADD_TEAM_MEMBER, {
        user
    })
}

export const addTeamMemberToAccount = ({ commit }, { user, account }) => {
    commit(types.ADD_TEAM_MEMBER_TO_ACCOUNT, {
        user, account
    })
}

export const removeTeamMember = ({commit}, {teamMember}) => {
    commit(types.DELETE_TEAM_MEMBER, {
        teamMember
    })
}

export const removeUser = ({ commit }, { user }) => {
    commit(types.DELETE_TEAM_MEMBER, {
        teamMember : user
    })
}

export const removeTeamMemberFromAccount = ({ commit }, { user, account }) => {
    commit(types.REMOVE_TEAM_MEMBER_FROM_ACCOUNT, {
        user, account
    })
}

export const updateTeamMemberInAccount = ({commit}, {user, account}) => {
    commit(types.UPDATE_TEAM_MEMBER_IN_ACCOUNT, {
        user, account
    })
}

export const updateUserProfile = ({commit}, payload ) => {
    commit(types.UPDATE_USER_PROFILE, {
        payload
    })
}

export const setListItemImageVisibility = ({commit}, boolean ) => {
    commit(types.SET_SHOW_IMAGES_IN_LIST_VIEW, {
        boolean
    })
}

export const removeSuggestion = ({commit}, {suggestion}) => {
    commit(types.DELETE_SUGGESTION, {
        suggestion
    })
}

export const setVendor = ({commit}, {vendor}) => {
    commit(types.SET_VENDOR, {
        vendor
    })
}

export const setUserPreference = ({commit}, {key, value}) => {
    if (key === 'filters' && value && value.sources && Array.isArray(value.sources) && value.sources.some(x => typeof x === 'object')) {
    }
    commit(types.SET_USER_PREFERENCE, {
        key, value
    })
}

export const setUserPreferences = ({ commit }, { preferences }) => {
    commit(types.SET_USER_PREFERENCES, {
        preferences
    })
}

export const setUserSignUpPlan = ({commit}, {plan}) => {
    commit(types.SET_USER_SIGNUP_PLAN, {plan})
}

export const setUserSignUpPath = ({commit}, {path}) => {
    commit(types.SET_USER_SIGNUP_PATH, {path})
}
