import * as types from '../mutation-types'
import features, {subscriptionFeaturesDefaults} from "@/Features";
import {hasValue, trueIfUndef} from "@/utilities/utils";
import Vue from "vue";

function api() {
    return Vue.prototype.$api
}

const state = {
    accounts : [],
    currentAccount : false,
    accountsBeingLoaded : [],
    subscriptions : [],
    lastFetchedAt : false,
    overviews : {},
    accountToLoadAfterHootsuiteAuthorization : false,
    accountToLoadAfterOauth:{
        sniply: false
    },
    accountIntegrationData: {

    },
    accountFeatureData: {},
    accountData: {},
}

export const featuresForAccount = (subscriptions, codeFeatures, currentAccountFeatureData) => account => {
        let subFeatures = subscriptionFeaturesForAccount(subscriptions)(account);
        let globFeatures = codeFeatures || features;
        let featureToggles = currentAccountFeatureData;
        let keys = [...new Set([...Object.keys(globFeatures), ...Object.keys(subFeatures)])];
        let featureStatus = key => {
            let codeToggle = trueIfUndef(globFeatures[key]);
            let toggle = featureToggles[key];
            let toggledOn = hasValue(toggle) ? toggle : codeToggle;
            let hasSubscription = trueIfUndef(subFeatures[key]);
            return hasSubscription && toggledOn;
        }
        return keys.reduce((result, key) => {
            return {
            ...result,
            [key]: featureStatus(key)
        }
    }, {})
};

const currentSubscription = state => subscriptionForAccount(state.subscriptions, state.currentAccount)
const subscriptionForAccount = (subscriptions, currentAccount) => {
    subscriptions = subscriptions.filter(s => currentAccount && s.account_id == currentAccount.id)
    if (subscriptions.length){
        return subscriptions[0];
    }
}

const currentPlans = state => (currentSubscription(state)||{}).plan_items||[];

const oneIfNotDef = x => !isNaN(parseInt(x)) ? parseInt(x) : 1

const planMetaValuesReducer = reduceValues => (combinedMetaData, plan) => (
    Object.keys(combinedMetaData).reduce((meta, key) => ({
        ...meta,
        [key]: reduceValues(combinedMetaData[key], plan[key], oneIfNotDef(plan.quantity))
    }), {}));

const reducedPlanMetaValues = (plans, reduceValues, initialMeta) =>
    plans.reduce(planMetaValuesReducer(reduceValues), initialMeta);

const subscriptionFeatures = state => _subscriptionFeatures(currentPlans(state));

const _subscriptionFeatures = plans => reducedPlanMetaValues(plans,
    (result, planVal) => (result || planVal) ? true : ((result===false)||(planVal===false)) ? false : undefined,
    subscriptionFeaturesDefaults
);

const subscriptionFeaturesForAccount = subscriptions => account => _subscriptionFeatures((subscriptionForAccount(subscriptions, account)||{}).plan_items||[])

const reducePlanLimits = plans => reducedPlanMetaValues(plans, (result, planVal, quantity) =>
    result === 'unlimited' || planVal === 'unlimited' ? 'unlimited' : Number(result) + Number(planVal) * quantity,
    { topics: 0, collections: 0, queries: 0, users: 0, contentPolicyRules:0, gallery_sites: 0, shutterstock_images: 0, proofpoint_configs: 0, contentTemplateCount: 0 }
)

const subscriptionLimits = state => {
    let plans = currentPlans(state);
    return plans.length ? reducePlanLimits(plans) :{ topics: 1, collections: 0, queries: 15, users: 1, contentPolicyRules:0 }
};


let subscriptionDataForAccount = function (allSubscriptions, acc) {
    let subscriptions = allSubscriptions.filter(sub => sub.account_id === acc.id);
    let planItems = subscriptions.flatMap(x => x.plan_items);
    let features = reducedPlanMetaValues(planItems,
        (result, planVal) => !!(result || planVal),
        {rss: false, crossAccount: false}
    );

    let limits = planItems.length ? reducePlanLimits(planItems) : {topics: 1, collections: 0, queries: 15, users: 1, contentPolicyRules:0}
    return {
        accountId: acc.id,
        subscriptions, planItems, features, limits
    }
};
const getters = {
    shareProviderForCurrentAccount: (state, getters, rootState, rootGetters) => {
        let currentAccount = getters.currentAccount;
        let shareProviderName = currentAccount && currentAccount.share_provider;
        let shareProviderOption = rootGetters.shareProviders.find(sp => sp.name === shareProviderName);
        if (shareProviderOption) return shareProviderName;


        shareProviderName = rootGetters.vendor;
        shareProviderOption = rootGetters.shareProviders.find(sp => sp.name === shareProviderName);
        if (shareProviderOption) return shareProviderName;

        return 'buffer'
    },
    currentAccount : state => {
        if (state.currentAccount){
            return state.accounts.find(a => a && a.id == state.currentAccount.id && state.currentAccount.id)
        }
    },
    currentShareProviderOptions: state => {
        return state.currentAccount
            && state.currentAccount.id
            && state.accountIntegrationData[state.currentAccount.id];
    },
    currentSubscription, currentPlans, subscriptionFeatures, subscriptionLimits, subscriptionFeaturesForAccount,
    currentPackage: (s, g) => g.currentPlans.find(p => p.plan_key === g.currentSubscription.plan_key),
    currentAccountFeatureData: (s) => s.accountFeatureData[s.currentAccount.id]||{},
    accountFeatures: (state, getters, rootState, rootGetters) => {
        return featuresForAccount(state.subscriptions, rootGetters.features, getters.currentAccountFeatureData)(getters.account)
    },
    account: state => {
        if (state.currentAccount) {
            return state.accounts.find(a => a && a.id == state.currentAccount.id && state.currentAccount.id)
        }
    },
    allAccounts : state => state.accounts.filter(x => x),
    accountsBeingLoaded : state => state.accountsBeingLoaded,
    subscriptions : state => state.subscriptions,
    accountsLastFetchedAt : state => state.lastFetchedAt,
    subscriptionPlan : state => {

        let subscriptions = state.subscriptions.filter(s => state.currentAccount && s.account_id == state.currentAccount.id)
        if (subscriptions.length){
            return subscriptions[0].package;
        }
    },
    subscriptionDataByAccount : state =>  {
        return state.accounts.map(acc => {
            return subscriptionDataForAccount(state.subscriptions, acc);
        })
    },
    accountsWithCrossAccount: (state, getters) => {
        return getters.subscriptionDataByAccount.filter(x => x.features.crossAccount).map(x => x.accountId)
    },
    accountOverviews : state => state.overviews,
    accountToLoadAfterHootsuiteAuthorization: state => state.accountToLoadAfterHootsuiteAuthorization,
    accountToLoadAfterOauth: state => state.accountToLoadAfterOauth,
    currentAccountData: state => state.accountData,
    currentAccountDataOpenai: state => state.accountData && state.accountData.openai,
}

const actions = {
    setAccountToLoadAfterHootsuiteAuthorization : ({commit}, {account}) => {
        commit("SET_ACCOUNT_TO_LOAD_AFTER_HOOTSUITE_AUTHORIZATION", {account});
    },
    setAccountToLoadAfterOAuth : ({commit}, {account, powerup}) => {
        commit("SET_ACCOUNT_TO_LOAD_AFTER_OAUTH", {account, powerup});
    },
    setFeatureData: ({commit}, {accountId, featureData}) => {
        commit('SET_FEATURE_DATA', {accountId, featureData});
    },
    async loadAccountData({commit, getters}) {
        if(getters.currentAccount && getters.currentAccount.id) {
            let accountData = ['openai'];
            let fetchData = async x => ({key: x, response: await api().get(`/accounts/${getters.currentAccount.id}/data/${x}`)});
            let results = await Promise.all(accountData.map(fetchData));
            results = results.map(({key, response}) =>
                response.status === 200 && response.data ? {key, response} : {key, response: {}}
            )
            results.forEach(({key, response: {data}}) => {
                commit("SET_ACCOUNT_DATA", {key, data});
            });
        }
    },
}


const mutations = {
    SET_FEATURE_DATA: (state, {accountId, featureData}) => {
        state.accountFeatureData[accountId] = featureData;
    },
    SET_SHARE_PROVIDER: (state, {shareProvider, account}) => {
        state.accounts.find(a => a.id === account.id).share_provider = shareProvider;
    },
    SET_ACCOUNT_TO_LOAD_AFTER_HOOTSUITE_AUTHORIZATION : (state, {account}) => {
        state.accountToLoadAfterHootsuiteAuthorization = account;
    },
    SET_ACCOUNT_TO_LOAD_AFTER_OAUTH : (state, {account, powerup}) => {
        state.accountToLoadAfterOauth[powerup] = account;
    },
    SET_ACCOUNT_DATA: (state, {key, data}) => {
        state.accountData = {...state.accountData, [key]: data}
    },
    [types.ADD_ACCOUNT_OVERVIEW](state, {overview}){
        state.overviews[overview.id] = overview;
    },
    [types.SET_LATEST_ACCOUNTS_FETCHED_AT](state, {date}){
        state.lastFetchedAt = date;
    },
    [types.ADD_SUBSCRIPTION_TO_ACCOUNT](state, {account, subscription}){
        let sub = { ...subscription, account_id: account.id }
        let existing = state.subscriptions.find(s => sub.account_id == s.account_id)
        if (typeof sub.plan_items === "string") sub.plan_items = JSON.parse(sub.plan_items);

        if ( ! existing){
            state.subscriptions.push(sub)
        } else {
            let index = state.subscriptions.indexOf(existing)
            state.subscriptions.splice(index, 1, sub)
        }
    },
    [types.ADD_ACCOUNT](state, {account}){
        if (!account) return;
        let existing = state.accounts.find(a => a && account.id == a.id);
        if ( ! existing){
            state.accounts.push(account)
        } else if(existing.updated < account.updated || ! existing.updated) {
            let index = state.accounts.indexOf(existing);
            state.accounts.splice(index, 1, account);
        }
    },
    [types.SET_ACCOUNT](state, {account}){
        state.currentAccount = account
    },
    [types.USER_DID_LOG_OUT](state) {
        state.currentAccount = false;
        state.accounts = [];
        state.accountsBeingLoaded = [];
        state.subscriptions = [];
        state.lastFetchedAt = false;
        state.signupPath = '';
        state.overviews = {}
    },
    [types.BEGIN_DOWNLOADING_ACCOUNT](state, {url}){
        if ( state.accountsBeingLoaded.indexOf(url) == -1 )
            state.accountsBeingLoaded.push(url)
    },
    [types.COMPLETE_DOWNLOADING_ACCOUNT](state, {account}){
        if (!account) return;
        state.accountsBeingLoaded = state.accountsBeingLoaded.filter(url => {
            return ! url.includes(account.id)
        })

        if ( ! state.accounts.find(a => a.id == account.id))
            state.accounts.push(account);
    },
    [types.UPDATE_ACCOUNT](state, {account}){
        let existing = state.accounts.find(a => account.id == a.id);
        if (existing) {
            let index = state.accounts.indexOf(existing);
            state.accounts.splice(index, 1, account);

        }
    },
    [types.ADD_FOLDER_TO_FOLDER](state, { folder, account }) {

        var accountToUpdate = state.accounts.find(a => a.id == account.id);

        if (accountToUpdate) {
            let index = state.accounts.indexOf(accountToUpdate)
            accountToUpdate.folders.push(folder.id)
            state.accounts.splice(index, 1, accountToUpdate)
        }

    },
    [types.ADD_TEAM_MEMBER_TO_ACCOUNT](state, {user, account}){
        let existingAccount = state.accounts.find(a => a.id == account.id);
        if (existingAccount){
            let index = state.accounts.indexOf(existingAccount)
            if ( ! account.users.find(u => u.includes(user.id))) {
                let users = [...account.users, user.id]
                let updatedAccount = { ...account, users }
                state.accounts.splice(index, 1, updatedAccount)
            }
        }
    },
    [types.DELETE_ACCOUNT](state, {account}){

        let existingAccount = state.accounts.find(a => a.id == account.id);
        if (existingAccount) {
            let index = state.accounts.indexOf(existingAccount);
            state.accounts.splice(index, 1);
        }

        if (state.currentAccount.id == existingAccount.id){
            state.currentAccount = state.accounts[0];
        }
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
