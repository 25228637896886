<template>
    <span v-show="!hideShare">
        <button v-if="showMobileShareButton" class="btn btn-link p-0 m-0 mobile-share-btn" @click="shareLink($event)" :title="'Share '+suggestion.title" >
            <i class="fa fa-share" /><br class="d-block d-lg-none" /> {{text}}
        </button>
        <a v-else @click="shareLink($event)" :title="'Share '+suggestion.title" :href="url" target="_blank" class="p-0 m-0" >
            <i class="fa fa-share" /><br class="d-block d-lg-none" /> {{text}}
        </a>
    </span>
</template>

<script>
import * as shareProviders from '@/services/ShareProviders';
import Embedded from '@/utilities/Embedded'
import {mapGettersTo} from '@/store/helpers';

export default {
    props : ['suggestion', 'text'],
    mixins: [Embedded],
    created() {
        mapGettersTo({
            account: 'currentAccount',
            accountFeatures: 'accountFeatures',
            apis: 'externalApis',
            deviceShare: 'deviceShare',
            features: 'features',
            hasSuggestions: 'hasSuggestions',
            shareProvider: 'shareProviderForCurrentAccount',
            vendor : 'vendor',
            vendorEmbedShareProvider: 'vendorEmbedShareProvider',
            hideShare: 'hideShare'
        }, this)
    },
    computed : {
        browserSupportsShare() { return navigator && navigator.share; },
        mobileShareAllowed() { return this.browserSupportsShare && this.features.nativeShare && this.$mobileCheck.isMobile(); },
        showMobileShareButton() { return this.mobileShareAllowed && this.deviceShare !== false; },
        shareProviderInfo() {
            return this.apis[this.shareProvider] && this.apis[this.shareProvider][this.account.id] || {};
        },
        shareText() { return this.suggestion.summary || this.suggestion.title },
        link() { return this.$shortener.link(this.suggestion) },
        type() {
            return this.hasSuggestions.type === 'collection' ?  'selection' : 'suggestion';
        },
        url(){
            let text = encodeURIComponent(this.shareText)
            let link = encodeURIComponent(this.link)

            let urlTemplate = shareProviders[this.shareProvider].template;
            let providerAccountMeta = Object
                .keys(this.shareProviderInfo)
                .reduce((result, key) => ({
                    ...result,
                    [key]: encodeURIComponent(this.shareProviderInfo[key])
                }), {});

            return urlTemplate({text, link}, providerAccountMeta)
        },
    },
    methods : {
        async customShareFunction(vendor) {
            let share = shareProviders[vendor].func;
            share({text: this.shareText, link: this.link, contentItem: this.suggestion, type: this.type})
        },
        async shareLink(e){

            // if (this.$mobileCheck.isMobile()){
            //     e.preventDefault();
            //     setTimeout( () =>  { window.location = "https://itunes.apple.com/us/app/buffer-social-media-manager/id490474324?mt=8" }, 25);
            //     // Hootsuite app url https://itunes.apple.com/us/app/hootsuite-social-media-tools/id341249709?mt=8
            //     let text = encodeURIComponent(this.suggestion.title)
            //     let link = encodeURIComponent(this.suggestion.source_url)

            //     window.location = `bufferapp://?t=${text}&u=${link}`;
            // }

            if (this.vendorEmbedShareProvider){
                e.preventDefault();
                if (this.vendorEmbedShareProvider.type === 'function') {
                    this.customShareFunction(this.vendorEmbedShareProvider.vendor)
                } else if (this.vendorEmbedShareProvider.type === 'messaging') {
                    this.messageParent('upcontent.share.request', {
                        text: this.shareText, link: this.link
                    })
                }
            }
            else if (this.mobileShareAllowed && this.deviceShare !== false) {
                if (this.deviceShare) {
                    e.preventDefault();
                    this.doMobileShare();
                } else {
                    let deviceShare = confirm("Would you like to use your mobile device’s native share feature?");
                    console.log('deviceShare', deviceShare)
                    await this.$store.dispatch('setDeviceShare', { value: deviceShare });
                    if (deviceShare) {
                        e.preventDefault();
                        this.doMobileShare();
                    } else if (this.shareProvider === 'hubspot' && this.accountFeatures.hubspotShareV2) {
                        this.customShareFunction('hubspot')
                    } else {
                        window.open(this.url, '_blank')
                    }
                }
            }  else if (this.shareProvider === 'hubspot' && this.accountFeatures.hubspotShareV2) {
                e.preventDefault();
                this.customShareFunction('hubspot')
            }
            this.messageParent('upcontent.share.initiated', {
                text: this.shareText, link: this.link
            })
        },
        doMobileShare() {
            navigator.share({
                title: this.shareText,
                // text: 'Check out Web Fundamentals — it rocks!',
                url: this.link,
            })

        }
    }
}
</script>

<style scoped>
    .mobile-share-btn {
        font-size: 100%;
    }
</style>
