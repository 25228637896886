<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props : [],
    computed : {
        ...mapGetters({
            notes : 'notes',
            suggestions : 'suggestionsCache',
            topics : 'topics',
            collections : 'collections'
        }),
    },
    watch : {
        suggestions(){
            this.refreshNotesForSuggestions()
        },
        topics(){
            this.refreshNotesForTopics();
        },
        collections(){
            this.refreshNotesForCollections();
        }
    },
    data(){
        return {

        }
    },
    mounted(){
        this.refreshNotesForTopics();
        this.refreshNotesForCollections();
        this.refreshNotesForSuggestions();
    },
    methods : {
        ...mapActions([
            'addNote',
            'deleteNote'
        ]),
        refreshNotesForTopics(){
            this.refreshNotes(this.topics);
        },
        refreshNotesForCollections(){
            this.refreshNotes(this.suggestions);
        },
        refreshNotesForSuggestions(){
            this.refreshNotes(this.suggestions);
        },
        refreshNotes(notesContainer){

            var noteUrls = [];

            notesContainer
                .filter(container => container.notes && container.notes.length > 0)
                .map(container => container.notes)
                .forEach(urls => noteUrls = noteUrls.concat(urls));

            let notesToDownload = noteUrls.filter(url => {
                let id = url.split('/').pop();
                return ! this.notes.find(n => n.id == id);
            })

            let notesToDelete = this.notes.filter(id => {
                return ! noteUrls.find(url => url.includes(id));
            })

            this.downloadNotes(notesToDownload);
            this.deleteNotes(notesToDelete)
        },
        downloadNotes(noteUrls){
            noteUrls.forEach(url => {
                this.$api.get(url).then(response => {
                    let note = response.data;
                    this.addNote({note})
                }).catch(() => {
                    let noteToDelete = this.notes.find(n => url.includes(n.id));
                    if (noteToDelete)
                        this.deleteNote({note : noteToDelete});
                })
            })
        },
        deleteNotes(notesToDelete){
            notesToDelete.forEach(note => this.deleteNote({note}));
        }
    },
    render(h){
        return h()
    }
}
</script>