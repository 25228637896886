<template>
    <modal id="notes-reader-modal" ref="notesReader" size="lg">
            <div>

                <notes-view-controller v-if="isShowing" />
                
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close Notes</button>
            </template>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as $ from 'jquery'
import NotesViewController from './ModalReader/NotesViewController'
import Modal from "../Controls/Modal";

export default {
    props : [],
    data(){
        return {
            isShowing : false,
            shouldTriggerFetch: true
        }
    },
    components : {
        Modal,
        NotesViewController
    },
    computed : {
        ...mapGetters({
            'currentAccount': 'account',
            'showAccountNotesReader' : 'showAccountNotesReader',
            'readingSuggestion' : 'readingSuggestion',
        }),
    },
    mounted() {
        this.$refs.notesReader.$el.addEventListener('hide.bs.modal', () => {
          if (this.showAccountNotesReader) this.toggleNotes()
        })
    },
    watch : {
        shouldTriggerFetch()  {
          // NOTE(casio): tmp workaround until notes fetching is being reworked. 
          // Reset shouldTriggerFetch after a while so new notes can be loaded, w/o fetching 
          // all notes & suggestion every time the user opens the modal
          if(!this.shouldTriggerFetch) {
            setTimeout(() => {
              this.shouldTriggerFetch = true 
            }, 20000 /* 20sec */)
          }
        },
        showAccountNotesReader(){
            if (this.showAccountNotesReader){
                // The first time this the notes modal is showing up, fetch all notes
                if (this.shouldTriggerFetch) {
                  window.Events.$emit('FetchAccountNotes', {accountId: this.currentAccount.id})
                  this.shouldTriggerFetch = false;
                }
                this.$refs.notesReader.show()
                this.isShowing = true;
            } else {
                this.$refs.notesReader.hide()
                this.isShowing = false;
            }
        },
        readingSuggestion(){
            if (this.readingSuggestion)
                this.$refs.notesReader.hide()
        }
    },
    methods : {
    ...mapActions({
            toggleNotes : 'toggleAccountNotesReader'
        }),
    }
}
</script>

<style>
</style>
