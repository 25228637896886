import axios from "axios";

const state = {
    cancelTokens: {

    }
}
const getters = {

}
const actions = {
    async getNewTokenAndCancelPreviousRequests({commit, dispatch}, {category}) {
        await dispatch('cancelPreviousRequests', {category})
        return await dispatch('getNewCancelToken', {category})
    },
    async getNewCancelToken({commit, dispatch}, {category}) {
        var axiosSource = axios.CancelToken.source()
        var cancelToken = axiosSource.token;
        await dispatch(actions.addCancelToken.name, {category, token: axiosSource})
        return cancelToken
    },
    addCancelToken({commit, dispatch}, {category, token}) {
        commit(mutations.ADD_CANCEL_TOKEN.name, {category, token})
    },
    cancelPreviousRequests({commit, dispatch, state}, {category}) {
        if (state.cancelTokens[category])
            state.cancelTokens[category].forEach(token => {
                if (token.cancel)
                    token.cancel();
            })
        commit(mutations.RESET_CANCEL_TOKENS.name, {category})
    },
}
const mutations = {
    RESET_CANCEL_TOKENS(state, {category}) {
        state.cancelTokens[category] = [];
    },
    ADD_CANCEL_TOKEN(state, {category, token}) {
        let existing = state.cancelTokens[category] || []
        state.cancelTokens[category] = [...existing, token];
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}
