<script> // FIXME: ts issues
import store from '@/store'
import {computed, ref} from "vue";
import QualityChecks from "@/components/QualityChecks/QualityChecks.vue";
import {Tab, Tabs} from "@/components/Controls/Tabs";
import Classifiers from "@/components/Settings/Team/Classifiers.vue";
import Policies from "@/components/Settings/Team/Policies.vue";

// need to use Vue 3 export default format because component must have explicit name option
export default {
    name: 'ContentQualityPage',
    computed: {
        store() {
            return store
        }
    },
    components: {Policies, Classifiers, QualityChecks, Tab, Tabs},
    setup() {
        const hasClassifierUpdates = ref(false);
        const features = computed(() => store.getters.accountFeatures);
        const contentPolicyEnabled = computed(() => features.value.contentPolicy);
        const hideIntegrations = computed(() => store.getters.hideIntegrations);
        const account = computed(() => store.getters.account);

        const handleUpdatePolicyTab = () => {
            hasClassifierUpdates.value = true;
        };

        const handleResetHasClassifierUpdates = () => {
            hasClassifierUpdates.value = false;
        }
        return {
            hasClassifierUpdates,
            contentPolicyEnabled,
            hideIntegrations,
            account,
            handleUpdatePolicyTab,
            handleResetHasClassifierUpdates,
        }
    }
}
</script>

<template>
    <div>
        <tabs v-if="contentPolicyEnabled">
            <tab label="Classifiers">
                <Classifiers @updatePolicyTab="handleUpdatePolicyTab"/>
            </tab>
            <tab label="Policies">
                <div class="Policies">
                    <Policies
                        :has-classifier-updates="hasClassifierUpdates"
                        @reset-has-classifier-updates="handleResetHasClassifierUpdates"
                    />
                </div>
            </tab>
            <tab v-if="!hideIntegrations" label="Proofpoint Tags">
                <QualityChecks :account="account"></QualityChecks>
            </tab>
        </tabs>
    </div>
</template>

<style scoped>

</style>
