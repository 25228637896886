<script>

import QueryString from './QueryStringMixin'

export default {
    props : ['topic', 'account', 'language'],
    mixins : [QueryString],
    data(){
        return {
            alls : [],
            ors : [],
            nots : [],
            networkActivity: false,
            networkError : false,
            showUpsell : false,
            queryError : false,
            queryErrorTimeout: false,
        }
    },
    watch : {
        queryError(){
            if (this.queryErrorTimeout && this.queryError){
                clearTimeout(this.queryErrorTimeout)
                setTimeout(() => {
                    this.queryError = false;
                }, 4000);
            } else {
                this.queryErrorTimeout = setTimeout(() => {this.queryError = false}, 4000);
            }
        },
        topic(){
            this.alls = [];
            this.ors = [];
            this.nots = [];
        },
        alls(){

            if (! this.topic.name && this.alls.length){
                this.topic.name = this.$strings.capitalizeWords(this.alls[0]);
            }

            while (this.queryString.length > 134 && this.alls.length){
                this.alls.pop()
                this.queryError = "You entered too many additional search terms. Remove some search terms for better results."
            }

        },
        ors(){
            while (this.queryString.length > 134 && this.ors.length){
                this.ors.pop()
                this.queryError = "You entered too many additional search terms. Remove some search terms for better results."
            }

        },
        nots(){
            while (this.queryString.length > 134 && this.nots.length){
                this.nots.pop()
                this.queryError = "You entered too many additional search terms. Remove some search terms for better results."
            }
        },
    },
    methods : {
         validateTermString(term){
            let punctuation = new RegExp(/[,]/);

            if (punctuation.test(term)){
                this.queryError = "Don't use commas here."
                return term.replace(punctuation, '');
            }

            if (term.split(" ").length > 7){
                this.queryError = "You cannot use more than seven words here."
                return term.split(" ").slice(0,7).join(" ");
            }

            return term;
        },
        begin(){
            let primaryInput = this.$refs.primaryInput;
            if( primaryInput && typeof primaryInput.focus === 'function'){
                primaryInput.focus();
            }
        },
        updateTopicName(){
            console.log('updateTopicName', {});
            this.$emit('networkActivity', 'Saving...');
            let topic = {...this.topic, queries : this.topic.queries.map(q => q.value)}
            return this.$api.put('/topics/'+topic.id+'/name', {name : topic.name})
        },
        updateTopicCulture(culture){
            console.log('updateTopicCulture', {culture});
            this.$emit('networkActivity', 'Saving...');
            let topic = {...this.topic, queries : this.topic.queries.map(q => q.value), culture};

            console.log('/topics/'+topic.id+'/culture', {culture})

            return this.$api.put('/topics/'+topic.id+'/culture', {culture})
        },
        updateTopicQueries(){
            console.log('updateTopicQueries', {});
            this.$emit('networkActivity', 'Saving...');
            let topic = {...this.topic, queries : this.topic.queries.map(q => q.value)}
            return this.$api.put('/topics/'+topic.id+'/queries', {queries : topic.queries})
        },
        finishSave() {
            let topic = {...this.topic, queries : this.topic.queries.map(q => q.value)};
            console.log('finishSave', {topic});
            this.$store.dispatch('updateTopic', {topic})
            this.$emit('networkActivity', false);
            this.networkActivity = false;
            window.Events.$emit('RefreshCurrentSuggestionUrls');
        },
        storeNewTopic(){

            this.$emit('networkActivity', 'Saving...');
            let topic = {...this.topic, queries : this.topic.queries.map(q => q.value)}
            let topicData = {
                name : topic.name,
                culture: this.language,
                queries: topic.queries,
                folder : topic.folder
            }

            return this.$api.post(`/accounts/${this.account.id}/topics`, topicData).then(response => {

                this.$emit('networkActivity', false);
                this.networkActivity = false;
                let updatedTopic = response.data

                this.$gtm.trackEvent({
                        category: 'UserActivity',
                        event : 'NewTopicCreated',
                        action: 'click',
                        label: 'Topic Created',
                        value: updatedTopic.queries.join(",")
                    });

                this.$store.dispatch('addTopic', {topic : updatedTopic})
                this.$store.dispatch('setCurrentTopic', {topic: updatedTopic});
                this.$emit('dismiss')

            })

        },
        handleErrors(e){
            this.networkActivity = false
            this.$emit('networkActivity', false);
            if (e.response){
                switch(e.response.status){
                    case 403:
                        this.networkError = "You do not have permission to edit this topic"
                    break;
                    case 400:
                        if (e.response.data.title.toLowerCase().includes('exceeded')){
                            this.$emit('showUpsell')
                        } else {
                            this.networkError = e.response.data.description
                        }
                    break;
                    default:
                        this.networkError = e.response.data.description
                    break;
                }
            }
        },
        addCurrentQuery(){
            if(this.nots.length && ! (this.alls.length || this.ors.length)){
                alert("You cannot create this query. Please add terms to the ALL or ONE fields");
                return;
            }

            if (! this.nots.length && ! this.alls.length && ! this.ors.length){
                return;
            }

            var lastQuery = this.topic.queries[this.topic.queries.length - 1];
            let value = this.queryString;

            if (lastQuery && lastQuery.value == ''){
                this.topic.queries.splice(this.topic.queries.indexOf(lastQuery), 1, {value})
            } else {
                this.topic.queries.push({value})
            }

            this.alls = [];
            this.ors = [];
            this.nots = [];

        }
    }
}
</script>

<style>
</style>
