<template>
    <modal ref="queryBuilderModal"
         id="query-builder-modal">
                <template v-slot:header>
                    <div  class="header d-sm-flex align-items-center">
                    <div class="modal-heading">
                        <div class="dropdown" v-if="canChangeMode">
                            <button class="btn btn-outline-secondary btn-sm small dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                {{ showMode }} Query Builder
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="#" @click.prevent="mode = 'guided'">
                                    <b>Guided Query Builder</b>
                                    <br><small class="text-muted"><i class="fa fa-bolt"></i> Follow the step-by-step
                                    process to create a simple, but effective query</small>
                                </a>
                                <a class="dropdown-item" href="#" @click.prevent="mode = 'advanced'">
                                    <b>Advanced Query Builder</b>
                                    <br><small class="text-muted"><i class="fa fa-graduation-cap"></i> Build your own
                                    query using Boolean operators</small>
                                </a>
                            </div>
                        </div>
                        <span style="font-size:1rem;font-weight: 400; opacity: 0.6; margin-left: 1rem;" v-else>{{
                                showMode
                            }} Query Builder</span>
                    </div>

                    <div class="ms-auto" v-if="features.languageInTopicSearch">
                        <label>
                            <topic-language-select v-model="language" aria-label="language"></topic-language-select>
                        </label>
                    </div>
                    <div class="ms-auto me-2">
                        <a class="btn btn-sm btn-secondary small" :href="helpButtonUrl" v-if="helpButtonUrl" target="_blank">
                            <i class="fa fa-question-circle"></i> Learn about what makes a good topic</a>
                    </div>
                    </div>
                </template>
                <div>
                    <upsell
                        v-if="showUpsell"
                        :account="account"
                        @complete="upsellComplete"
                        @replaceExistingTopic="replaceExistingTopic"
                        :new-topic-name="topic.name"
                    />
                    <div v-else>
                        <guided
                            :topic="topic"
                            v-if="mode == 'guided'"
                            :account="account"
                            :language="language"
                            ref="guidedBuilder"
                            @showUpsell="showUpsell = true"
                            @dismiss="dismiss"
                            @stageReached="guidedReachedStage"
                            @startOver="restart"
                            @complete="saveTopic"
                            @networkActivity="networkActivity = $event"
                        />
                        <advanced
                            :topic="topic"
                            v-else
                            v-show="mode == 'advanced'"
                            :account="account"
                            :language="language"
                            ref="advancedBuilder"
                            @showUpsell="showUpsell = true"
                            @dismiss="dismiss"
                            @startOver="restart"
                            @networkActivity="networkActivity = $event"
                        />
                    </div>

                </div>

                <div class="alert alert-danger" v-if="errorMessage">
                    {{ errorMessage }}
                </div>

                <template v-slot:footer v-if="networkActivity">
                    <div class="alert alert-green">
                        {{ networkActivity }}
                    </div>
                </template>
                <template v-else v-slot:footer>
                    <template v-if="mode == 'advanced' && ! showUpsell">
                        <div class="d-sm-flex d-none justify-content-around align-items-center" style="flex:1 1 0%;">
                            <div class="me-auto">
                                <select v-model="folderId"
                                        v-if="availableFolders.length > 0 & ! topic.id"
                                        class="form-control form-select">
                                    <option :value="rootFolderId">Select a Folder</option>
                                    <option v-for="folder in availableFolders"
                                            :key="'query-builder-folder-'+folder.id"
                                            :value="folder.id">
                                        {{ folder.name }}
                                    </option>
                                </select>
                            </div>
                            <button class="ms-2  mb-2 btn btn-secondary" @click="addEmptyQuery">
                                Add Manual Query
                            </button>
                            <button class="ms-2  mb-2 btn btn-secondary" @click="addQuery">
                                Add Query
                            </button>
                            <button
                                class="ms-2  mb-2 btn btn-primary"
                                @click="saveTopic"
                                :disabled="!topic.name"
                            >
                                <span v-text="saveButtonText"></span>
                            </button>
                        </div>


                        <div class="d-sm-none d-block" style="flex:1;">
                            <select v-model="folderId"
                                    v-if="availableFolders.length > 0 & ! topic.id"
                                    class="form-control form-select d-block mb-2">
                                <option :value="rootFolderId">Select a Folder</option>
                                <option v-for="folder in availableFolders"
                                        :key="'query-builder-folder-'+folder.id"
                                        :value="folder.id">
                                    {{ folder.name }}
                                </option>
                            </select>
                            <button class="full-width btn btn-secondary mb-2" @click="addEmptyQuery">
                                Add Manual Query
                            </button>
                            <button class="full-width btn btn-secondary mb-2" @click="addQuery">
                                Add Query
                            </button>
                            <button
                                class="full-width btn btn-primary mb-2"
                                @click="saveTopic"
                                :disabled="!topic.name"
                            >
                                <span v-text="saveButtonText"></span>
                            </button>
                        </div>

                    </template>
                    <div class="d-flex align-items-end full-width justify-content-between"
                         v-if="mode == 'guided' && ! showUpsell && readyToSave">
                    <span>
                        <span v-if="mode == 'guided' && availableFolders.length > 0"
                              class="small mb-2  d-flex justify-content-between align-items-end text-success">
                            <i class="fa fa-arrow-down animated bounce mb-1 me-1"></i>
                            <span class="ps-1">Add your topic to a folder (if you like) for easy organization</span>
                        </span>
                        <select class="form-control form-select" v-model="folderId" v-if="availableFolders.length > 0 & ! topic.id">
                            <option :value="rootFolderId">Select a Folder</option>
                            <option v-for="folder in availableFolders"
                                    :key="'query-builder-folder-'+folder.id"
                                    :value="folder.id">
                                {{ folder.name }}
                            </option>
                        </select>
                    </span>


                        <button
                            class="btn btn-primary ms-auto"
                            @click="saveTopic"
                            :disabled="!topic.name"
                        >
                            <span v-text="saveButtonText"></span>
                        </button>
                    </div>
                </template>

    </modal>
</template>

<script>

import * as $ from 'jquery'
import Guided from './QueryPotter'
import Advanced from './Advanced'
import Upsell from './Upsell'
import {mapGetters} from 'vuex';
import capitalize from 'capitalize'
import TopicLanguageSelect from "@/components/Topics/TopicCultureSelector";
import Modal from "../Controls/Modal";

export default {
    props: [],
    components: {
        Modal,
        Guided, Advanced, Upsell, TopicLanguageSelect
    },
    data() {
        return {
            mode: 'guided',
            language: null,
            originalTopic: false,
            topic: false,
            folderId: false,
            showUpsell: false,
            errorMessage: false,
            readyToSave: false,
            allowChangingMode: true,
            networkActivity: false
        }
    },
    watch: {
        folderId() {
            if (this.topic)
                this.topic.folder = this.folderId
        }
    },
    computed: {
        ...mapGetters({
            account: 'currentAccount',
            folders: 'folders',
            features: 'accountFeatures',
            learnUrls: 'learnUrls',
            currentTopic: 'currentTopic'
        }),
        isEdit() {
            return Boolean(this.topic && this.topic.id)
        },
        canChangeMode() {
            return !this.changesMade && !this.topic.id && this.allowChangingMode;
        },
        saveButtonText() {
            var text = 'Save Topic';
            if (!this.topic) return text;

            if (!this.topic.id) {
                text = 'Create Topic'
            }

            return text;
        },
        availableFolders() {
            if (!this.account) return [];
            return this.$sort.alphabetize(
                this.folders.filter(f => f.account == this.account.id && f.id !== this.rootFolderId))
        },
        rootFolderId() {
            if (this.account && this.account.root) {
                let rootFolder = this.folders.filter(f => f).find(f => this.account.root.includes(f.id));
                if (rootFolder) {
                    return rootFolder.id
                }
            }
        },
        originalQueries() {
            if (this.originalTopic)
                return JSON.stringify(this.originalTopic.queries)
        },
        updatedQueries() {
            return JSON.stringify(this.topic.queries.map(q => {
                return {value: q.value}
            }));
        },
        queriesWereChanged() {
            if (this.topic && this.originalTopic)
                return this.originalQueries !== this.updatedQueries;
        },
        nameWasChanged() {
            if (this.topic && this.originalTopic)
                return this.topic.name !== this.originalTopic.name
        },
        cultureWasChanged() {
            return this.language !== this.originalTopic && this.originalTopic.culture
        },
        changesMade() {
            return this.nameWasChanged || this.queriesWereChanged || this.cultureWasChanged;
        },
        queryBuilder() {
            var builder = false;
            switch (this.mode) {
                case 'advanced':
                    builder = this.$refs.advancedBuilder;
                    break;
                case 'guided':
                    builder = this.$refs.guidedBuilder;
                    break;
                default:
                    builder = this.$refs.advancedBuilder;

            }
            console.log('queryBuilder', this.mode, builder)
            return builder;
        },
        helpButtonUrl() {
            return this.learnUrls.whatMakesAGoodTopic;
        },
        showMode() {
            return capitalize(this.mode);
        }
    },
    mounted() {
        window.Events.$on('showQueryBuilderModal', ({topic, mode}) => {

            if (mode) {
                this.mode = mode;
            } else {
                this.mode = 'guided';
            }

            if (!topic) {
                this.topic = {name: '', queries: [], folder: this.rootFolderId}
            } else {
                this.topic = {
                    ...topic, queries: topic.queries.map(a => {
                        return {value: a}
                    })
                };
                this.$intercom.trackEvent('Edit topic', {topic});
            }
            this.originalTopic = JSON.parse(JSON.stringify(this.topic));
            console.log({topic});
            this.language = topic.culture;

            this.folderId = this.rootFolderId

            this.$refs.queryBuilderModal.show();
        });

        this.$refs.queryBuilderModal.$el.addEventListener('hidden.bs.modal', () => {
            this.topic = {name: '', queries: [], folder: this.rootFolderId};
            this.folderId = false
            this.showUpsell = false;
            this.restart();
            this.queryBuilder.restart();
            this.mode = 'neither';
            this.readyToSave = false;
            window.Events.$emit('dismissQueryBuilderModal');
        })

        this.$watch('topic', () => {
            this.errorMessage = false
        }, {deep: true});

        this.$refs.queryBuilderModal.$el.addEventListener('shown.bs.modal', () => {
            this.queryBuilder.begin();
        })

    },
    methods: {
        replaceExistingTopic() {

            this.$api.delete('/topics/' + this.currentTopic.id).catch(() => {
                this.$store.dispatch('addTopic', {topic: this.currentTopic})
            }).then(() => {
                this.$store.dispatch('deleteTopic', {topic: this.currentTopic});
                this.queryBuilder.storeNewTopic();
                this.dismiss();
            });


        },
        upsellComplete() {
            this.showUpsell = false;
            this.saveTopic();
            this.dismiss();
        },
        guidedReachedStage(stage) {
            if (stage == 3) {
                this.readyToSave = true;
            } else {
                this.readyToSave = false;
            }
        },
        addEmptyQuery() {
            this.queryBuilder.addEmptyQuery();
        },
        addQuery() {
            this.queryBuilder.addCurrentQuery();
        },
        async saveTopic() {
            console.log('saveTopic', {
                id: this.topic.id,
                qb: this.queryBuilder,
                qc: this.queriesWereChanged,
                nc: this.nameWasChanged,
                cm: this.changesMade
            })
            this.addQuery();

            if (!this.topic.queries.length) {
                this.errorMessage = "You haven't added any queries to this topic yet!"
                return;
            }

            if (!this.topic.name.length) {
                this.errorMessage = "You must give your topic a name";
                return;
            }

            if (this.changesMade && this.topic.id) {

                if (this.queryBuilder) {
                    try {
                        // These are not in parallel in order to avoid conflicts saving last accessed.
                        // We should find a better way on backend but for now
                        if (this.nameWasChanged) {
                            await this.queryBuilder.updateTopicName()
                        }
                        if (this.cultureWasChanged) {
                            await this.queryBuilder.updateTopicCulture(this.language)
                            this.topic.culture = this.language;
                        }
                        if (this.queriesWereChanged) {
                            await this.queryBuilder.updateTopicQueries();
                        }
                        this.queryBuilder.finishSave();
                        this.dismiss();
                    } catch (e) {
                        this.queryBuilder.handleErrors(e);
                    }
                } else {
                    this.dismiss()
                }

                await this.$store.dispatch('setCurrentTopic', {topic : this.topic});
            } else if (!this.topic.id) {
                try {
                    await this.queryBuilder.storeNewTopic()
                    this.queryBuilder.finishSave();
                    this.dismiss();
                } catch (e) {
                    this.queryBuilder.handleErrors(e);
                }
            } else {
                this.dismiss()
            }

        },
        dismiss() {
            this.$refs.queryBuilderModal.hide();
        },
        restart() {
            this.language = null;
            this.topic.queries = [];
            this.topic.name = '';
            this.topic.folder = this.rootFolderId;
        }
    }
}
</script>

<style lang="scss">
// Do not scope these styles cause it's the modal!
#query-builder-modal {
    .modal-header {
        padding-top: 0!important;
        padding-bottom: .5rem!important;
    }
    .header {
        padding-left: 0!important;
    }
}
</style>


<style lang="scss" scoped>

h5 {
    font-weight: normal;
    font-size: 100%;
}

.header {
    padding: 1rem;
}
.modal-heading {
    font-size: 1rem;
}
.animated.bounce {
    animation-duration: 3s;
}
</style>

<style>

#query-builder-modal .modal-dialog{
  max-width: 700px;
}

#query-builder-modal .modal-footer .control-grid {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
    flex: 1;
}

@media screen and (min-width: 600px) {
    #query-builder-modal .modal-dialog {
        width: 90%;
        max-width: 960px;
    }
}

@media screen and (max-width: 500px) {
    #query-builder-modal .modal-footer .control-grid {
        grid-template-columns: auto;
    }
}

#query-builder-modal .modal-footer .control-grid div * {
    display: block;
    width: 100%;
}
</style>
