<template>
    <div>
        <h5>Change Account Name</h5>
        <p class="small text-muted">Changes are saved automatically</p>
        <input class="form-control" v-model="account.name" @change="changeAccountName" />

        <hr />
        <h4 v-if="features.experimental">Applications</h4>
        <application :team="team" v-if="features.experimental"></application>
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex';
import Integrations from './Integrations';
import Application from "@/components/Settings/Team/Application";

export default {
    props : ['team'],
    components: {Application, Integrations},
    computed : {
        ...mapGetters({
            apis : 'externalApis',
            features: 'features'
        }),
        amplifyApiData(){
            return this.apis.amplify && this.apis.amplify[this.team.id]
        },
        amplifyConnectUrl(){
            let uri = this.$strings.replaceAll(process.env.VUE_APP_HOOTSUITE_AMPLIFY_REDIRECT_URI, "%2F", "/");
            return `https://platform.hootsuite.com/oauth2/auth/?client_id=${process.env.VUE_APP_HOOTSUITE_AMPLIFY_CLIENT_ID}&response_type=code&scope=offline&redirect_uri=https://${uri}`
        }
    },
    data(){
        return {
            account : {...this.team},
        }
    },
    watch : {
        team(){
            this.account = {...this.team}
         }
    },
    mounted(){
    },
    methods : {
        changeAccountName(){
            this.account.updated = moment().utc().format();
            this.$store.dispatch('addAccount', {account: this.account})
            this.$api.put(`/accounts/${this.account.id}/name`, {name : this.account.name})
        },
        removeAmplify(){
            this.$store.dispatch('setAmplifyDataForAccount', {data : false, account : this.account});
            this.$api.delete(`/accounts/${this.account.id}/amplify-settings`);
        },
        setAccountToLoadAfterAuthorization(){
            this.$store.dispatch('setAccountToLoadAfterHootsuiteAuthorization', {account : this.team});
            console.log(this.amplifyConnectUrl)
            window.location = this.amplifyConnectUrl
        }
    }
}
</script>

<style>
</style>
