
<script>
import {mapGetters} from "vuex";
import {OAuthConnection} from "@/services/OAuth";

export default {
    name: "MailchimpBase",
    computed: {
        ...mapGetters({
            account: 'currentAccount',
            apis: 'externalApis',
            features: 'features',
        }),
        mailchimpAccountInfo() {
            return this.account && this.apis && this.apis.mailchimp &&
                this.apis.mailchimp[this.account.id]
        },
        dc() {
            return this.mailchimpAccountInfo && this.mailchimpAccountInfo.dc
        },
        mailchimpAccountData() {
            return this.apis && this.team && this.team.id && this.apis['mailchimp'] &&
                this.apis['mailchimp'][this.team.id];
        },
        service() {
            return new OAuthConnection('mailchimp', this)
        },
        authorized() {
            return !!this.service && !!this.service.isConnected(this.team) && this.feature.activated
        },
        feature() {
            return this.mailchimpAccountData
                && this.mailchimpAccountData.collection_features
                && this.mailchimpAccountData.collection_features.find(f => f.name.includes('email'))
        },
        ready() {
            return this.service
        },
    }
}
</script>

<style scoped>

</style>
