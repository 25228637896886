<script>
import { mapGetters } from 'vuex';
import {extensionHomeRoute} from "@/services/BrowserExtension";
import {isBrowserExtension} from "@/services/Context";
// import {powerups} from "@/Constants";

export default {
    computed : {
        ...mapGetters({
            account : 'currentAccount',
            user : 'currentUser',
            overviews : 'accountOverviews',
            topic : 'currentTopic',
            topics : 'topics'
        }),
        overview(){
            if (this.account){
                return this.overviews[this.account.id];
            }
        },
        accountTopics(){
            if (this.account)
                return this.topics.filter(t => t.account.includes(this.account.id))

            return []
        }
    },
    watch : {
        user(){
            if (this.user)
                this.fetchAccounts()
        },
        account(){
            if (this.account)
                this.fetchAccountMap(this.account.id);
        },

    },
    mounted(){
        if (this.account){
            this.fetchAccountMap(this.account.id)
        }
    },
    data() { return { integrations: null }},
    methods : {
        async fetchAccounts(){
            return this.$api.get('/accounts').then(async response => {
                let accountUrls = response.data;
                let accounts = await Promise.all(accountUrls.map(this.fetchAccount));
                return accounts
            })
        },
        async fetchAccount(url){
            return this.$api.get(url).then(async response => {
                let account = response.data;
                await this.$store.dispatch('addAccount', {account});

                let userDefault = this.user && this.user.default_account;
                let deviceDefault = (JSON.parse(localStorage.getItem('defaultAccounts')||'{}')||{})[this.user&&this.user.id];
                let defaultAccount = deviceDefault || userDefault;
                let accountToLoad = this.$route.query.account || defaultAccount;
                if (accountToLoad && accountToLoad.includes(account.id)) {
                    if (!this.account){
                        await this.$store.dispatch('setAccount', {account: account});
                    }
                }

                // this.integrations.then(integrations => [
                //     ...integrations.map(i => i.name),
                //     ...powerups
                // ].forEach(powerup => this.loadAccountPowerupSettings(powerup, account)));
            })
        },
        fetchAccountMap(accountId){
            this.$store.dispatch('beginDownloadingAccount', {url : `/accounts/${accountId}`});
            this.$api.get(`/accounts/${accountId}/overview`).then(response => {
                this.$store.dispatch('addAccountOverview', {overview: response.data});
                this.storeOverview(response.data);
            })
        },
        storeOverview(overview){



            if ( ! overview){
                this.$store.dispatch('completeDownloadingAccount', {account : this.account});
                return;
            }

            this.$store.dispatch('addTopics', {topics : overview.topics.map(o => {
                return {...o, account : this.account.id, incomplete: true}
            })})

            if ( (! this.topic || ! this.accountTopics.map(t => t.id).includes(this.topic.id)) && this.accountTopics.length){
                let topic = this.accountTopics[0];
                this.$store.dispatch('setCurrentTopic', {topic})
                let route = isBrowserExtension() ? extensionHomeRoute() : "/";
                this.$router.push(route);
            }

            this.$store.dispatch('addCollections', {collections : overview.collections.map(o => {
                return {...o, account : this.account.id, incomplete: true}
            })})
            this.$store.dispatch('addFolders', {folders : overview.folders.map(o => {
                return {...o, account : this.account.id, incomplete: true}
            })})

            overview.users.forEach( u => {
                let user = {...u, account: this.account.id, incomplete: true}
                this.$store.dispatch('addUser', {user});
                this.$store.dispatch('addTeamMemberToAccount', {user, account: this.account});
            });

            this.$store.dispatch('completeDownloadingAccount', {account : this.account});

        }
    },

    // async fetchGlobals() {
    //     this.integrations = this.fetchIntegrations();
    //     return { integrations: this.integrations }
    // },
    // async fetchIntegrations() {
    //     let resp = await this.$api.get('/integrations?meta=true');
    //     let integrations = resp.data;
    //     await this.$store.dispatch(apiActions.setIntegrationMetaAll.name, { integrations });
    //     return integrations;
    // },
    // loadAccountPowerupSettings(powerup, account) {
    //     this.$api.get(`/accounts/${account.id}/${powerup}-settings`).then(response => {
    //         let data = response && response.data;
    //         let token =  data && data.access_token;
    //         this.$store.dispatch('setPowerupDataForAccount', {data : token ? {token, ...data} : false, account, powerup});
    //     }).catch( () => {
    //         //No Settings Found
    //     })
    // },
    render(h){
        return h();
    }
}
</script>

<style>
</style>
