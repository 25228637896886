<template xmlns="http://www.w3.org/1999/html">
    <form>
        <fieldset>
            <label class="label-calm d-flex">
                Destination
            </label>
            <uc-select
                :items="destinationOptions"
                :placeholder="'Select a destination...'"
                v-model="destination"
            >
            </uc-select>

            <div v-if="features.openaiIntegration">
                <label class="label-calm d-flex mt-2">
                    Voice
                </label>
                <!-- NOTE: In the UI we are using 'voice' but in the backend we are using 'agents' synonymously -->
                <uc-select
                    :items="agentOptions"
                    :placeholder="'Select a voice...'"
                    v-model="agent"
                >
                </uc-select>
                <div class="form-text">
                    To add custom voice options, visit the AI Post Text Generation page
                </div>
            </div>
        </fieldset>
    </form>
</template>

<script>
import {UcSelect} from "@/components/Controls";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CollectionPostTextSettingsEditor",
    components: {UcSelect},
    props: {
        postTextSettings: {type: Object},
    },
    data() {
        return {
            destination: this.postTextSettings && this.postTextSettings.destination || null,
            agent: this.postTextSettings && this.postTextSettings.agent || null,
        }
    },
    computed: {
        ...mapGetters({
            currentAccount: 'currentAccount',
            features: 'accountFeatures',
            currentPostTextAgents: 'currentPostTextAgents',
        }),
        destinationOptions() {
            return [
                {
                    value: 'summary',
                    description: 'Summary'
                },
                {
                    value: 'newsletter',
                    description: 'Newsletter'
                },
                {
                    value: 'facebook',
                    description: 'Facebook'
                },
                {
                    value: 'linkedin',
                    description: 'LinkedIn'
                },
                {
                    value: 'twitter',
                    description: 'Twitter'
                },
                {
                    value: 'sales email',
                    description: 'Sales Email'
                },
                {
                    value: 'job listing',
                    description: 'Job Listing'
                },
            ];
        },
        agentOptions() {
            if (this.currentPostTextAgents && this.currentPostTextAgents.length === 0) return;
            const displayAgentsOptions = this.currentPostTextAgents.map(i => (
                {
                    value: i.agent_prompt_text,
                    description: i.name
                }
            ));
            displayAgentsOptions.push(
                {
                    value: null,
                    description: 'No custom agent voice'
                }
            )
            return displayAgentsOptions;
        },
    },
    methods: {
        ...mapActions({
            loadPostTextAgents: 'loadFilteredPostTextAgents'
        }),
    },
    watch: {
        destination() {this.$emit('fieldChange', {destination: this.destination})},
        agent() {this.$emit('fieldChange', {agent: this.agent})},
        postTextSettings() {
            this.destination = this.postTextSettings.destination;
            this.agent = this.postTextSettings.agent;
        },
        currentAccount() {
            this.loadPostTextAgents(this.currentAccount.id);
        },
    },
    mounted() {
        this.loadPostTextAgents(this.currentAccount.id);
    }
}
</script>

<style scoped>

</style>
