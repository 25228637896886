export default {
    install: (Vue, { store }) => {
        Vue.prototype.$plan = {
            plan() {
                if (store && store.state && store.state.accounts && store.state.accounts.currentAccount) {
                    return store.state.accounts.currentAccount.plan
                }
            },
            isNonFree() {
               return this.plan();
            }
        }
    }
}