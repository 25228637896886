<template>
    <div class="dropdown position-static-xs">
        <a class="nav-link dropdown-toggle"
           href="#"
           id="navbarDropdown"
           role="button"
           data-bs-toggle="dropdown"
           aria-haspopup="true"
           aria-expanded="false">
                <span class="text-truncate account-name">
                    <span v-if="currentAccount">
                        {{ currentAccount.name }}
                    </span>
                    <span v-else>Loading Accounts</span>
                </span>
        </a>
        <div class="dropdown-menu wide-advanced-menu advanced-menu dropdown-menu-end"
             aria-labelledby="navbarDropdown">
            <div class="d-flex-weak flex-wrap">
                <div class="accounts-column order-3 order-md-1">
                    <div class="dropdown-item" @click.prevent.stop>
                        <div class="global-nav-title"><i class="fa fa-user"></i> Accounts</div>
                        <small class="text-muted">Choose an account to view topics and suggestions</small>
                    </div>
                    <div class="accounts-list">
                        <account-link
                            class="d-flex global-nav-account-link"
                            v-for="account in accounts"
                            :key="account.id"
                            :account="account"
                            :is-loading="loadingAccounts.indexOf(account) > -1"
                            @setAccount="setAccount"
                        ></account-link>
                    </div>
                </div>

                <div class="dropdown-divider order-2 v-line-800"></div>

                <div class="order-1  order-md-3">

                    <router-link class="dropdown-item" to="/topics" id="global-nav-topics">
                        <div class="global-nav-title"><topics-svg class="global-nav-svg multiple top-1 em-1"/> Topics</div>
                        <small class="text-muted">Edit and manage your folders and topics</small>
                    </router-link>
                    <router-link class="dropdown-item" to="/collections" id="global-nav-collections">
                        <div class="global-nav-title"><collections-svg class="global-nav-svg multiple top-1 em-1"/> Collections</div>
                        <small class="text-muted">Edit and manage your folders and collections</small>
                    </router-link>
                    <router-link class="dropdown-item" to="/filter-view-manage" id="global-nav-filter">
                        <div class="global-nav-title"><i class="fa fa-filter"></i> Filters</div>
                        <small class="text-muted">Edit and manage filters used on collections &amp; topics</small>
                    </router-link>
                    <router-link v-if="isNonFreeAccount" class="dropdown-item" to="/team-members" id="global-nav-team">
                        <div class="global-nav-title"><i class="fa fa-users"></i> Team Members</div>
                        <small class="text-muted">Invite new members, view current members, and change
                            permissions</small>
                    </router-link>
                    <router-link class="dropdown-item"
                                 to="/galleries"
                                 v-if="features.galleryBuilder && !hideGalleries"
                                 id="global-nav-galleries">
                        <div class="global-nav-title"><i class="fa fa-globe"></i> Galleries</div>
                        <small class="text-muted">Edit and manage your website galleries</small>
                    </router-link>
                    <router-link class="dropdown-item" to="/content-templates"
                                 v-if="accountFeatures.contentTemplates && !hideContentTemplates" id="global-nav-content">
                        <div class="global-nav-title"><i class="fa fa fa-envelope"></i> Email Templates</div>
                        <small class="text-muted">Edit and manage your email templates</small>
                    </router-link>
                    <router-link class="dropdown-item"
                                 to="/settings#integrations"
                                 v-if="currentAccount.plan && !hideIntegrations"
                                 id="global-nav-integrations">
                        <div class="global-nav-title"><i class="fa fa-bolt"></i> Integrations</div>
                        <small class="text-muted">Connect to third party applications</small>
                    </router-link>
                    <router-link class="dropdown-item" :to="{name:'Settings'}" id="global-nav-settings">
                        <div class="global-nav-title"><i class="fa fa-cog"></i> Settings</div>
                        <small class="text-muted">Change your subscription plan, payment method, and create new
                            accounts</small>
                    </router-link>
                    <router-link class="dropdown-item" to="/adminxupc" v-if="isUpContentPowerUser"
                                 id="global-nav-admin">
                        <div class="global-nav-title"><i class="fa fa fa-wrench"></i> Admin Panel</div>
                        <small class="text-muted">Stuff</small>
                    </router-link>


                </div>
                <div class="dropdown-divider order-4 v-line-800"></div>
                <div class="order-5" style="width: 100%">
                    <router-link class="dropdown-item" :to="{name : 'Logout'}" id="global-nav-logout">Log
                        out
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
// Do not scope.
.global-nav-svg {

}
</style>
<style lang="scss" scoped>
// Must be scoped.
.dropdown-menu {
    // Needs to be over 1000 to overlap sidebar.
    z-index: 1010;
}

@media screen and (min-width: 768px) and (min-height: 500px) {
    .accounts-list {
        overflow-y: auto;
        max-height: calc(100vh - 230px);
    }
}

.account-name {
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
}


.feature-tag {
    vertical-align: super;
    font-size: .6em;
    text-transform: uppercase;
    color: var(--bs-blue);
    max-width: 0;
    display: inline-block;
    overflow-x: visible;
}
</style>

<script>
import {Dropdown} from 'bootstrap';
import {mapGetters} from 'vuex';
import AccountLink from './AccountMenuItemLink'
import TopicsSvg from "@/assets/svg/topics";
import CollectionsSvg from "@/assets/svg/collections";

export default {
    components: {
        AccountLink, TopicsSvg, CollectionsSvg,
    },
    computed: {
        ...mapGetters({
            features: 'features',
            accountFeatures: 'accountFeatures',
            currentAccount: 'currentAccount',
            subscriptions: 'subscriptions',
            accounts: 'allAccounts',
            loadingAccounts: 'accountsBeingLoaded',
            folders: 'folders',
            topics: 'topics',
            subscriptionPlan: 'subscriptionPlan',
            isUpContentPowerUser: 'isUpContentPowerUser',
            hideGalleries: 'hideGalleries',
            hideIntegrations: 'hideIntegrations',
            hideContentTemplates: 'hideContentTemplates'

        }),
        isNonFreeAccount() {
            return this.subscriptionPlan;
        },
        currentSubscription() {
            return this.subscriptions.find(s => s.account_id == this.currentAccount.id);
        },
    },
    mounted() {
        this.dropDown = new Dropdown(this.$el);
    },
    unmounted() {
        this.dropDown.dispose();
    },
    methods: {
        setAccount({account}) {
            this.$store.dispatch('setAccount', {account})
        },
    }
}
</script>
