<script>
import { mapGetters } from 'vuex';
export default {
    props : ['id'],
    computed : {
        ...mapGetters({
            accounts : 'allAccounts'
        })
    },
    mounted() {
        this.setAccount();
    },
    methods : {
        setAccount(){
            let account = this.accounts.find(a => a.id == this.id);
            if (account){
                this.$store.dispatch('setCurrentAccount', {account});
                this.$router.push("/")
            }
        }
    },
    render(h){
        return h();
    }
}
</script>

<style>
</style>
