<script>

import {mapGetters} from 'vuex';

export default {
    computed : {
        ...mapGetters(['appVersion'])
    },
    watch  : {
        appVersion(incoming, previous){
            if (previous && incoming.number > previous.number && incoming.requiresReload){
                this.$store.dispatch('logoutUser');
                this.$store.dispatch('setLoginMessage', { message: "Your session has expired. Please log in again." });
                this.$router.push("/login");
            }
        }
    },
    mounted() {
        this.$store.dispatch('setAppVersion', { number: 2, requiresReload : true });
    },
    render(h){
        return h();
    }
}
</script>