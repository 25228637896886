<script setup>
import {computed, defineProps, ref} from "vue";
    import store from "@/store";
import {appApi} from "@/init/ApiInit";
import Spinner from "@/components/Controls/Spinner.vue";
    const scheme = process.env.VUE_APP_API_SCHEME || 'https';
    const base = process.env.VUE_APP_API_BASE_URL;
    const props = defineProps({extension: {type: String, default: 'yaml'}});
    const account = computed(() => store.getters.account);
    const url = computed(() => `${scheme}://${base}/accounts/${account.value.id}/export.${props.extension}`);
    const data = ref(null)
    const loading = ref(false);
    const fetchExport = async () => {
        loading.value = true;
        const resp = await appApi.get(url.value);
        data.value = resp.data;
        loading.value = false;
    }
    const copy = () => {
        window.Events.$emit('clipboardCopy', data)
    }
</script>

<template>
    <div>
        <button class="btn btn-outline-primary" v-if="data" type="button" @click="copy">
            <i class="fa fa-download"/> Copy Export File
        </button>
        <button type="button" class="btn btn-outline-primary" @click="fetchExport" v-else>
            <i class="fa fa-download"/> Create Export File <spinner v-if="loading"/>
        </button>
    </div>
</template>

<style scoped>

</style>
