<template>
    <div class="welcome-screen">
        <div class="welcome-hero">
            <div class="gradient"></div>
            <div class="text-container">
                <h1>Welcome to {{appName}}! </h1>
                <p>Get started by creating a topic or by exploring some of the suggested topics below</p>

                <button class="btn btn-primary btn-lg shadow-none" @click="showQueryBuilder">Create a topic</button>
                <p class="small">
                    <a  
                        :href="learnUrls.howUpContentWorks" v-if="learnUrls.howUpContentWorks"
                        target="_blank">Learn how to create an effective topic
                    </a>
                </p>
            </div>
        </div>

        <div class="container">
            <h1 class="mb-2">Explore Topics</h1>
            <default-topics />
        </div>

        

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopicBuilder from '@/components/QueryBuilder/QueryPotter'
import DefaultTopics from '@/components/Topics/DefaultTopics'

export default {
    props : [],
    computed : {
        ...mapGetters({
            topic : 'currentTopic',
            folders : 'folders',
            account : 'currentAccount',
            learnUrls: 'learnUrls',
            appName: 'appName',
            signupPlan : 'signupPlan'            
        }),

    },
    watch : {
        topic(){
            this.$router.push("/")
        }
    },
    components : {
        TopicBuilder, DefaultTopics
    },
    data(){
        return {
            topics : [],
            networkActivity : false,
            networkError : false
        }
    },
    mounted(){
        if (this.signupPlan){
            this.$router.push('/settings#subscription');
        }
    },
    methods : {
        
        showQueryBuilder(){
            window.Events.$emit('showQueryBuilderModal', {topic : false });
        },

    }
}
</script>

<style scoped>


.welcome-hero {
    text-align: center;
    padding: 130px;
    background-image: url('/src/assets/images/welcome/cover.jpg');
    background-size: cover;
    background-position: center;

    color: white;
    overflow: hidden;
    position: relative;

    margin-bottom: 2em;
    
}

.welcome-hero .btn {
    margin: 50px auto;
    display: block;
}

@media screen and (max-width: 500px) {
    .welcome-hero {
        text-align: left;
        padding: 50px;
    }

    .welcome-hero .btn {
        margin: 10px 0;
        display: block;
    }
}


.welcome-hero a {
    color: #ffffff !important;
}

.welcome-hero .text-container {
    position: relative;
    z-index: 2;
}

.gradient {
    content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(to bottom right,#11547a,rgb(21, 45, 78));
    opacity: .6; 
    height: 100%;
    width: 100%;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    filter: brightness(100%);
  }

  .welcome-topic:hover {
      transform: translate(-2px, -2px);
  }

  .welcome-topic:hover .gradient {
    opacity: 0.6;      
    filter: brightness(70%);
    
  }

  .welcome-topic:hover h5 {
      transform: translate(2px, 2px);
      }

.welcome-topics-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(215px, 1fr));
    grid-gap: 30px;
    margin-bottom: 80px;
    cursor: pointer;
}

.welcome-topic {
    background-position: center;
    background-size: cover;
    height: 180px;
    display : flex;
    color: white;
    align-items: center;
    position: relative;
    transition: all 0.2s ease-in-out;
    letter-spacing: 0.1em; 
}

.welcome-topic h5 {
    text-align: center; 
    flex: 1; 
    position: relative;
    z-index: 2; 
    transition: all 0.2s ease-in-out;     
    padding: 10px 0; 
}

</style>
