<script setup> // FIXME: add ts
import * as toastr from "toastr";
import store from '@/store'
import {ref, computed, watch, onMounted} from "vue";
import ClassifierRules from "@/components/Settings/Team/ClassifierRules.vue";
import NewClassifierRule from "@/components/Settings/Team/NewClassifierRule.vue";
import {apis, appApi} from "@/init/ApiInit";
import Spinner from "@/components/Controls/Spinner.vue";
import InputTypeFile from "@/utilities/InputTypeFile.vue";

const props = defineProps({
    savedClassifier: { type: Object, default: () => ({}) },
    addingNewClassifier: Boolean
});

const name = ref(props.savedClassifier?.name || '');
const ruleCombinationOperation = ref(props.savedClassifier?.ruleCombinationOperator || 'any');
const currentRules = ref( []);
const isAddingRule = ref(false);
const isSavingClassifiersList = ref(false);
const isLoadingSavedRules = ref(false);
const hasRuleEdits = ref(false);
const deleteRules = ref([]);
const file = ref(null);
const preview = ref(null);
const isShowDeleteButton = ref(false);
const isValidatingDelete = ref(false);
const validationError = ref('');
const subscriptionLimitForRules = ref(null);
const remainingRulesOnClassifierLoad = ref(null);
const showOutOfRulesMessage = ref(false);


const learnUrls = computed(() => store.getters.learnUrls);
const isAddingNewClassifier = computed(() => props.addingNewClassifier);
const classifierHasEdits = computed(() => {
    name.value !== props.savedClassifier?.name
    || ruleCombinationOperation.value !== props.savedClassifier?.ruleCombinationOperator
    || hasRuleEdits.value
    || deleteRules.value
});
const account = computed(() => store.getters.currentAccount);
console.log('* account', account)


const currentClassifierRulesFromStore = (id) => store.dispatch('loadRulesForClassifier', id);

const emits = defineEmits([
    'updateClassifierList',
]);

watch(()=> store.getters.account, async () => {
    await fetchSubscriptionData();
    showOutOfRulesMessage.value = false;
}, {immediate: true});

watch( () => props.savedClassifier, async() => {
    if(!props.addingNewClassifier) {
        isLoadingSavedRules.value = true;
        name.value = props.savedClassifier?.name
        ruleCombinationOperation.value = props.savedClassifier?.ruleCombinationOperator;
        currentRules.value = await currentClassifierRulesFromStore(props.savedClassifier?.id);
        isLoadingSavedRules.value = false;
        deleteRules.value = [];
        isShowDeleteButton.value = true;
        validationError.value = '';
        await fetchSubscriptionData();
        showOutOfRulesMessage.value = false;
        preview.value = null;
        file.value = null;
    }
}, {immediate: true}); // immediate: true option to pickup savedRules when component first mounts (first click)

watch(() => props.addingNewClassifier, async() => {
    if(props.addingNewClassifier) {
        name.value = '';
        ruleCombinationOperation.value = 'any';
        currentRules.value = [];
        isShowDeleteButton.value = false;
        validationError.value = '';
        await fetchSubscriptionData();
        showOutOfRulesMessage.value = false;
    }
});

async function fetchSubscriptionData() {
    const resp = await window.$app.api.get(`/accounts/${store.getters.account.id}/subscription/usage`);
    const rules = resp.data.resources.filter(i => i.name === 'contentPolicyRules');
    subscriptionLimitForRules.value = rules;
    remainingRulesOnClassifierLoad.value = rules[0].remaining;
    return rules;
}


const createNewRule = classifierId => rule => {
    return window.$app.api.post(`/content-classifiers/${classifierId}/rules`, {
        operation: rule.operation,
        field: rule.field,
        value: rule.ruleValue,
    });
};

function saveChangesToExistingRule(rule) {
    return window.$app.api.put(`/classifier-rules/${rule.id}`, {
        operation: rule.operation,
        field: rule.field,
        value: rule.ruleValue,
    });
}

const saveRuleForExistingClassifier = async rule => {
    if (rule.id)
        return await saveChangesToExistingRule(rule);
    else
        return await createNewRule(props.savedClassifier?.id)(rule)
};

const saveDeleteRuleForExistingClassifier = async rule => {
    return window.$app.api.delete(`/classifier-rules/${rule.id}`)
}

async function postAllClassifierData() {
    isSavingClassifiersList.value = true;
    const classifierResp = await apis.contentPolicy.accountsAccountIdContentClassifiersPost(store.getters.account.id, {
        name: name.value,
        rule_combination_operation: ruleCombinationOperation.value
    });
    let classifierId = classifierResp.data.id;
    await Promise.all(currentRules.value.map(createNewRule(classifierId)));
    hasRuleEdits.value = false;
    emits('updateClassifierList');
    isSavingClassifiersList.value = false;
}

async function putClassifier() {
    await apis.contentPolicy.contentClassifiersClassifierIdPut(props.savedClassifier?.id, {
        name: name.value,
        rule_combination_operation: ruleCombinationOperation.value,
    });
}

async function putAllClassifierData() {
    // PUT existing classifier
    isSavingClassifiersList.value = true;
    if (name.value !== props.savedClassifier?.name
        || ruleCombinationOperation.value !== props.savedClassifier?.ruleCombinationOperator) {
        await putClassifier();
    }
    if(deleteRules.value.length !== 0) {
        await Promise.all(deleteRules.value.map(rule => {
            saveDeleteRuleForExistingClassifier(rule);
        }));
    }
    if (hasRuleEdits.value) {
        await Promise.all(currentRules.value.map(rule => {
            saveRuleForExistingClassifier(rule);
        }));
    }
    hasRuleEdits.value = false;
    emits('updateClassifierList')
    isSavingClassifiersList.value = false;
}

const save = async () => {
    const classifierHasEdits = name.value !== props.savedClassifier?.name
        || ruleCombinationOperation.value !== props.savedClassifier?.ruleCombinationOperator
        || hasRuleEdits.value
        || deleteRules.value
    if(!props.addingNewClassifier && classifierHasEdits) {
        await putAllClassifierData();
    }
    // POST new classifier
    if(props.addingNewClassifier) {
        await postAllClassifierData();
    }
    toastr.success('Classifier Saved');
};

const handleRemoveRule = (rule, index) => {
    remainingRulesOnClassifierLoad.value++;
    if(remainingRulesOnClassifierLoad.value > 0) {
        showOutOfRulesMessage.value = false;
    }
    if (rule.id) {
        deleteRules.value.push(rule)
    }
    // Create a new array excluding the deleted row
    currentRules.value = currentRules.value.filter((_, i) => i !== index);
};

const handleAddRule = async (newRule) => {
    if(remainingRulesOnClassifierLoad.value <= 0) {
        showOutOfRulesMessage.value = true;
        return;
    }
    remainingRulesOnClassifierLoad.value--;
    hasRuleEdits.value = true;
    currentRules.value = [newRule, ...currentRules.value];
};
const handleRuleChange = (rule) => {
    hasRuleEdits.value = true;
    const index = currentRules.value.findIndex(r => r.localId === rule.localId && r.id === rule.id);
    const updatedRulesList = [...currentRules.value];
    updatedRulesList[index] = rule;
    currentRules.value = updatedRulesList;
};

const deleteClassifier = async(classifierId) => {
    alert('Are you sure you want to delete this classifier?');
    isValidatingDelete.value = true;
    try {
        await apis.contentPolicy.contentClassifiersClassifierIdDelete(classifierId);
    } catch(e) {
        if(e.response.status === 409) {
            const policiesWithClassifier = e.response.data.affiliated_policies.map(p => `${p}`).join(', ');
            validationError.value = 'Cannot delete classifier without removing it from the following policies: '
                + policiesWithClassifier;
            isValidatingDelete.value = false;
            return;
        }
    }
    emits('updateClassifierList');
    isValidatingDelete.value = false;
};

const submit = async () => {
    file.value = event.target.files[0];
    preview.value = await file.value.text();
    if(!file.value) return;
    const rows = preview.value.toString().split(/\r?\n/);
    await fetchSubscriptionData();
    if(remainingRulesOnClassifierLoad.value < rows.length) {
        showOutOfRulesMessage.value = true;
        preview.value = null;
        file.value = null;
        return;
    }

    const url = `/content-classifiers/${props.savedClassifier?.id}/rules/import`;
    const headers = {"Content-Type": file.value.type}
    const resp = await appApi.post(url, preview.value, {headers});
    if (resp.status >= 200 && resp.status < 300) {
        toastr.success("Bulk rules uploaded! Revisit classifier form to see uploaded rules")
    } else {
        toastr.error(`Failed! ${JSON.stringify(resp.data)}`)
    }
};
</script>

<template>
    <div>
        <form @submit.prevent.stop="save" class="mb-3 form-horizontal form">
            <div>
                <div class="row">
                    <label class="label-calm mb-4 col col-12 col-lg-6">
                        Classifier Name &nbsp;
                        <input
                            required
                            type="text"
                            v-model="name"
                            placeholder="Enter classifier name"
                            class="form-control form-control-sm d-block"
                        />
                    </label>
                    <fieldset class="d-block col col-12 col-lg-6">
                        <legend class="label-calm d-block">Fail if</legend>
                        <div>
                            <label class="d-inline form-check-label me-2">
                                <input
                                    type="radio"
                                    class="form-check-input"
                                    v-model="ruleCombinationOperation"
                                    value="any"
                                    name="classifierRuleCombinationOperationAny"
                                >
                                Any Match
                            </label>
                            <label class="d-inline form-check-label me-2">
                                <input
                                    type="radio"
                                    class="form-check-input"
                                    v-model="ruleCombinationOperation"
                                    value="all"
                                    name="classifierRuleCombinationOperationAll"
                                >
                                All Match
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div v-if="!isAddingRule">
                    <button class="full-width btn btn-primary" type="button" @click="isAddingRule=true">
                        Add Rules
                    </button>
                    <div v-if="!isAddingNewClassifier">
                        <form @submit.prevent="submit">
                            <a :href="learnUrls.contentPolicies"
                               v-if="learnUrls.contentPolicies"
                               target="_blank"
                               class="learn-more">Learn more about bulk uploads
                            </a>
                            <InputTypeFile
                                accept="text/csv"
                                name="importFile"
                                file-type-label="CSV File"
                                @changed="submit"
                                style="background-color: var(--secondary)!important;"
                            />
<!--                                ref="resetCSVUploader"-->
<!--                                @click="handleResetCSVUploader"-->

                        </form>
                    </div>
                </div>
                <div class="d-flex flex-wrap classifier-rules" style="gap: 15px">
                    <div v-show="isAddingRule">
                        <NewClassifierRule @addRule="handleAddRule"/>
                        <form v-if="!isAddingNewClassifier" @submit.prevent="submit">
                            <a :href="learnUrls.contentPolicies"
                               v-if="learnUrls.contentPolicies"
                               target="_blank"
                               class="learn-more">Learn more about bulk uploads
                            </a>
                            <InputTypeFile
                                accept="text/csv"
                                name="importFile"
                                file-type-label="CSV File"
                                @changed="submit"
                                style="background-color: var(--secondary)!important;"
                            />
                        </form>
                    </div>
                    <div v-if="account.role === 'administrator' && showOutOfRulesMessage" class="meter-warning alert alert-warning mt-2">
                        You need to <a href="/settings#subscription" target="_blank"><b>upgrade your subscription plan</b></a> to add any more rules
                    </div>
                    <div v-if="account.role !== 'administrator' && showOutOfRulesMessage" class="meter-warning alert alert-warning mt-2">
                        Let your admin know you need to update your subscription plan to add any more rules
                    </div>
                    <div class="flex-grow" style="min-width: 350px">
                        <div v-if="isLoadingSavedRules && !isAddingNewClassifier">
                            <Spinner/>
                        </div>
                        <ClassifierRules
                            v-else
                            :rulesList="currentRules"
                            @ruleChange="handleRuleChange"
                            @removeRule="handleRemoveRule"
                        />
                    </div>
                </div>

                <div class="form-footer">
                    <button type="submit" class="btn btn-primary pull-right">Save</button>
                    <button v-if="isShowDeleteButton" type="button" class="btn btn-danger pull-right me-2" @click="deleteClassifier(savedClassifier.id)">Delete</button>
                    <spinner v-if="isValidatingDelete"></spinner>
                    <br>
                    <br>
                    <p v-if="validationError" class="alert alert-danger">{{validationError}}</p>
                </div>
            </div>
        </form>

    </div>
</template>

<style lang="scss">
label textarea {
    display: inline-block;
}
.meter-warning {
    height: 60px;
}
@media (max-width: 1332px) {
    .meter-warning {
        height: 80px;
    }
}

</style>
