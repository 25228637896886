<script>

    import {mapGetters} from 'vuex';
    // import {limitRequests} from "@/utilities/requests";

    export default {
    computed : {
        ...mapGetters({
            'topics' : 'topics',
            'collections' : 'collections',
            'folders' : 'folders',
            currentTopic : 'currentTopic'
        })
    },
    watch : {
        currentTopic(){
            if (this.currentTopic && this.currentTopic.incomplete){
                this.$api.limit.get(`/topics/${this.currentTopic.id}`).then(response => {
                    let topic = response.data;
                    this.$store.dispatch('addTopic', {topic});
                })
                .catch( () => {

                })
            }
        },
        // topics(){
        //     let topicsToDownload = this.topics.filter(local => local.incomplete );
        //     topicsToDownload.forEach(partial => {
        //         this.$api.get(`/topics/${partial.id}`).then(response => {
        //             let topic = response.data;
        //             this.$store.dispatch('addTopic', {topic});
        //         })
        //         .catch( () => {

        //         })
        //     })
        // },
        collections(){
            let collectionsToDownload = this.collections.filter(local => local.incomplete );
            collectionsToDownload.forEach(partial => {
                this.$api.limit.get(`/collections/${partial.id}`).then(response => {
                    let collection = response.data;
                    this.$store.dispatch('addCollection', {collection});
                })
                .catch( () => {

                })
            })
        },
        // folders(){
        //     let foldersToDownload = this.folders.filter(local => local.incomplete );
        //     foldersToDownload.forEach(partial => {
        //         this.$api.get(`/folders/${partial.id}`).then(response => {
        //             let folder = response.data;
        //             this.$store.dispatch('addFolder', {folder});
        //         })
        //         .catch( () => {

        //         })
        //     })
        // }
    },
    methods : {

    },
    render(h){
        return h();
    }
}
</script>
