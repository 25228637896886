<script setup> // FIXME: add ts
import * as toastr from "toastr";
import store from '@/store'
import {ref, computed, watch, onMounted} from "vue";
import {apis} from "@/init/ApiInit";
import Spinner from "@/components/Controls/Spinner.vue";


const props = defineProps({
    savedPolicy: { type: Object, default: () => ({}) },
    isAddingNewPolicy: Boolean,
    hasClassifierUpdates: Boolean,
})

const name = ref(props.savedPolicy?.name || '');
const classifierIds = ref(props.savedPolicy?.classifiers || []);
const classifiersListForAccount = ref([]);
const isLoadingClassifierList = ref(false);
const isSavingPoliciesList = ref(false);
const hasClassifierChangesForPolicy = ref(false);
const isShowDeleteButton = ref(true);
const isValidatingDelete = ref(false);
const validationError = ref('');

const noClassifiersChecked = computed(() => classifierIds.value.length === 0)

const emits = defineEmits([
    'updatePoliciesList',
    'resetHasClassifierUpdates'
]);

watch(() => props.savedPolicy, () => {
    if(props.savedPolicy) {
        name.value = props.savedPolicy?.name
        classifierIds.value = [...props.savedPolicy?.classifiers];
        isShowDeleteButton.value = true;
        validationError.value = '';
    }
});

watch(() => props.isAddingNewPolicy, () => {
    if(props.isAddingNewPolicy) {
        name.value = '';
        classifierIds.value = [];
        isShowDeleteButton.value = false;
        validationError.value = '';
    }
}, {immediate: true});

watch( () => props.hasClassifierUpdates, async() => {
    if(props.hasClassifierUpdates) {
        await loadClassifiersForAccount();
        emits('resetHasClassifierUpdates');
    }
}, {immediate: true});

const account = computed(() => store.getters.account);
const loadClassifiersFromStore = () => store.dispatch('loadAccountClassifiers');

const loadClassifiersForAccount = async() => {
    isLoadingClassifierList.value = true;
    classifiersListForAccount.value = await loadClassifiersFromStore();
    isLoadingClassifierList.value = false;
};

const save = async () => {
    const policyHasEdits = name.value !== props.savedPolicy?.name || hasClassifierChangesForPolicy.value
    // PUT existing policy
    if(!props.isAddingNewPolicy && policyHasEdits) {
        isSavingPoliciesList.value = true;
        const resp = await apis.contentPolicy.contentPoliciesPolicyIdPut(props.savedPolicy?.id, {
            name: name.value,
            classifiers: [...classifierIds.value],
        });
        emits('updatePoliciesList');
        isSavingPoliciesList.value = false;
        hasClassifierChangesForPolicy.value = false;
    }
    // POST new policy
    if(props.isAddingNewPolicy) {
        isSavingPoliciesList.value = true;
        const resp = await apis.contentPolicy.accountsAccountIdContentPoliciesPost(store.getters.account.id, {
            name: name.value,
            classifiers: [...classifierIds.value],
        });
        emits('updatePoliciesList');
        isSavingPoliciesList.value = false;
        hasClassifierChangesForPolicy.value = false;
    }
    toastr.success('Content Policy Saved');
};

const classifierChecked = (classifierId) => {
    hasClassifierChangesForPolicy.value = true;
    if(classifierIds.value.includes(classifierId)) {
        classifierIds.value = classifierIds.value.filter(id => id !== classifierId)
    }
    else {
        classifierIds.value = [...classifierIds.value, classifierId];
    }
};

const deletePolicy = async(policyId) => {
    alert('Are you sure you want to delete this policy?');
    isValidatingDelete.value = true;
    try {
        await apis.contentPolicy.contentPoliciesPolicyIdDelete(policyId);
    } catch(e) {
        if(e.response.status === 409) {
            const collectionsWithPolicy = e.response.data.affiliated_collections.map(p => `${p}`).join(', ');
            validationError.value = 'Cannot delete policy without removing it from the following collections: '
                + collectionsWithPolicy;
            isValidatingDelete.value = false;
            return;
        }
    }
    emits('updatePoliciesList');
    isValidatingDelete.value = false;
};

onMounted(() => {
    loadClassifiersForAccount();
})
</script>

<template>
    <div>
        <form @submit.prevent.stop="save" class="mb-3 form-horizontal form">
            <div class="row">
                <label class="label-calm mb-4 col col-12 col-lg-6">
                    Policy Name &nbsp;
                    <input
                        required
                        type="text"
                        v-model="name"
                        placeholder="Enter policy name"
                        class="form-control form-control-sm d-block"
                    />
                </label>
            </div>
            <fieldset class="d-block col col-12 col-lg-6">
                <div v-if="isLoadingClassifierList">
                    <Spinner/>
                </div>
                <legend v-else class="label-calm d-block">
                    Classifiers
                    <label
                        v-for="classifier in classifiersListForAccount"
                        :key="classifier.id"
                        class="d-inline me-2"
                    >
                        <div>
                            <input
                                type="checkbox"
                                :value="classifier.id"
                                :checked="classifierIds.includes(classifier.id)"
                                @change="classifierChecked(classifier.id)"
                            >
                            {{classifier.name}}
                        </div>
                    </label>
                </legend>
            </fieldset>
            <div class="form-footer">
                <button type="submit" :disabled="noClassifiersChecked || !hasClassifierChangesForPolicy" class="btn btn-primary pull-right">Save</button>
                <button v-if="isShowDeleteButton" type="button" class="btn btn-danger pull-right me-2" @click="deletePolicy(savedPolicy.id)">Delete</button>
                <spinner v-if="isValidatingDelete"></spinner>
                <br>
                <br>
                <p v-if="validationError" class="alert alert-danger">{{validationError}}</p>
            </div>
        </form>
    </div>
</template>

<style lang="scss">
</style>
