<template>
    <div class="container">
        <div v-if="!success">
            <form  v-if="!locked" class="form mb-2" @submit.prevent="customize" :style="{
                // width: showingDialog ? '800px' :  '400px',
                // height: showingDialog ? '600px' : '400px'
                }">
                <label>
                    Account
                    <select class="form-control form-select" :value="currentAccount && currentAccount.id" @change="accountChange($event.target.value)" :disabled="locked">
                        <option v-for="a in accounts" :key="a.id" :value="a.id">{{a.name}}</option>
                    </select>
                </label>
                <label>
                    Collection
                    <!--<select class="form-control" :value="currentCollection && currentCollection.id" @change="collectionChange($event.target.value)" :disabled="locked">-->
                        <!--<option v-for="c in accountCollections" :value="c.id">{{c.name}}</option>-->
                    <!--</select>-->
                </label>
                <select-collection-topic type="collection" :value="selectedCollection && currentCollection && currentCollection.id" @input="collectionChange($event)" :disabled="locked"></select-collection-topic>
                <!--<output>Adding to {{currentCollection && currentCollection.name}} in account {{currentAccount && currentAccount.name}}</output>-->
                <!--<button class="btn btn-primary full-width">Customize and Add</button>-->
            </form>
            <p v-if="locked" class="small alert alert-water border mt-3"  style="height: 4em;
            vertical-align: middle">
                <span class="mt-1 d-inline-block">
                    Adding to: <strong><em>{{currentCollection && currentCollection.name}}</em></strong>,
                    in: <strong><em>{{currentAccount && currentAccount.name}}</em></strong>
                </span>
                <button type="button" class="btn btn-sm btn-secondary float-end mt-0 mb-1" style="vertical-align: top" @click="reset">Change</button>
            </p>
            <add-url-form
                    :url="$route.query.url"
                    :summary="$route.query.summary"
                    :default-title="$route.query.title || ''"
                    :default-publisher="$route.query.publisher || ''"
                    :title="$route.query.title"
                    class="mb-5"
                    @lock="locked = true"
                    ref="addUrlForm"
                    :disabled="!selectedAccount || !selectedCollection"
                    :currentCollection="currentCollection"
                    @addedToCollection="addedToCollection"
            >
            </add-url-form>
        </div>
        <div v-else>
            <div class="alert alert-green">
                Successfully added to collection!
                <a :href="`https://my.upcontent.com/?collection=${currentCollection.id}`" target="_blank">Go to Collection</a>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script>
    import RouteContext from '@/utilities/RouteContext'
    import {mapGetters, mapActions} from 'vuex';
    import AddUrlForm from "@/components/Collections/AddUrlForm";
    import SelectCollectionTopic from "@/components/Controls/CollectionTopicSelector";
    export default {
        components: {SelectCollectionTopic, AddUrlForm},
        mixins: [RouteContext],
        computed: {
            ...mapGetters({
                accounts: 'allAccounts',
                collections: 'collections',
                currentAccount: 'account',
                currentCollection: 'currentCollection'
            }),
            accountCollections() {
                return this.collections.filter(c => c.account.includes(this.currentAccount && this.currentAccount.id))
            }
        },
        data() {
            return {
                showingDialog: false,
                selectedAccount: true,
                selectedCollection: true,
                locked: false,
                success: false
            }
        },
        methods: {
            ...mapActions({

                setCurrentAccount: 'setCurrentAccount',
                setCurrentCollection: 'setCurrentCollection'
            }),
            customize() {
                this.showingDialog = true;
                window.Events.$emit('showAddUrlToCollectionModal', this.$route.query.url);
            },
            accountChange(value) {
                let account = this.accounts.find(a => a.id === value);
                this.selectedAccount = Boolean(account);
                if (account)
                    this.setCurrentAccount({account})
            },
            collectionChange(value) {
                let collection = this.collections.find(c => c.id === value);
                this.selectedCollection = Boolean(collection);
                console.log('collectionChange', {value, collection});
                if (collection)
                    this.setCurrentCollection({collection})
            },
            lock() {
                this.locked = true;
            },
            reset() {
                this.locked = false;
                this.$refs.addUrlForm.cancel();
            },
            addedToCollection() {
                this.success = true;
            }
        },
        mounted() {
            this.$store.dispatch('setSuggestionViewerType', {type : 'collections'});
            console.log('AddToCollection.load')
        }
    }
</script>
