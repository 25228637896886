<template>
    <div class="suggestions-global-nav d-flex justify-content-end align-items-center">
        <a href="#scrolling-suggestions-container" v-if="!showBackToCollectionLink && collection" class="dropdown-item screen-reader-only">Focus Article List</a>

        <div class="dropdown current me-3 position-static-xs" ref="suggestionGlobalNavigationDropDown">
            <button class="btn btn-link text-nowrap nav-link text-decoration-none dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="menuitem"
                    @click.prevent
                    aria-haspopup="true"
                  aria-expanded="false"
                  :aria-label="`${type}, active is ${name}`" id="open-suggestions-dropdown">
                <span class="suggestion-toggle me-2 d-none d-sm-inline" :title="name">Topics &amp; Collections</span>
                <span class="suggestion-toggle d-sm-none" :title="name">Content</span>
<!--                <i class="suggestion-toggle-icon fa fa-caret-down"></i>-->
            </button>
            <div class="dropdown-menu advanced-menu dropdown-menu-end p-3" role="menu"
                 aria-labelledby="open-suggestions-dropdown" @click.stop>
                <folder-navigator v-if="topic || collection || account"></folder-navigator>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import FolderNavigator from "@/components/Suggestions/ManageSuggestion/FolderNavigator";
import BackToButton from "../../Suggestions/ManageSuggestion/BackToButton";

export default {

    name: 'SuggestionsGlobalNavigation',

    components : {
        BackToButton,
        FolderNavigator
    },

    computed : {
        ...mapGetters({
            type : 'suggestionViewerType',
            topic : 'currentTopic',
            collection : 'currentCollection',
            account: 'currentAccount'
        }),
        showBackToCollectionLink() {
            return this.$route.name !== 'Suggestions' && this.name;
        },
        name(){
            switch(this.type){
                case 'topics':
                    return this.topic ? this.topic.name : false;
                case 'collections':
                    return this.collection ? this.collection.name : false;
                default:
                    return false
            }
        }
    },
    mounted(){
        window.Events.$on('NavigatedToSuggestion', this.toggleDropdown);
    },
    methods : {
        toggleDropdown() {
            const theDropdown = this.$refs.suggestionGlobalNavigationDropDown;

            try{
                theDropdown.click();
            }
            catch (e) {
                // console.log('BS Toggle Failure', e);
            }
        }
    }
}
</script>

<style lang="scss">
// Do not scope
.suggestions-global-nav {
    .folder-navigation-actions {
        margin-top: .75em;
    }
}
</style>
<style scoped lang="scss">
    .dropdown-menu {
        width: 300px;
        min-width: 300px;
        z-index: 1010;
        max-height: calc(100vh - 8em)!important;
        overflow-y: auto!important;;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        .dropdown-menu {
            max-height: calc(100vh - 6em)!important;
        }
    }

    // Hootsuite niche case.
    @media screen and (max-width: 300px){
        .dropdown-menu {
            width: 100%;
            min-width: 100%;
        }
    }
    .dropdown{
        overflow-x: visible;
    }
</style>
