<template>
    <form @submit.prevent.stop="submit" class="collection-content-campaign-edit">
        <label>
            Name
            <input type="text" class="form-control form-control-sm" v-model="name">
        </label>
        <label>
            Template
            <select class="form-control form-select form-control-sm" v-model="templateId">
                <option v-for="template in contentTemplates" :key="template.id" :value="template.id">
                    {{template.name}}
                </option>
            </select>
        </label>
        <label v-if="!collectionId && !campaign">Collection</label>
        <collection-topic-selector type="collection"  v-if="!collectionId && !campaign" v-model="selectedCollectionId"></collection-topic-selector>
        <h5 class="legend">Filter for this Collection</h5>
        <hr class="mt-0">

        <select-filter-view
            v-model="filterViewId"
            :has-content-type="hasContentType"
            :has-content-id="hasContentId"
            :currentUsage="campaign && campaign.id"
            class="mb-2"
            ref="filterViewEdit"
        >

        </select-filter-view>

        <div v-if="features.contentSections && sections.length > 1">
            <h5 class="mt-0">Sections</h5>
            <hr class="mt-0">
            <content-template-sections  :sections="sections" :limit="100" @input="sectionsUpdated">
            </content-template-sections>
        </div>
        <button type="submit" class="btn-sm btn btn-primary full-width">Save</button>
    </form>
</template>

<style>
    .collection-content-campaign-edit .table th {
        border-top: none;
        padding-top: 0;
    }

    .legend {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0;
        font-size: 16px;
        margin-bottom: 5px;
        line-height: inherit;
        color: inherit;
        white-space: normal;
    }
</style>

<script>
    import {mapGetters} from 'vuex'
    import CollectionTopicSelector from "@/components/Controls/CollectionTopicSelector";
    import HasTemplates from '@/components/Integrations/Bee/HasBeeTemplates'
    import * as toastr from "toastr";
    import ContentTemplateSections from "@/components/ContentTemplates/ContentTemplateSections";
    import { Array as ArrayUtils} from 'sugar'
    import SelectFilterView from "@/components/FilteringAndSorting/SelectFilterView";
    export default {
        mixins: [HasTemplates],
        components: {SelectFilterView, ContentTemplateSections, CollectionTopicSelector},
        computed: {
            ...mapGetters({
                account: 'account',
                features: 'features'
            }),
            dataToSave() {
                return {
                    name: this.name,
                    template_id: this.templateId,
                    collection_id: this.collectionId,
                    filter_view_id: this.filterViewId,
                    config: {
                        sections: this.sectionsToSave
                    }
                }
            }
        },
        props: {
            collectionId: String,
            campaign: Object
        },
        watch: {
            collectionId() {
                this.selectedCollectionId = this.collectionId;
                if (this.collectionId && this.campaign) {
                    Object.assign(this, {
                        name: this.campaign && this.campaign.name || '',
                        templateId: this.campaign && this.campaign.template_id || '',
                        filterViewId: this.campaign && this.campaign.filter_view_id || '',
                        selectedCollectionId: this.campaign && this.campaign.collection_id || this.collectionId,
                        hasContentId: this.collectionId,
                        hasContentType: 'collection',
                    })
                }
            },
            campaign() {
                Object.assign(this, {
                    name: this.campaign && this.campaign.name || '',
                    templateId: this.campaign && this.campaign.template_id || '',
                    filterViewId: this.campaign && this.campaign.filter_view_id || '',
                    selectedCollectionId: this.campaign && this.campaign.collection_id || this.collectionId,
                    hasContentId: this.collectionId,
                    hasContentType: 'collection',
                })
            },
            templateId() {
                this.loadSections()
            }
        },
        data() {
            return {
                name: this.campaign && this.campaign.name || '',
                templateId: this.campaign && this.campaign.template_id || '',
                filterViewId: this.campaign && this.campaign.filter_view_id || '',
                selectedCollectionId: this.campaign && this.campaign.collection_id || this.collectionId,
                hasContentId: this.collectionId,
                hasContentType: 'collection',
                sections: [],
                sectionsToSave: []
            }
        },
        methods:{
            async load() {
                await this.refresh();
                this.editFilters();
                this.loadSections()
            },
            async submit() {
                let id = this.campaign && this.campaign.id;
                if (id) {
                    await this.$api.put(`/collection-content-campaigns/${id}`, this.dataToSave);
                } else {
                    let resp = await this.$api.post(`/collections/${this.selectedCollectionId}/content-campaigns`, this.dataToSave);
                    id = resp.data.id;
                }
                toastr.success('Saved')
                this.$emit('saved', {id, ...this.dataToSave})
            },

            editFilters() {
                this.hasContentType = 'collection';
                this.hasContentId = this.collectionId;
            },
            async loadSections() {
                if (!this.templateId) {
                    this.sections = [];
                    return;
                }
                let sectionData = await this.$api.get(`/content-templates/${this.templateId}/sections`).then(r=>r.data);
                let sectionCount = sectionData.section_count;
                this.sections = this.campaign && this.campaign.config && this.campaign.config.sections || [];

                if (this.sections.length < sectionCount) {
                    let newItems = ArrayUtils.construct(sectionCount - this.sections.length, index=>({ index, count: 1}));
                    console.log('add new section configs', {existing: this.sections.length,  target: sectionCount, newItems})
                    this.sections = [...this.sections, ...newItems]
                } else if (this.sections.length > sectionCount) {
                    this.sections.splice(sectionCount - 1)
                    console.log('remove section configs', {existing: this.sections.length,  target: sectionCount})
                } else {
                    console.log('section config count matches target')
                }

                if (this.sections.every(x => x.count)) {
                    this.sections[this.sections.length-1].count = null;
                }
                this.sectionsToSave = this.sections;
            },
            sectionsUpdated(val) {
                this.sectionsToSave = val;
            }
        },
        mounted() {
            this.load()
        }
    }
</script>
