<script>
import { mapGetters } from 'vuex';
export default {
    computed : {
        ...mapGetters([
            'currentAccount',
            'currentTopic',
            'currentCollection',
            'topics',
            'collections',
            'suggestionViewerType'
        ]),
        accountTopics(){
            return this.topics.filter(t => t.account && t.account.includes(this.currentAccount.id));
        },
        accountCollections(){
            return this.collections.filter(c => c.account && c.account.includes(this.currentAccount.id));
        },
        currentTopicIsNotInAccount(){
            return ! this.currentTopic || ! this.currentTopic.account.includes(this.currentAccount.id);
        },
        currentCollectionIsNotInAccount(){
            return ! this.currentCollection || ! this.currentCollection.account.includes(this.currentAccount.id);            
        }
    },
    watch : {
        currentAccount(){
            this.setTopic()
            this.setCollection()
        },
        suggestionViewerType(){

            if (this.$route.path == '/topics' && this.suggestionViewerType == 'collections'){
                this.$router.push('/collections');
                if ( ! this.currentCollection)
                    this.setCollection();
            }
            
            if (this.$route.path == '/collections' && this.suggestionViewerType == 'topics'){
                this.$router.push('/topics');
                if ( ! this.currentTopic)
                    this.setTopic();
            }

            if (this.$route.path == "/welcome" && this.suggestionViewerType == 'collections' && ! this.accountTopics.length){
                this.$router.push("/");
            }

            if (this.$route.path == "/" && this.suggestionViewerType == 'topics' && ! this.accountTopics.length){
                this.$router.push("/welcome");
            }

            if (this.$route.path == "/" && this.suggestionViewerType == 'collections' && this.accountCollections.length){
                this.setCollection();
            }
        }
    },
    methods : {
        setTopic(){
            
            if (this.currentTopicIsNotInAccount && this.accountTopics.length){
                this.$store.dispatch('setCurrentTopic', {topic: this.accountTopics[0], switchTo: false});
            } else if(this.accountTopics.length == 0) {
                this.$store.dispatch('setCurrentTopic', {topic : false});
            }
        },
        setCollection(){
            if (this.currentCollectionIsNotInAccount && this.accountCollections.length){
                this.$store.dispatch('setCurrentCollection', {collection: this.accountCollections[0], switchTo: false});
            } else if(this.accountCollections.length == 0) {
                this.$store.dispatch('setCurrentCollection', {collection : false});
            }
        }
    },
    render(h){
        return h();
    }
}
</script>
