<template>
    <div class="powerups-grid">
        <div class="d-flex mb-2">
            <div class="flex-grow me-3">
                <input type="search" class="form-control form-control-sm" v-model="searchText" placeholder="Search"/>
            </div>
            <div class="mb-2 d-flex justify-content-between flex-wrap flex-grow me-3">
                <button v-for="x in searchTerms" type="button" class="btn btn-default search-badge-button" @click="searchText=x.search" :key="x.search">
                    {{ x.display }}
                </button>
            </div>
            <div class="flex-grow">
                <select class="form-select form-select-sm">
                    <option selected value="Active">Active</option>
                    <option selected value="Authenticated">Authenticated</option>
                    <option selected value="All">All</option>
                </select>
            </div>
        </div>
        <div class="powerups-grid--grid-container">
            <powerup-card v-for="i in active" :key="i.name" v-bind="i" @change="$emit('change', i)" v-show="powerupSearchMatches(i)"></powerup-card>
            <powerup-card v-for="i in authenticated" :key="i.name" v-bind="i" @change="$emit('change', i)" v-show="powerupSearchMatches(i)"></powerup-card>
            <powerup-card v-for="i in rest" :key="i.name" v-bind="i" @change="$emit('change', i)" v-show="powerupSearchMatches(i)"></powerup-card>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getIconClass} from "../../../../services/Icons";
import PowerupCard from "../../V2/PowerupCard.vue";
export default {
    name: "PowerupsList",
    components: {PowerupCard},
    computed: {
        ...mapGetters({
            allIntegrations: 'apisMeta',
            account: 'account',
            powerups: 'collectionPowerups',
            powerupSearchTerms: 'powerupSearchTerms',
            powerupNameMap: 'powerupNameMap'
        }),
        filtered() {
            return this.powerups.filter(x => x)
        },
        active() {
            return this.$sort.alphabetize(this.powerups.filter(x => x.active), 'title')
        },
        authenticated() {
            return this.$sort.alphabetize(this.powerups.filter(x => !x.active && x.isAuthenticated), 'title')
        },
        rest() {
            return this.$sort.alphabetize(this.powerups.filter(x => !x.active && !x.isAuthenticated), 'title')
        }
    },
    methods: {
        powerupSearchMatches(i) {
            let searchableTerms = this.powerupSearchTerms[this.powerupNameMap[i.name]];
            if (!this.searchText || !searchableTerms) return true;
            let searchTerms = this.searchText.split(' ');
            searchableTerms = searchableTerms.split(' ');
            return searchTerms.every(
                term => searchableTerms.some(word => word.toLowerCase().includes(term.toLowerCase())));
        },
    },
    data() {
        return {
            searchText: ''
        }
    },
    created() {
        this.searchTerms = [
            { search: 'social', display: 'Social'},
            { search: 'email', display: 'Email'},
            { search: 'cta', display: 'CTA'},
            { search: 'employee advocacy', display: 'Employee Advocacy'},
            { search: 'quality', display: 'Content Quality'},
        ]
    }
}
</script>

<style scoped>
.powerups-grid {
    max-height: 100% !important;
    overflow-y: auto;
}
.search-badge-button {
    border-radius: 1em;
    font-weight: var(--font-weight-semibold);
    padding: 0 1em;
    color: var(--primary);
    margin-right: .1em !important;
    margin-top: .5em;
    font-size: var(--font-size-11);
}
.list-group-header {
    font-weight: var(--font-weight-semibold);
    background: var(--water-33);
    color: var(--primary-25);
    font-size: var(--font-size-12);
    padding-bottom: .5em;
}
@media (min-width: 1400px) {
    .powerups-grid--grid-container {
        grid-template-columns: repeat( auto-fit, minmax(256px, 1fr));
    }
}
@media (min-width: 1200px) {
    .powerups-grid--grid-container {
        grid-template-columns: repeat( auto-fit, minmax(235px, 1fr));
    }
}
.powerups-grid--grid-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(230px, 1fr));
    grid-gap: 8px;
}
.powerups-grid-grid {
    
}
</style>
