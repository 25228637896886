<template>
    <modal ref="modal" size="large">
        <template v-slot:header>
            <span>New Workflow Step</span>
        </template>
        <div v-if="type==''">
            <workflow-stage-type-picker v-model="type"></workflow-stage-type-picker>
        </div>
        <div v-else-if="type === 'feeds'">

        </div>
        <div v-else-if="type === 'automation'">
            <tabs>
                <tab label="From" icon-class="fa fa-download">
                    <collections-by-folder :folder="rootFolder" :is-checkable="true"></collections-by-folder>
                    <filters-form></filters-form>

                </tab>
                <tab label="To" icon-class="fa fa-upload">
                    <collections-by-folder :folder="rootFolder" :is-checkable="true"></collections-by-folder>
                    <filters-form></filters-form>
                </tab>
            </tabs>
        </div>
        <div v-else-if="type === 'collaboration'">
        </div>
        <div v-else-if="type === 'destination'">
            <ul class="no-bullets ps-0">
                <li>
                    <button type="button" class="btn btn-outline-primary full-width mb-2">
                        <i class="integration-logo icon-medium amplify-icon me-1"></i>
                        <span class="integration-name">Amplify Topic</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary full-width mb-2">
                        <i class="integration-logo icon-medium hubspot-icon me-1"></i>
                        <span class="integration-name">Hubspot Email Newsletter</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary full-width mb-2">
                        <i class="integration-logo icon-medium mailchimp-icon me-1"></i>
                        <span class="integration-name">Mailchimp Email Newsletter</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary full-width mb-2">
                        <i class="fa fa-envelope me-1"></i>
                        <span class="integration-name">HTML Email Newsletter</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary full-width mb-2">
                        <i class="fa fa-globe me-1"></i>
                        <span class="integration-name">Website Gallery</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary full-width mb-2">
                        <i class="fa fa-rss me-1"></i>
                        <span class="integration-name">RSS Feed</span>
                    </button>
                </li>
            </ul>
        </div>
    </modal>
</template>

<style>

</style>

<script>
import Modal from "@/components/Controls/Modal";
import WorkflowStageTypePicker from "@/components/Workflows/WorkflowStageTypePicker";
import FiltersForm from "@/components/FilteringAndSorting/FiltersForm";
import CollectionsByFolder from "@/components/Collections/CollectionsByFolder";
import {mapGetters} from 'vuex'
import {Tab, Tabs} from "@/components/Controls/Tabs";

export default {
    components: {Tab, Tabs, CollectionsByFolder, FiltersForm, WorkflowStageTypePicker, Modal},
    computed: {
        ...mapGetters({
            collections: 'collections',
            features: 'features',
            folders: 'folders',
            account: 'currentAccount',
            collection: 'currentCollection'
        }),
        rootFolder() {
            return this.folders.find(f => this.account.root.includes(f.id));
        },
    },
    data() {
        return {
            type: ''
        }
    },
    watch: {
        type() {
            if (this.type === 'topic') {
                this.$refs.modal.hide();
                window.Events.$emit('showQueryBuilderModal', {topic: false});
            }
        }
    },
    methods: {
        show() {
            this.type = ''
            this.$refs.modal.show();
        }
    }
}
</script>
