<template>
    <div class="form add-url-form">
        <main>
            <edit-selection v-if="suggestion"
                            ref="editSelection"
                            :selection="suggestion"
                            :default-summary="summary"
                            :auto-edit-mode="true"
            ></edit-selection>
            <label v-else>
                URL
                <input ref="urlEntry" class="form-control" placeholder="Enter URL here" v-model="urlVal"/>
            </label>

            <progress-bar class="my-3" v-if="networkActivity" />

            <div class="alert alert-blue" v-if="networkActivity">{{networkActivity}}</div>
            <div class="alert alert-danger my-3" v-if="networkError">
                {{networkError}}
            </div>
        </main>
        <footer class="mt-2 add-url-buttons">
            <button type="button" v-if="suggestion" class="btn btn-primary me-2" :disabled="networkActivity" @click="addToCollection">Add To Collection</button>
            <button type="button" v-else class="btn btn-primary" :disabled="networkActivity || disabled || !currentCollection" @click="fetchUrlContents">Check URL</button>
            <button type="button" v-if="suggestion" class="ms-auto btn btn-secondary" @click="cancel">Cancel</button>
        </footer>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ProgressBar from '@/utilities/ProgressBar'
import HeroImage from "@/components/Suggestions/HeroImage"
import EditSelection from "@/components/Collections/EditSelection";
import {knownTags} from "@/Constants";
import SuggestionTags from "@/components/Suggestions/SuggestionTags";
import RouteContext from '@/utilities/RouteContext'
import Embedded from "@/utilities/Embedded";
import {ContentPoliciesService} from "@/services/contentPolicies";

export default {
    components : {
        SuggestionTags,
        EditSelection,
        ProgressBar,
        HeroImage,
    },
    mixins: [RouteContext, Embedded],
    props: {
        canClose: {type: Boolean, default: true},
        url: {type: String, default: null},
        summary: {type: String, default: null},
        defaultTitle: {type: String, default: null},
        defaultPublisher: {type: String, default: null},
        disabled: {type: Boolean, default: false},
        currentCollection: {type: Object, required: true},
    },
    data(){
        return {
            suggestion : false,
            networkError : false,
            suggestionUrl : false,
            tasks: [],
            urlVal: this.url,
            selectionUrl: false,
            isShouldNotAddToCollection: false,
            collectionContentPolicies: null,
            uncrawlable: false,
        }
    },
    watch: {
        url() {
            this.urlVal = this.url;
        }
    },
    computed: {
        ...mapGetters({
            collections : 'collections',
            suggestions : 'suggestionsCache',
            accountFeatures: 'accountFeatures',
            features: 'features',
            currentContentPolicy: 'currentContentPolicy',
        }),
        tags() {
            return this.suggestion.tags.map(t => {
                if (knownTags[t.key]) {
                    return Object.assign({}, t, knownTags[t.key])
                } else return t;
            })
        },
        networkActivity() {
            return this.tasks[0] || false;
        }
    },
    mounted(){

    },
    methods : {
        cancel(){
            if (!this.suggestion || !this.suggestion.id){
                this.dismiss()
            } else {
                this.suggestion = false;
                this.$api.delete(this.suggestionUrl);
                this.suggestionUrl = false;
                this.selectionUrl = false;
            }
        },
        async getCollectionContentPolicy(collectionId) {
            return await this.$apis.contentPolicy.collectionsCollectionIdContentPolicyGet(collectionId).then(resp => resp.data)
        },
        async contentPolicies(selection) {
            this.isShouldNotAddToCollection = false;
            this.uncrawlable = false;
            this.collectionContentPolicies = await this.getCollectionContentPolicy(this.currentCollection.id);

            if(!selection.id && this.collectionContentPolicies.length > 0) {
                this.isShouldNotAddToCollection = true;
                this.uncrawlable = true;
                return;
            }

            if(this.collectionContentPolicies && this.collectionContentPolicies.length) {
                const mandatoryPolicy = this.collectionContentPolicies.find(p => p.enforcement_level === 'MANDATORY');
                const collectionMandatoryContentPolicyId = mandatoryPolicy.policy_id;
                const policyService = new ContentPoliciesService(window.$app.apis);
                const contentPolicyValidation = await policyService.checkOne(collectionMandatoryContentPolicyId, {contentItemId: selection.id});
                console.log('Result for checking if content policy failed', contentPolicyValidation)
                if(contentPolicyValidation.failed_content_policy) {
                    await this.$store.dispatch('loadContentPolicy', collectionMandatoryContentPolicyId)
                    this.isShouldNotAddToCollection = true;
                }
            }
        },
        fetchUrlContents(){
            this.$emit('lock', true);
            this.tasks = [
                "Fetching contents of URL from publisher...",
                "Processing results and formatting preview...."
            ];
            this.networkError = false;

            this.$api.post(`/collections/${this.currentCollection.id}/previews`, {url : this.urlVal})
            .then(async response => {
                this.tasks = this.tasks.filter(x => x !== "Fetching contents of URL from publisher...")
                let suggestionUrl = response.data.suggestion;
                let selectionUrl = response.data.selection;
                await Promise.all([
                    this.loadSelection(suggestionUrl, selectionUrl),
                    this.qualityCheck(suggestionUrl, selectionUrl)
                ]);
                this.tasks = [];


            })
                .catch(e => {
                    this.suggestion = {
                        title: this.defaultTitle || '',
                        summary: "",
                        publisher: this.defaultPublisher || "",
                        date_published: null,
                        url: this.url,
                        image_url: "",
                    };

                    this.tasks = [];
                    this.networkError = e.response.data.description;
                })


        },
        async qualityCheck(suggestionUrl, selectionUrl) {
            this.tasks.push('Checking your organization\'s content policy...')
            let qualityChecks = await this.$api.get(`/collections/${this.currentCollection.id}/quality-checks`).then(r=>r.data)
            this.tasks = this.tasks.filter(x => x !== 'Checking your organization\'s content policy...')
            if (qualityChecks && qualityChecks.length) {
                this.tasks.push('Checking content against your organization\'s content policy...')
                await this.$api.post(selectionUrl + '/quality-check');
                await this.loadSelection(suggestionUrl, selectionUrl);
                this.tasks = this.tasks.filter(x => x !== 'Checking content against your organization\'s content policy...')
            }
        },
        async loadSelection(suggestionUrl, selectionUrl) {
            await this.$api.get(suggestionUrl).then(response => {
                this.suggestion = response.data
                this.suggestionUrl = suggestionUrl;
                this.selectionUrl = selectionUrl;
            })
            this.tasks = this.tasks.filter(x => x !== "Processing results and formatting preview....")
        },
        show(){
            console.log('AddUrlToCollectionModal.show')
            this.tasks = [];
        },
        dismiss(){
            this.tasks = [];

        },
        async addToCollection(){
            await this.contentPolicies(this.suggestion);

            if(this.isShouldNotAddToCollection && this.uncrawlable) {
                this.networkError = 'This collection has a content policy on it, and we were unable to crawl content to verify if it passes the policy';
                return; // short circuit early so article isn't added
            }

            if(this.isShouldNotAddToCollection && !this.uncrawlable) {
                this.networkError = `Article does not pass Collection's Content Policy: ${this.currentContentPolicy.name}`;
                return; // short circuit early so article isn't added
            }

            this.tasks = ['Saving...']
            let selection = this.suggestion;

            if (!selection.id) {
                let suggestionUrl = {};
                let selectionUrl = {};

                await this.$api.post(`/collections/${this.currentCollection.id}/selections`,
                    this.$refs.editSelection.getInputData()).then(response => {
                    suggestionUrl = response.data.suggestion;
                    selectionUrl = response.data.selection;
                    this.tasks = ['Saving......'];
                });
                await Promise.all([
                    this.loadSelection(suggestionUrl, selectionUrl, true),
                    this.qualityCheck(suggestionUrl, selectionUrl, true)
                ]).then(() => {
                    selection = this.suggestion;
                    this.$refs.editSelection.selection.id = selection.id;
                    this.tasks = ['Saving...'];
                });
            }

            if (this.accountFeatures.customSelectionImageText) {
                selection = await this.$refs.editSelection.uploadAndSave();
            }

            await this.$api.post(this.selectionUrl, {quality_check: 'complete'});
            let selectionNew = await this.$api.get(this.selectionUrl).then(r => r.data);
            selectionNew.dontReset = true;
            selection = {...selection, ...selectionNew};

            this.$store.dispatch('addUrlToSuggestions', {url: this.suggestionUrl});
            this.$store.dispatch('addSuggestionToCache', {suggestion: selection});
            this.$store.dispatch('addSourceForSuggestion',
                {source: this.suggestion.publisher, hasSuggestionsId: this.currentCollection.id});
            this.tasks = [];
            this.dismiss();
            this.$emit('addedToCollection');
            this.messageParent('upcontent.addToCollection', {
                collection: {id: this.currentCollection.id, name: this.currentCollection.name },
                contentItem: {
                    id: this.suggestion.id,
                    title: this.suggestion.title,
                    image_url: this.suggestion.image_url,
                    summary: this.suggestion.summary,
                    publisher: this.suggestion.publisher,
                    date_published: this.suggestion.date_published,
                    url: this.$shortener.link(this.suggestion),
                    source_url: this.suggestion.source_url,
                    suggestionUrl: this.suggestionUrl,
                    selectionUrl: this.selectionUrl
                }
            });
        }
    }
}
</script>

<style scoped>
@media (min-width: 576px){
    .modal-dialog {
        max-width: 700px;
        margin: 1.75rem auto;
    }
}

</style>
