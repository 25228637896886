import * as types from '../mutation-types'
import {CollectionSourceViewModel} from "@/models/CollectionSourceViewModel";
import Vue from 'vue'

function api() {
    return Vue.prototype.$api
}

const state = {
    collections : [],
    currentCollection : false,
    sources: [],
    powerups: [],
    advanced: {},
    htmlEmails: []
}
const getters = {
    collections : state => state.collections,
    collectionSources : state => state.sources,
    nonIntegrationPowerups: (state, getters, rs, rootGetters) =>  [
        {
            name: "mail",
            title: "HTML Emails",
            active: getters.hasHtmlEmails,
            isAuthenticated: rootGetters.accountFeatures.contentTemplates
        },
        {
            name: 'openai',
            title: 'AI Post Text Generation',
            active: getters.collectionPostTextSettings?.on,
            isAuthenticated: rootGetters.currentAccountDataOpenai?.openai_api_key,
        }
    ],
    collectionPowerups : (state, getters) => [...state.powerups || [], ...getters.nonIntegrationPowerups || []],
    currentCollection: state => state.collections.find(c => c.id == state.currentCollection),
    currentCollectionRole: (state, getters) => getters.currentCollection && getters.currentCollection.role,
    canManageCollection: (state, getters) => ['manager', 'administrator'].includes(getters.currentCollectionRole),
    canEditCollectionContent: (state, getters) => ['manager', 'administrator'].includes(getters.currentCollectionRole),
    htmlEmails: state => state.htmlEmails,
    hasHtmlEmails: state => state.htmlEmails && state.htmlEmails.length,
    advancedCollectionSettings: state => state.advanced,
    collectionContentPolicy: state => state.advanced && state.advanced.policy,
    collectionAbstractLimit: (state, getters) =>
        getters.collectionContentPolicy && getters.collectionContentPolicy.abstractLimit || null,
    collectionIndexingSettings: state => state.advanced && state.advanced.indexingSettings,
    collectionPostTextSettings: state => state.advanced && state.advanced.post_text_generation,
    collectionIndexCollection: (state, getters) =>  
        getters.collectionIndexSettings && getters.collectionIndexingSettings.indexCollection
}

const actions = {
    addCollection : ({commit}, {collection}) => {
        commit("ADD_COLLECTION", {collection})
    },
    addItemsToCollections : ({commit}, {items, collectionIDs}) => {
        commit("ADD_ITEMS_TO_COLLECTIONS", {items, collectionIDs});
    },
    async setCurrentCollection ({commit, dispatch}, {collection, switchTo}){
        await commit("SET_CURRENT_COLLECTION", {collection});
        if (switchTo === false) return;
        await dispatch('setSuggestionViewerType', {type : 'collections'})
        await Promise.all([
            dispatch('loadSuggestionView').catch(console.error),
            dispatch(actions.loadCollectionSources.name).catch(console.error),
            dispatch(actions.loadCollectionPowerups.name).catch(console.error)
        ])
        await dispatch('loadAdvancedSettings', {})
    },
    async loadAdvancedSettings({commit, getters}) {
        if (getters.currentCollection && getters.currentCollection.id) {
            let settings = ['policy', 'indexingSettings', 'post_text_generation'];
            let fetchData = async x => ({key: x, response: await api().get(`/collections/${getters.currentCollection.id}/data/${x}`)});
            let results = await Promise.all(settings.map(fetchData));
            results = results.map(({key, response}) =>
                response.status === 200 && response.data ? {key, response} : {key, response: {}}
            )
            results.forEach(({key, response: {data}}) => {
                commit("SET_ADVANCED_COLLECTION_SETTING", {key, data});
            })
        }
    },
    removeSuggestionFromCurrentCollection : ({commit}, {suggestion}) => {
        commit("REMOVE_ITEM_FROM_CURRENT_COLLECTION", {item : suggestion});
    },
    removeCollection : ({commit}, {collection}) => {
        commit("REMOVE_COLLECTION", {collection});
    },
    async loadCollectionSources({commit, getters}) {
        if (!getters.currentCollection || !getters.currentCollection.id) {
            commit("LOAD_COLLECTION_SOURCES", {sources: []});
            return;
        }
        let result = await api().get(`/collections/${getters.currentCollection.id}/sources`)
        let sources = result.data.map(CollectionSourceViewModel.fromApi);
        commit("LOAD_COLLECTION_SOURCES", {sources});
    },
    addCollectionSource: ({commit}, {source}) => {
        commit("ADD_COLLECTION_SOURCE", {source});
    },
    removeCollectionSource: ({commit}, {source}) => {
        commit("REMOVE_COLLECTION_SOURCE", {source});
    },
    async loadCollectionPowerups({commit, getters, dispatch}) {
        if (!getters.currentCollection || !getters.currentCollection.id) {
            await commit("LOAD_COLLECTION_POWERUPS", {sources: []});
            return;
        }
        let result = await api().get(`/collections/${getters.currentCollection.id}/integrations`)
        let powerups = result.data.map(x => ({...x, isAuthenticated: x.is_authenticated}))
        await commit("LOAD_COLLECTION_POWERUPS", {powerups});
        await dispatch(actions.loadCollectionHtmlEmails.name)
        await dispatch('loadAccountData');
    },
    async loadCollectionHtmlEmails({commit, getters}) {
        if (getters.currentCollection && getters.currentCollection.id) {
            let result = await window.$app.api.get(`/collections/${getters.currentCollection.id}/content-campaigns`);
            let htmlEmails = result.data;
            commit(mutations.SET_HTML_EMAILS_FOR_CURRENT_COLLECTION.name, {htmlEmails});
        }
    },
    addCollectionPowerup: ({commit}, {powerup}) => {
        commit("ADD_COLLECTION_POWERUP", {powerup});
    },
    removeCollectionPowerup: ({commit}, {powerup}) => {
        commit("REMOVE_COLLECTION_POWERUP", {powerup});
    },
}


const mutations = {
    SET_HTML_EMAILS_FOR_CURRENT_COLLECTION(state, {htmlEmails}){
        state.htmlEmails = htmlEmails;
    },
    [types.ADD_COLLECTIONS](state, {collections}){
        state.collections = state.collections.concat(collections.filter(t => !state.collections.find(e => e.id == t.id)));

    },
    SET_ADVANCED_COLLECTION_SETTING: (state, {key, data}) => {
        state.advanced = {...state.advanced, [key]: data}
    },

    LOAD_COLLECTION_SOURCES: (state, {sources}) => {
        state.sources = sources
    },
    LOAD_COLLECTION_POWERUPS: (state, {powerups}) => {
        state.powerups = powerups
    },
    ADD_COLLECTION_SOURCE: (state, {source}) => {
        state.sources = [...state.sources, source]
    },
    ADD_COLLECTION_POWERUP: (state, {powerup}) => {
        state.powerups = [...state.powerups, powerup]
    },
    REMOVE_COLLECTION_SOURCE: (state, {source}) => {
        state.sources = state.sources.filter(s => s.id !== source.id)
    },
    REMOVE_COLLECTION_POWERUP: (state, {powerup}) => {
        state.powerups = state.powerups.filter(p => p.id !== powerup.id)
    },

    REMOVE_COLLECTION : (state, {collection}) => {
        state.collections = state.collections.filter(c => c.id !== collection.id);
    },
    REMOVE_ITEM_FROM_CURRENT_COLLECTION : (state, {item}) => {

        var existing = state.collections.find(c => c.id == state.currentCollection);
        let index = state.collections.indexOf(existing);
        if (existing){
            existing.suggestions.splice(existing.suggestions.indexOf(item.id), 1);
            state.collections.splice(index, 1, existing)
        }

    },
    SET_CURRENT_COLLECTION : (state, {collection}) => {
        if (collection){
            state.currentCollection = collection.id;
        } else {
            state.currentCollection = false
        }
    },
    ADD_COLLECTION : (state, {collection}) => {
        let existing = state.collections.find(s => s.id == collection.id);
        if (!existing) {
            state.collections.push(collection)
        } else {
            let index = state.collections.indexOf(existing);
            state.collections.splice(index, 1, collection);
        }
    },
    ADD_ITEMS_TO_COLLECTIONS : (state, {items, collectionIDs}) => {
        collectionIDs.forEach((id) => {
            var existing = state.collections.find(c => c.id == id);
            let index = state.collections.indexOf(existing);
            if (existing){
                if ( ! existing.suggestions ) existing.suggestions = [];
                existing.suggestions = existing.suggestions.concat( items.map(i => i.id) );
                state.collections.splice(index, 1, existing);
            }
        })
    },
    [types.USER_DID_LOG_OUT](state){
        state.collections = [];
    },
    [types.MOVE_COLLECTIONS_TO_FOLDER](state, {collections, folder}){
        collections.forEach(c => {
            let existing = state.collections.find(collection => collection.id == c.id);
            let index = state.collections.indexOf(existing);
            let replacement = {...existing, folder : folder.id};
            state.collections.splice(index, 1, replacement);
        })
    },
    [types.DELETE_COLLECTION](state, {collection}){
        let existing = state.collections.find(c => collection.id == c.id);
        let index = state.collections.indexOf(existing);
        state.collections.splice(index, 1);        
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
