<template>
    <details :open="true" class="powerup">
        <summary class="mb-2">
            <span class="power-up-label d-flex ">
                <i class="fa fa-check-circle icon-dynamic d-inline text-success"/>
                <span class="ms-2">
                    Quality Checks
                    <div class="text-muted small" >
                        Check your content against various quality checks your organization has setup
                        <a :href="learnUrls.qualityChecks" v-if="learnUrls.qualityChecks"  target="_blank">Learn More</a>
                    </div>
                </span>
            </span>
        </summary>

        <ul class="no-bullets ms-2 mt-4 ps-0 list-group">
            <li v-for="check in checks" class="" :key="check.id">
                <label>
                    <input type="checkbox" v-model="checksEnabled" :value="check.id">&nbsp;
                    {{check.name}}
                </label>
            </li>
        </ul>
    </details>
</template>

<style scoped>
    .power-up-label {
        display: inline-block;
    }

    .power-up-label a {
        text-decoration: underline;
    }
    .power-up-label .icon-dynamic {
        vertical-align: top;
        margin-top: .2em;
    }

    .powerup-action {
        right: 0;
        left: auto;
        position: absolute;
        top: 0;
        bottom:0;
        margin: auto 10px auto auto;
        height: fit-content;
    }
    .powerup summary {
        background: var(--bs-light);
        border-bottom: 1px solid var(--bs-default);
        list-style: none;
        list-style-type: none;
        margin: -10px -10px -1px -10px;
        color: var(--bs-dark);
        position: relative;
    }
    details.powerup summary::-webkit-details-marker {
        display:none;
    }
    .powerup {
        border: 1px solid var(--bs-default);
        padding: 10px;
        padding-bottom: 0;
    }

    .powerup[open] {
        padding-bottom: 20px;
    }


    .powerup[open] summary {
        margin-bottom: 10px;
    }

    .hide {
        display: none;
    }

    .powerup:not(:last-child) {
        border-bottom: none;
    }
    .powerup-message {
        display: inline-block;
        color: var(--bs-danger);
        font-size: 0.7em;
        max-width: 360px;
        margin-left: 10px;
        vertical-align: middle;
        white-space: pre;
    }
    .powerup summary {
        vertical-align: middle;
    }
    .uc-select-sm {
        font-size: 0.875rem;
        color: var(--bs-dark)
    }
</style>

<script>
    import CollectionContentCampaign from "@/components/Collections/powerups/CollectionContentCampaign";
    import {mapGetters} from 'vuex'
    export default {
        components: {CollectionContentCampaign},
        props: {
            collectionId: String
        },
        computed: {
            ...mapGetters({
                apiHost: 'apiHost',
                account: 'account',
                learnUrls: 'learnUrls'
            })
        },
        data() {
            return {
                checks: [],
                checksEnabled: []
            }
        },
        watch: {
            collectionId() {
                this.load()
            }
        },
        methods: {
            async load() {
                this.checks = await this.$api.get(`/accounts/${this.account.id}/quality-checks`).then(r=>r.data)
                try {
                    this.checksEnabled = await this.$api.get(`/collections/${this.collectionId}/quality-checks`).then(r=>r.data) || []
                } catch(e) {
                    console.error(e)
                }
            },
            async save() {
                await this.$api.put(`/collections/${this.collectionId}/quality-checks`, {checks:this.checksEnabled})
            },
        },
        mounted() { this.load() }
    }
</script>
