<template>
    <div class="signup-flow p-4">
        <div class="signup-flow-title mb-2 mb-md-4 pb-2 pb-sm-4 pb-md-0">
            <template v-if="!isStartTrialStep && !hasAccount">
                <h1 class="fw-light">Let's Create Your <span class="text-nowrap">{{appName}} Account!</span>
                </h1>
            </template>
            <template v-if="isStartTrialStep">
                <h1 class="fw-light">Enter Your Payment Info</h1>
                <p>
                    We need a card on file to secure your account,
                    you will not be charged until your 14 day trial ends.
                </p>
            </template>
            <!-- TODO: Users with no topics should ever see this -->
            <template v-if="hasAccount && !isStartTrialStep">
                <h1 class="fw-light">You already have an account.</h1>
                <p class="font-size-17" v-if="showAlreadyLoggedInOptions">
                    You are currently logged in. You can
                    <router-link to="/" class="ux-suf-has-account-return-to-app">
                        return to the application
                    </router-link>
                    or
                    <router-link :to="{name : 'Logout'}"  class="ux-suf-has-account-logout">log out</router-link>
                    .
                </p>
            </template>
        </div>

        <div class="container m-0 p-0 d-flex flex-column justify-content-center">
            <div class="row order-1 signup-flow-primary-container">

                <div class="col order-2 order-sm-1 col-sm-7 ">
                    <!-- TODO: I want this in AccountDetails.vue -->
                    <template v-if="!isStartTrialStep && !hasAccount">
                        <div class="signup-with-trial">
                            <div>

                                <label class="form-group">
                                    <span class="d-flex justify-content-start align-items-center mb-1">
                                        <span class="d-block label-chill">Account Name</span>
                                        <span class="alert alert-tag alert-warning ms-2"
                                              v-if="!isNameComplete">An account name is required.</span>
                                    </span>
                                    <input placeholder="Your name or the name of your organization."
                                           class="form-control ux-suf-account-name"
                                           required="required"
                                           v-model="name"/>
                                </label>

                                <label class="d-block form-group">
                                    <span class="d-flex justify-content-start align-items-center mb-1">
                                        <span class="d-block label-chill">Email</span>
                                        <span class="alert alert-tag alert-warning ms-2"
                                              v-if="!isEmailComplete">An email is required.</span>
                                    </span>
                                    <input class="form-control ux-suf-email" v-model="email"
                                           required="required" type="email"/>
                                </label>

                                <label class="d-block form-group">
                                    <span class="d-flex justify-content-start align-items-center mb-1">
                                        <span class="d-block label-chill">Password</span>
                                        <span class="alert alert-tag alert-warning ms-2"
                                              v-if="!isPasswordComplete">A password is required</span>
                                        <span class="alert alert-tag alert-warning ms-2"
                                              v-if="!isPasswordDecent && isPasswordComplete">Create a stronger password.</span>
                                    </span>
                                    <password-field @enterPressed="createAccount"
                                                    v-model="password" ref="passwordField"></password-field>
                                </label>

                                <div class="alert alert-danger" v-if="errorMessage">
                                    {{ errorMessage }}
                                </div>

                                <div class="alert alert-danger" v-if="networkError">
                                    {{ networkError }}
                                </div>


                            </div>
                        </div>
                    </template>

                    <template v-if="isStartTrialStep">
                        <payment-details ref="paymentDetails"/>
                    </template>
                </div>

                <div class="col-12 order-1 order-sm-2 col-sm-5 col-md-4 offset-md-1 col-xl-4  ">
                    <pricing-plan v-if="isStartTrialStep || (!isStartTrialStep && !hasAccount)"/>
                </div>
            </div>
            <div class="row order-3 order-sm-2">
                <div class="col-12 col-sm-8 offset-sm-2 offset-md-8 col-md-4 pt-4 pt-md-5">
                    <sign-up-button :network-activity="networkActivity"
                                    :party-time="partyTime"
                                    v-if="isCreationStep || isStartTrialStep"
                                    @submitted="submitSignUpAction"
                                    class="full-width mb-2"
                                    :class="{'network-activity': networkActivity}"
                                    ref="signUpButton"
                    >
                        <template v-slot:default>
                            <template v-if="!isStartTrialStep">Create Account</template>
                            <template v-if="isStartTrialStep">Start Trial</template>
                        </template>
                    </sign-up-button>
                </div>
            </div>
            <div v-if="isCreationStep" class="row order-2 order-sm-3">
                <div class="col-12 text-end">
                    <privacy-and-terms-agreement class="font-size-15 mt-2"></privacy-and-terms-agreement>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-end align-items-center small" aria-hidden="true">

        </div>
    </div>
</template>
<style lang="scss">
// Do not scope styles because input::placeholder is inside <password-field />
.signup-with-trial {
    .form-group {
        display: block;
        margin-bottom: 1.25em;
    }

    .form-group input:focus::placeholder {
        color: #fff !important;
    }

    .form-group input::placeholder {
        font-weight: var(--font-weight-light);
        color: var(--steel-50) !important;
        font-style: italic;
        transition: color 500ms;
    }

    // Medium devices (tablets, 768px and up)
    @media (max-width: 767.98px) {
        .form-control::placeholder {
            font-size: 80%;
        }
    }

}

html > body {
    background: var(--water-200) !important;
}
</style>
<style lang="scss">
// Do not scope or these styles won't work.

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
    .network-activity .sign-up-button {
        font-size: var(--font-size-14) !important;
    }
}
</style>
<style lang="scss" scoped>
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    h1 {
        font-size: var(--font-size-22) !important;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    h1 {
        font-size: var(--font-size-26)
    }
}

/* Layout stuff: a static width is needed or content will resize to widest element. */
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .signup-flow-primary-container {
        width: 744px
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .signup-flow-primary-container {
        width: 855px
    }
}
</style>
<script>

import Base from './Base'
import PricingPlan from "@/components/Auth/V2/PricingPlan";
import {mapGetters} from "vuex";
import PasswordField from "@/components/Auth/PasswordField";
import PaymentDetails from "@/components/Auth/V2/PaymentDetails";
import SignUpButton from "@/components/Auth/V2/SignUpButton";
import PrivacyAndTermsAgreement from "@/components/Auth/V2/PrivacyAndTermsAgreement";

export default {
    name: 'SignUpFlow',
    mixins: [Base],
    components: {
        PrivacyAndTermsAgreement,
        SignUpButton,
        PaymentDetails,
        PricingPlan,
        PasswordField,
    },
    data() {
        return {
            showNetworkActivityMessage: false,
            networkActivity: null,
            networkError: null,
            partyTime: false,
            showAlreadyLoggedInOptions: false,
            validate: false,
        }
    },
    computed: {
        ...mapGetters({
            signupPlan: 'signupPlan',
            signupPlanDuration: 'signupPlanDuration',
            account: 'currentAccount',
            signupPath: 'signupPath',
            termsAndPrivacyAcceptedDate: 'termsAndPrivacyAcceptedDate',
            appName: 'appName',
        }),
        hasAccount() {
            return this.account && this.account.id;
        },
        isCreationStep() {
            return !this.hasAccount && this.signupPath === 'trial-start';
        },
        isStartTrialStep() {
            return this.account && this.account.id && this.signupPath === 'trial-start';
        },
        isCreationValid() {
            // TODO: Probably get this into AccountDetails.vue
            // Who are we to question what is and isn't but us who dictate the code itself.
            return this.isNameComplete &&
                this.isEmailComplete &&
                this.isPasswordComplete &&
                this.isPasswordDecent &&
                this.termsAndPrivacyAcceptedDate;
        },
        isNameComplete() {
            // TODO: Probably get this into AccountDetails.vue
            return !this.validate || this.name !== '';
        },
        isEmailComplete() {
            // TODO: Probably get this into AccountDetails.vue
            return !this.validate || this.email !== '';
        },
        isPasswordComplete() {
            // TODO: Probably get this into AccountDetails.vue
            return !this.validate || this.password !== '';
        },
        isPasswordDecent() {
            // TODO: Probably get this into AccountDetails.vue
            return !this.validate || this.$refs.passwordField.passwordScore > 2;
        },
    },
    watch: {
        isStartTrialStep() {
            // Prevents the "Start Trial" CTA from getting stuck on "Planning Your Welcome Party..."
            if (this.isStartTrialStep) {
                this.networkActivity = false
            }
        },
        networkActivity() {
            if (!this.networkActivity) {
                this.partyTime = false;
                this.showNetworkActivityMessage = false;
            }
        }
    },
    mounted() {

        // Store the user's pricing plan preference in VUEX        
        if (this.$route.query && this.$route.query.plan) {
            this.$store.dispatch('setPlanAndFrequencyFromStripe', {stripeKey: this.$route.query.plan});

            // Removes querystring and prevents refreshing the page from resetting the user's state.
            window.history.replaceState({}, document.title, this.$route.path);
        }

        // Check that user doesn't already have an account when they come to the sign up path.
        if (!this.hasAccount) {
            // If they don't have an account, they haven't accepted terms.
            window.Events.$emit('resetTermsAndPrivacy');

            // Set the store so it knows where to redirect the user after account creation & login.
            this.$store.dispatch('setUserSignUpPath', {path: 'trial-start'});
        }

        // Delay showing logged in options as some users will get redirected automatically after a pause.
        setTimeout(() => this.showAlreadyLoggedInOptions = true, 1700);
    },
    methods: {
        async submitSignUpAction() {
            if (this.isCreationStep) {

                this.validate = true;
                window.Events.$emit('validateTermsAndPrivacyAgreement');

                if (this.isCreationValid) {
                    // Only proceed if validation passes.
                    this.createAccount();
                }

                return;
            }
            if (this.isStartTrialStep) {
                await this.startTrial();
            }
        },
        async startTrial() {

            // This prevents UI flickering if networkActivity resolves very quickly, e.g. validation errors.
            this.showNetworkActivityMessage = true;
            setTimeout(() => {
                if (this.showNetworkActivityMessage) {
                    this.networkActivity = "Saving..."
                }
            }, 50);

            let cardResult = false;
            cardResult = await this.$refs.paymentDetails.saveCard();
            if (!cardResult) {
                // Card error
                this.showNetworkActivityMessage = false;
                this.networkActivity = false;
                return;
            }

            let subscriptionResult = false;
            subscriptionResult = await this.requestSubscriptionChange();

            if (subscriptionResult && cardResult) {
                this.networkActivity = "Done!";
                await this.$store.dispatch('setUserSignUpPlan', {plan: false});
                await this.$store.dispatch('setUserSignUpPath', {path: null});

                await this.$router.push(`/welcome?success=true&plan=${subscriptionResult}`);
            }

            this.networkActivity = false;
        },
        requestSubscriptionChange() {

            //this.$emit('planSelected', this.selectedPlan);

            this.networkError = false;

            this.networkActivity = "Planning your welcoming party...";
            this.partyTime = true;

            let planArray = this.signupPlanDuration.id.split('_');
            let interval = planArray.pop();
            let planPackage = planArray.join('_');
            let request = {package: planPackage, interval}

            return this.$api.put(`/accounts/${this.account.id}/subscription`, request)
                .then(response => {
                    this.networkActivity = false;
                    let subscription = response.data;
                    this.$store.dispatch('addSubscriptionToAccount', {subscription, account: this.account});
                    this.$store.dispatch('updateAccount',
                        {account: {...this.account, plan: this.selectedPlan}});

                    return planPackage;

                }).catch(e => {
                    if (e.response && e.response.data && e.response.data.description) {
                        this.networkError = e.response.data.description
                    } else {
                        this.networkError = "Something went wrong processing your plan selection";
                    }
                    this.networkActivity = false;

                    return false;
                })
        },
    }
}
</script>
