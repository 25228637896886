<template>
    <form @submit.prevent style="width: 530px; max-width: calc(100vw - 20px)" @click.stop>
        <div class="d-flex gap-4">
            <fieldset class="">
                <fieldset class="mb-4" v-if="languageSupportsContentType">
                    <div class="">
                        <content-filter/>
                    </div>
                </fieldset>
                <fieldset  class="mb-4">
                    <div class="">
                        <sort-filter/>
                    </div>
                </fieldset>
                <fieldset  class="mb-4">
                    <div class="">
                        <age-filter/>
                    </div>
                </fieldset>
            </fieldset>
            <div>

                <fieldset v-if="!favorites" class="mb-4">
                    <div class="" style="min-width: 300px">
                        <sources-filter/>
                    </div>
                </fieldset>
                <fieldset v-if="features.tags" class="mb-4">
                    <tags/>
                </fieldset>
            </div>
        </div>
        <div class="mb-3">
            <filter-usages :filter-view-id="filterViewId"></filter-usages>
        </div>
        <div class="d-flex justify-content-end gap-2 form-footer">
            <button type="button"
                    @click="clearFilterViewSelection({})"
                    v-if="filterViewId && features.clearFilters"
                    class="btn btn-outline-secondary btn-sm">
                <i class="fa fa-close me-1" aria-hidden="true"></i>
                <span>Remove All Filters</span>
            </button>
            <button type="submit"
                    @click="resetFiltersToView"
                    v-if="dataHasChanged && filterViewId"
                    class="btn btn-outline-secondary btn-sm">
                <i class="fa fa-undo me-1" aria-hidden="true"></i>
                <span>Revert to Saved Values</span>
            </button>
            <button type="submit"
                    @click="$emit('saveExisting')"
                    v-if="dataHasChanged && filterViewId"
                    class="btn btn-outline-primary btn-sm">
                <i class="fa fa-save me-1" aria-hidden="true"></i>
                <span>Save and Overwrite</span>
            </button>
            <button type="submit" @click="$emit('saveNew')" :disabled="networkActivity"
                    class="btn btn-sm btn-primary">
                Save As
            </button>
        </div>
    </form>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import SourcesFilter from '../SuggestionFilters/Sources'
import GeographyFilter from '../SuggestionFilters/Geography'
import AgeFilter from "./Age"
import SortFilter from './Sort'
import ContentFilter from './Content'
import Tags from "@/components/FilteringAndSorting/SuggestionFilters/Tags";
import {languagesSupportingContentType} from "@/Constants";
import {Filters} from "@/models/Filters";
import {arrayHasValues} from "@/utilities/utils";
import FilterUsages from "../FilterUsages";

export default {
    name: "HorizontalFilterDropdowns",
    components: {
        FilterUsages,
        Tags,
        SourcesFilter, GeographyFilter, AgeFilter, SortFilter, ContentFilter,
    },
    data() {
        return {
            active: false
        }
    },
    computed: {
        ...mapGetters({
            filters: 'currentFilters',
            favorites: 'showFavoriteSuggestions',
            isLoadingSources: 'isLoadingSources',
            selectedSources: 'selectedSources',
            allTags: 'tags',
            features: 'features',
            hasSuggestions: 'hasSuggestions',
            currentTopic: 'currentTopic',
            selectedTags: 'selectedTags',
            account: 'account',
            filterViewId: 'currentFilterViewId',
            selectedFilters: 'currentFilterView',
            excludedSources: 'excludedSources',
            includeNewSources: 'includeNewSources',
            currentFilters: 'currentFilters',
            currentCollection: 'currentCollection',
            filterViews: 'filterViewOptions',
            required: 'required'
        }),
        dataToSave() {
            if (!this.currentFilters) return null;

            // This logic will handle if include new sources has not been set yet and default it to true.
            const includeNewSources = this.includeNewSources !== false;


            let includeLimit = !!(this.selectedFilters && this.selectedFilters.limit);
            let includeOffset = !!(this.selectedFilters &&  this.selectedFilters.offset);
            let filters = Filters.fromUi({
                ...this.currentFilters,
                includeNewSources,
                includeAllSources: includeNewSources && !arrayHasValues(this.excludedSources),
                limit: this.selectedFilters && this.selectedFilters.limit || null,
                offset: this.selectedFilters && this.selectedFilters.offset || 0,
                tags: this.selectedTags,
                excludes: this.excludedSources,
                sources: this.selectedSources,
                favorites: this.favorites,
                required: this.required,
            }, includeLimit, includeOffset)
            return filters.toApi()
        },
        selectedFiltersApi() {
            return this.selectedFilters && this.selectedFilters.toApi();
        },
        dataHasChanged() {
            try {
                if (!this.currentFilters || !this.selectedFilters) {
                    return false;
                }
                return this.selectedFilters &&
                    (JSON.stringify(this.selectedFilters.toApi()) !== JSON.stringify(this.dataToSave));
            } catch(e) {
                console.log(e)
                return null
            }
        },
        languageSupportsContentType() {
            return !this.topicLanguage || languagesSupportingContentType.includes(this.topicLanguage)
        },
        topicLanguage() {
            if (this.hasSuggestions.type !== 'topic') return null;
            let culture = this.currentTopic.culture;
            return Boolean(culture) && culture.split('-')[0]
        },
        age() {
            if (this.filters && this.filters.age == -1) {
                return 'Forever'
            }

            return this.filters.age + " " + this.$strings.plural('Day', this.filters.age);
        },
        evergreen() {
            return this.filters && this.filters.evergreen
        },
        selectedSourceCount() {
            return this.selectedSources ? this.selectedSources.length : 0
        },
        selectedTagObjects() {
            return this.selectedTags.map(tagKey => this.allTags.find(tag => tag && tag.key === tagKey)).filter(x => x)
        },
        imageOnly() {
            return this.filters.required && this.filters.required.includes('image')
        }
    },
    methods: {
        ...mapActions(['setUserPreference', 'loadPublishersFilters', 'filterViewSelected', 'resetFiltersToView',
            'loadFilterViewList', 'loadFilterView',
            'setIncludedSuggestionSourceList', 'setSelectedTags','toggleShowFavorites', 'setRequired', 'clearFilterViewSelection']),
        toggleFavorites() {
            this.$store.dispatch('toggleShowFavorites')
        }
    }
}
</script>

<style lang="scss" scoped>
/*@media screen and (min-width: 768px) {*/
/*    .filters-section {*/
/*        visibility: visible;*/
/*    }*/
/*}*/
.filters {
    position: relative;
    margin-left: auto;
    flex-wrap: wrap;
}

.filters a {
    font-size:var(--font-size-11)!important;
    text-transform: uppercase;
}

.filters > div {
    color: rgba(70, 70, 70, 0.8);
}

.dropdown-menu {
    padding: 10px;
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
    .filters a {
        font-size:var(--font-size-11);
    }
    //.filters > div {
    //    margin: 0 .25em 0 0;
    //}
    .filters > div b {
        font-size:var(--font-size-11);
    }
}

@media screen and (max-width: 768px) {
    .filters.btn-group.d-flex {
        flex-direction: column;
        width: 100%;
    }
    .filters.btn-group {
        flex-direction: column;
    }
    .filters.btn-group .btn-group .btn {
        border-radius: 0 !important;
        margin-left: 0px !important;
        margin-top: -1px !important;
    }
    .filters.btn-group .btn-group:first-child  .btn{
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
        margin-left: -1px !important;
    }
    .filters.btn-group .btn-group:last-child  .btn{
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
}

@media screen and (min-width: 768px) {
    .filters.btn-group.d-flex {
        flex-wrap: nowrap;
        flex-grow: 1;
    }
}

@media screen and (max-width: 850px) {
    .filters .btn {
        font-size: var(--font-size-10) !important;
    }
}

</style>

