<template>
    <div>
        <h4>
            AI Post Text Generation Custom Voices
        </h4>
        <hr/>
        <sidebar-menu-layout>
            <template #sidebar>
                <searchable-groupable-list :items="agentsList" @select="selectItem" :search-matches="search">
                    <template v-slot:default="item">
                        <div>{{item.name}}</div>
                        <div class="small text-muted text-truncate">{{item.agent_prompt_text}}</div>
                    </template>
                </searchable-groupable-list>
                <div>
                    <button class="btn-primary btn full-width" type="button" @click="add">Add Voice</button>
                </div>
            </template>
            <template #main>
                <div style="max-width: 500px">
                    <post-text-agent-form
                        v-if="currentAgent"
                        :value="currentAgent"
                        :account="currentAccount.id"
                        :addingNewAgent="addingNewAgent"
                        @saved="agentSaved"
                        @deleted="agentDeleted"
                        @fieldChanged="fieldChanged"
                    >
                    </post-text-agent-form>
                </div>
            </template>


        </sidebar-menu-layout>

<!--        <post-text-agent-form></post-text-agent-form>-->

    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import SearchableGroupableList from "@/components/Controls/SearchableGroupableList.vue";
import SidebarMenuLayout from "@/components/Layouts/SidebarMenuLayout.vue";
import PostTextAgentForm from "@/components/Settings/Team/PostTextAgentForm.vue";

export default {
    name:'PostTextAgents',
    components: {SidebarMenuLayout, SearchableGroupableList, PostTextAgentForm},
    data() {
        return {
            hasChanges: false,
            currentAgent: null,
            addingNewAgent: false,
    }},
    watch : {
    },
    computed : {
        ...mapGetters({
            currentAccount: 'currentAccount',
            agentsList: 'currentPostTextAgents'
        }),
    },
    methods: {
        ...mapActions({
            loadPostTextAgents: 'loadFilteredPostTextAgents'
        }),
        search(searchText, item) {
            return !searchText || item.name.toLowerCase().includes(searchText);
        },
        add() {
            this.currentAgent = {
                name: '',
                agent_prompt_text: ''
            }
            this.addingNewAgent = true;
        },
        selectItem(item) {
            console.log("* item", item)
            // if (this.hasChanges) {
            //     if (!confirm("You will lose changes are you sure?")) return;
            // }
            this.currentAgent = item;
            this.addingNewAgent = false;
            this.hasChanges = false;
        },
        async agentSaved() {
            this.hasChanges = false;
            await this.loadPostTextAgents(this.currentAccount);
            this.currentAgent = {
                name: '',
                agent_prompt_text: ''
            }
        },
        async agentDeleted() {
            await this.loadPostTextAgents(this.currentAccount);
            this.currentAgent = {
                name: '',
                agent_prompt_text: ''
            }
        },
        fieldChanged() {
            this.hasChanges = true;
        }
    },
    async mounted() {
        await this.loadPostTextAgents(this.currentAccount);
    }
}
</script>

<style lang="scss">

</style>
