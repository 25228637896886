import {StringUtils} from "../utilities/Strings";
// import {setShareProvider_} from "@/store/actions";
import moment from "moment";

export const connections = {

}

export class OAuthConnection {
    constructor(apiName, context) {
        this.$store = context.$store;
        this.$api = context.$api;
        this.apis = context.apis || context.$store.getters.externalApis;
        this.$route = context.$route;
        this.$router = context.$router;

        this.apiName = apiName;
        this.apiNameCaps = StringUtils.capitalize(this.apiName);
        this.apiDescription = StringUtils.capitalizeWords(this.apiName.replace('_', ' '));
        if (this.apiName === 'hubspot') this.apiDescription =  'HubSpot';
    }
    connectUrl() {
        return `https://${process.env.VUE_APP_API_BASE_URL}/integrations/${this.apiName}/authenticate?redirect_uri=${encodeURIComponent(this.returnUrl())}` ;
    }

    dataFromQuery(query) {
        let { state, code } = query;
        return { state, code, redirect_uri: this.returnUrl() };
    }

    authError(query) {
        return query.error;
    }

    integrationAccountData(team) {
        return this.$store.getters.externalApis[this.apiName][team.id]
    }

    integrationMeta() {
        return this.$store.getters.apisMeta[this.apiName]
    }

    async doAfterActions(accountData, accountId) {
        accountData = accountData || this.integrationAccountData();
        let meta = this.integrationMeta();
        console.log(accountData, meta)
        if (meta && meta.share_feature
        &&  accountData && accountData.share_feature && accountData.share_feature.activated) {
            if (confirm('Do you want to set the share button to use this integration?')) {
                await Promise.all([
                    this.$store.dispatch('setShareProvider', { account: {id: accountId}, shareProvider: this.apiName }),
                    this.$api.put(`/accounts/${accountId}/share-provider`, { share_provider: this.apiName })
                ]);
            }
        }
        console.log('OAuthConnection.doAfterActions')
        if (connections[this.apiName] && connections[this.apiName]) {
            let custom = new connections[this.apiName](this);
            if (custom.doCustomAfterActions) {
                await custom.doCustomAfterActions(accountData, accountId);
            }
        }
    }

    disconnect(team){
        this.$store.dispatch(`setPowerupDataForAccount`, {data : false, account : team, powerup: this.apiName});
        return this.$api.delete(`/accounts/${team.id}/${this.apiName}-settings`);
    }

    async connect(team, options){
        this.$store.dispatch('setOAuthError', {error: false, api: this.apiName});
        await this.$store.dispatch(`setAccountToLoadAfterOAuth`, {account : team, powerup: this.apiName});
        let newTabIfIframed = ['sniply', 'proofpoint', 'amplify', 'constant_contact', 'hubspot']
        if (options && options.iframed && newTabIfIframed.includes(this.apiName)) {
            this.setupNewWindowActions(team, options);
            window.open(this.connectUrl());
        } else
            window.location = this.connectUrl()
    }
    setupNewWindowActions(team, options) {
        if (window.localStorage)
            window.localStorage.setItem('closeAfterAuth', new Date().toISOString());
        let handleAfterNewWindowClose = () => {
            if (!document.hidden) {
                document.removeEventListener("visibilitychange", handleAfterNewWindowClose);
                this.refreshApiSettingsFromServer(team, options);
            }
        };
        document.addEventListener("visibilitychange", handleAfterNewWindowClose);
    }
    async refreshApiSettingsFromServer(team, options) {
        console.log('refreshApiSettingsFromServer')
        let response = await this.$api.get(`/accounts/${team.id}/${this.apiName}-settings`);
        let data = response.data;
        let token = response.data.access_token;
        console.log('setting auth data', !!token, team.id)
        await this.connectWithToken(token, team.id, data, []);
        if (options.afterOauthData){
            this.goToReturn(team.id, options.afterOauthData)
        }

    }
    apiData(team){
        return this.apis[this.apiName] && this.apis[this.apiName][team.id]
    }
    isConnected(team) {
        return !!this.apiData(team)
    }

    returnUrl() {
        let currentProtocol = window.location.protocol;
        let domain = window.location.hostname;
        let isLocal = domain.startsWith('localhost');
        let protocol = isLocal ? currentProtocol : 'https:';
        return `${protocol}//${window.location.host}/apis/${this.apiName}/authorize`
    }

    async postAuthData(account, returnData, afterActions){
        console.log('postAuthData')
        if (!account) return;

        this.setActivity(`Authorizing ${this.apiDescription} Account...`);

        let accountId = account.id;
        let error = this.authError(this.$route.query)

        if (error) {
            this.setActivity(false);
            this.$router.push('/settings?account='+accountId+'#integrations')
            return;
        }

        return this.$api
            .post(`/accounts/${accountId}/${this.apiName}-settings`, this.dataFromQuery(this.$route.query))
            .then(() => this.$api.get(`/accounts/${accountId}/${this.apiName}-settings`, {}))
            .then(async response => {
                console.log('post get complete')
                this.setActivity(false);
                let token = response.data.access_token;
                await this.connectWithToken(token, accountId, response.data, afterActions);
                this.goToReturn(accountId, returnData);
            }).catch( (e) => {
                this.setActivity(false);
                if (e.response)
                    this.setError(e.response.data.description);
                this.goToReturn(accountId, returnData);
                return Promise.reject(e);
            })
    }
    async connectWithToken(token, accountId, d, afterActions){
        console.log('OAuthConnection.connectWithToken')
        let data = {token, ...d};

        await this.$store.dispatch(`setPowerupDataForAccount`, {data, account : {id : accountId}, powerup: this.apiName});

        if (afterActions) {
            await Promise.all(afterActions.map(async a => {
                await a()
            }))
        }

        await this.$store.dispatch(`setAccountToLoadAfterOAuth`, {account : false, powerup: this.apiName});
        await this.doAfterActions(data, accountId)
    }

    goToReturn(accountId, returnData) {
        console.log('OAuthConnection.goToReturn')
        if (window.localStorage) {
            let closeAfterAuth = window.localStorage.getItem('closeAfterAuth');
            if (closeAfterAuth && moment().diff(closeAfterAuth, 'minutes') < 2) {
                window.close();
            }
        }
        if (returnData && returnData.url) {
            this.$router.push(returnData.url)
        }
        else {
            this.$router.push("/settings?account="+accountId+'#integrations')
        }

        if (returnData && returnData.actions) {
            returnData.actions.forEach(action => {
                window.Events.$emit(action[0], action[1])
            })
        }

        this.$store.dispatch('setAfterOAuthData', {});
    }

    setActivity(messageOrFalse) {
        this.$store.dispatch('setOAuthActivity', {activity: messageOrFalse, api: this.apiName})
    }

    setError(messageOrFalse) {
        this.$store.dispatch('setOAuthError', {error: messageOrFalse, api: this.apiName})
    }
}
