<template>
    <div>
        <h3 v-if="author" class="note-heading">
            <b v-text="authorName"/> left a note {{ showCreated }}
            <span v-if="suggestion && showTarget">on <a href="#" @click.prevent="readSuggestion">{{
                    suggestion.title
                }}</a></span>

        </h3>
        <div class="note-container" v-if="author && user">
            <div class="note-text" v-text="note.text"></div>
            <button @click="deleteNote()"
                    class="ms-auto btn btn-sm btn-white"
                    v-if="user.id == author.id && ! note.temporary">
                <i class="fa fa-times text-danger"></i>
            </button>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import gravatar from 'gravatar';
import moment from 'moment'

export default {
    props: ['note', 'showTarget'],
    data() {
        return {
            profileImage: false
        }
    },
    computed: {
        ...mapGetters({
            teamMembers: 'teamMembers',
            user: 'currentUser',
            suggestions: 'suggestionsCache',
            topics: 'topics',
            collections: 'collections'
        }),
        author() {
            return this.note.user ? this.teamMembers.find(t => this.note.user.includes(t.id)) : null;
        },
        gravatarImage() {
            return gravatar.url(this.author.email)
        },
        authorName() {
            if (!this.user) {
                return;
            }

            if (this.author.id == this.user.id) {
                return 'You'
            }
            return this.author.name
        },
        suggestion() {
            return this.note.suggestion ? this.suggestions.find(s => this.note.suggestion.includes(s.id)) : null;
        },
        showCreated() {
            return this.prettyDate(this.note.created);
        }
    },
    mounted() {
        this.checkForSuggestion();
    },
    methods: {
        checkForSuggestion() {
            if (!this.suggestion) {
                window.Events.$emit('CheckForNoteSuggestions');
            }
        },
        deleteNote() {
            this.$api.delete(`/notes/${this.note.id}`)
            this.$store.dispatch('deleteNote', {note: this.note});
        },
        readSuggestion() {
            this.$store.dispatch('setReadingSuggestion', {suggestion: this.suggestion});
        },
        setTopic() {
            let topic = this.topics.find(t => this.note.topic.includes(t.id))
            this.$store.dispatch('setCurrentTopic', {topic});
            this.$store.dispatch('setReadingSuggestion', {suggestion: false});
        },
        prettyDate(date) {
            return moment.utc(date).fromNow()
        }
    }
}
</script>

<style>
.note-heading {
    font-size: var(--font-size-13);
}

.note-container {
    background: rgb(224, 224, 224);
    border-radius: var(--border-radius);
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.note-text {
    font-size: 14px;
    white-space: pre;
}

.note-text p:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
}

.author-gravatar {
    border-radius: 18px;
    overflow: hidden;
    width: 35px;
    height: 35px;
}
</style>
