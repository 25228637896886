<template>
    <form @submit.prevent.stop="invite" class="m-3">
        <h4>Sniply Campaign Assign</h4>
        <hr>
        <label class="d-block">
            Campaign
            <sniply-campaign-select v-model="campaign" :campaigns="sniplyCampaigns"></sniply-campaign-select>
        </label>
        <label class="d-block">
            Collection
            <collection-topic-selector required type="colllection" cross-account v-model="collection" style="min-width: 300px"></collection-topic-selector>
        </label>
        <h5>Not yet functional</h5>
    </form>
</template>

<style>

</style>

<script>
    import SniplyCampaignSelect from "@/components/Integrations/Sniply/SniplyCampaignSelect";
    import CollectionTopicSelector from "@/components/Controls/CollectionTopicSelector";
    import {SniplyConnection} from "@/services/Sniply";
    import {mapGetters} from 'vuex'
    export default {
        components: {CollectionTopicSelector, SniplyCampaignSelect},
        computed: {
            Sniply() { return new SniplyConnection(this) },
            ...mapGetters({
                apis : 'externalApis',
                account: 'account'
            })
        },
        mounted() {
            this.loadSniplyCampaignList()
        },
        data() {
            return {
                campaign: false,
                sniplyCampaigns: [],
                sniplyCampaignsLoaded: false
            }
        },
        methods: {
            async loadSniplyCampaignList(fetchNext) {
                if (!this.apis['sniply'])  return Promise.resolve();
                if (fetchNext === undefined) this.sniplyCampaigns = [];

                let fetchFirst = (() => this.Sniply.fetchCampaigns(this.account));
                let campaigns = await (fetchNext || fetchFirst)();
                this.sniplyCampaigns = this.$sort.alphabetize([...this.sniplyCampaigns, ...campaigns.results]);

                if (campaigns.hasNext && this.sniplyCampaigns.length < campaigns.count)
                    await this.loadSniplyCampaignList(campaigns.next);
                else {
                    this.sniplyCampaignsLoaded = true;
                    this.fetchSniplyBrands();
                }
            },
            fetchSniplyBrands() {
                let brands = [...new Set(this.sniplyCampaigns.map(x=>x.brand))];
                Promise
                    .all(brands.map(brand =>
                        this.$api.get(`/accounts/${this.account.id}/sniply/brands/${brand}/`)))
                    .then(results => {
                        results.forEach(result => {
                            let brand = result.data;
                            this.sniplyCampaigns
                                .filter(campaign => campaign.brand === brand.id)
                                .forEach(campaign => {
                                    campaign.brandName = brand.name;
                                    campaign.brandPhotoUrl = brand.photo_url;
                                })
                        })
                    });
            },
        }
    }
</script>
