

<script>
import {hasNonWhitepsaceValue, hasValue} from "../../utilities/utils";
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            folders: 'folders',
            currentAccount: 'currentAccount',
            accountsWithCrossAccount: 'accountsWithCrossAccount'
        })
    },
    methods: {
        path(x) {
            let folders = this.folderNames(x).filter(hasNonWhitepsaceValue).map(x => ({
                icon: 'fa fa-folder-o',
                name: x
            }));
            let accountName = x.account ? this.formatAccountName(x) : '';
            let account = accountName ? {
                name: accountName,
                icon: 'fa fa-users'
            } : null;
            let path = [account, ...folders].filter(hasValue)
            return path
        },
        folder(collection) {
            return this.folders.find(f => collection.folder && collection.folder.includes(f.id))
        },
        parents(folder) {
            return folder.parents && folder.parents.map(p => this.folders.find(f => p.includes(f.id))).filter(hasValue) || []
        },
        ancestors(folder) {
            let parents = this.parents(folder);
            return [...parents.flatMap(p => this.ancestors(p)), ...parents]
        },
        folderNames(collection) {
            let folder = this.folder(collection);
            if (!folder) return [];
            let parents = this.ancestors(folder);
            let folders = [...parents, folder];
            return folders.map(folder => folder && folder.name).filter(x => x !== 'root');
        },
        formatAccountName(c) {
            return c.isCrossAccount ? c.accountInfo.name : ''
        },
        isCurrentAccount(collection) {
            return collection.account.includes(this.currentAccount.id);
        },
        isCrossAccount(collection) {
            return this.accountsWithCrossAccount.some(a => collection.account.includes(a))
        },
    }
}
</script>
