<template>
    <div>

        <topic-empty-state 
            v-if="type == 'topics'" 
            :stillBuilding="stillBuilding" 
            :busy="busy" 
            :showFavorites="showFavorites"
            :account-topics="accountTopics"
        />
        <collection-empty-state :collection="collection" v-if="type == 'collections'" :busy="busy" />
        
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import TopicEmptyState from './EmptyTopic'
import CollectionEmptyState from './EmptyCollection'

export default {
    props : ['urls', 'stillBuilding', 'busy'],
    components : {
        TopicEmptyState,
        CollectionEmptyState
    },
    computed : {
        ...mapGetters({
            showFavorites : 'showFavoriteSuggestions',
            topics : 'topics',
            collections : 'collections',
            hasSuggestions : 'hasSuggestions',
            suggestions : 'suggestions',
            type : 'suggestionViewerType',
            collection : 'currentCollection',
            account : 'currentAccount'
        }),
        accountTopics(){
            return this.topics.filter(t => t.account.includes(this.account.id));
        },
    }
}
</script>
