<template>

</template>

<script>
import CollectionDataEditor from "@/components/Collections/V2/CollectionDataEditor.vue";
import {mapGetters} from "vuex";

export default {
    name: "ApiKeyIntegrationSettingsController",
    mixins: [CollectionDataEditor],
    computed: {
        ...mapGetters({
            learnUrls: "learnUrls",
            currentAccount: "currentAccount",
        }),
        learnMoreUrl() {
            return this.learnUrls[this.name]
        }
    },
    data() {
        return {
            accountData: {},
            on: Boolean(this.accountData && this.accountData.openai_api_key)
        }
    },
    methods: {
        async accountDataLoad() {
            if(!this.accountMetadataKey) {
                throw('Error: No metadata key set for ApiKeyIntegrationSettingsController');
            }
            if (this.currentAccount.id) {
                let resp = await this.$api.get(`/accounts/${this.currentAccount.id}/data/${this.accountMetadataKey}`)
                if (resp.data) {
                    Object.assign(this, resp.data)
                }
                this.accountData = resp.data || {};
            } else if (this.defaultAccountValues) {
                Object.assign(this, this.defaultAccountValues)
                this.accountData = {};
            }
        },
    }
}
</script>

<style scoped>

</style>
