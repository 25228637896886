import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import * as actions from './actions'
import suggestion from './modules/suggestion'
import topics from './modules/topics'
import user from './modules/user'
import signup from './modules/signup'
import folders from './modules/folders'
import accounts from './modules/accounts'
import notes from "./modules/notes"
import teamMembers from "./modules/team-members"
import viewOptions from "./modules/viewOptions"
import preferences from "./modules/user-preferences";
import collections from "./modules/collections";
import filters from "./modules/filters";
import age from "./modules/filter-fields/age.js";
import cancelTokens from "./modules/cancel-token";
import publishers from "./modules/publishers";
import filterViews from "./modules/filter-views";
import suggestionSettings from "./modules/suggestion-settings";
import contentTemplates from "./modules/contentTemplates";
import galleries from "./modules/galleries";
import generalState from "./modules/general-state";
import suggestionManagement from "./modules/suggestion-management";
import appVersion from './modules/version';
import thirdPartyApis from "./modules/third-party-apis"
import config from './modules/config'
import sniply from './modules/integrations/sniply'
import postText from './modules/post-text'
import classifiers from "@/store/modules/content-policies"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const testing = process.env.NODE_ENV == 'testing'

const modules = {
    cancelTokens,
    suggestion,
    topics,
    user,
    signup,
    folders,
    accounts,
    notes,
    teamMembers,
    viewOptions,
    preferences,
    collections,
    suggestionManagement,
    appVersion,
    config,
    galleries,
    thirdPartyApis,
    generalState,
    filters,
    age,
    filterViews,
    publishers,
    suggestionSettings,
    contentTemplates,
    sniply,
    postText,
    classifiers,
};

var store = new Vuex.Store({
    actions,
    modules,
    strict: debug,
    plugins: [
        createPersistedState({
          // NOTE(casio): tmp workaround to skip persisting of the notes modal status `showAccountNotesReader`
          paths: [ ...Object.keys(modules).filter(m => m != 'notes'), 'notes.allNotes' ]
        })
    ]
})

// import devState from './dev-store'
if (debug){
    // store.replaceState(devState)
}

import state from './testing-state';

if (testing){
    store.replaceState(state)
}

export default store
