

<script>
    export default {
        computed: {
            navMode() {
                try {
                    return this.$route.query.nav || window.navMode || (window.navMode = (this.$route.query.nav || 'normal'));
                } catch(e) {
                    console.error('RouteContext.navMode', e)
                    return 'normal'
                }
            },
            navFull() { return this.navMode === 'normal'; },
            navMin() {return this.navMode === 'minimal'; },
            showAccountsMenu() {
                return this.navMode === 'normal';
            },
            showContentWorkflow() {
                return this.navMode === 'normal';
            },
            isEmbed() {
                return this.$route.query.embed || window.isEmbed || (window.isEmbed = (this.$route.query.embed || false));
            }
        },
        methods: {
            goToCollection() {
                if (this.$route.query.collection && this.$route.query.collection !== true) {
                    let collection = this.$store.getters.collections.find(c => c.id === this.$route.query.collection)
                    this.$store.dispatch('setCurrentCollection', {collection})
                    this.$store.dispatch('setSuggestionViewerType', {type : 'collections'});
                    return true
                }
                return false;
            },
            goToAccount() {
                let accountId = this.$route.query.account;
                let currentAccount = this.$store.getters.account;
                let allAccounts = this.$store.getters.allAccounts;
                if (accountId && currentAccount && currentAccount.id !== accountId && allAccounts && allAccounts.length) {
                    let account = allAccounts.find(c => c.id === accountId)
                    this.$store.dispatch('setCurrentAccount', {account})
                }
            }
        }
    }
</script>
