<template>
    <router-link class="btn btn-primary d-flex align-items-center text-decoration-none btn-back-to" to="/">
        <i class="fa fa-arrow-left me-3 bottom-1"></i>
        <has-content-icon :has-content="hasContent" :type="type" class="back-to-icon light-variation me-1"/>
        <div class="back-to-text text-truncate" :title="name">{{ name }}</div>
    </router-link>
</template>

<script>
import {mapGetters} from "vuex";
import HasContentIcon from "./HasContentIcon";

export default {
    name: "BackToButton",
    components: {HasContentIcon},
    computed : {
        ...mapGetters({
            type: 'suggestionViewerType',
            topic: 'currentTopic',
            collection: 'currentCollection',
            account: 'currentAccount'
        }),
        hasContent() { return this.type === 'collections' ? this.collection : this.topic},
        name() {
            switch (this.type) {
                case 'topics':
                    return this.topic ? this.topic.name : 'Topic';
                case 'collections':
                    return this.collection ? this.collection.name : "Collection";
                default:
                    return 'Content'
            }
        }
    }
}
</script>

<style scoped>
    a {
        background: var(--blue) !important;
        border-color: var(--blue) !important;
    }
    .back-to-icon {
        --sizing: .9em;
        font-size: var(--font-size-14);
        width: var(--sizing);
        height: var(--sizing);
    }
    @media screen and (max-width: 575.98px) {
        .btn.btn-primary {
            font-size: var(--font-size-12) !important;
        }
    }
</style>
<style lang="scss">
// Do not scope or these won't work.
.back-to-icon {
    .path-stroke {
        stroke: #fff;
    }
    .path-fill {
        fill: #fff;
    }
}
.back-to-text {
    max-width: 20vw!important;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .back-to-text {
        max-width: 35vw!important;
    }
}
</style>
