<script>

import { mapGetters } from 'vuex';
import axios from 'axios';
import {powerups} from '@/Constants';
import apiStore from '@/store/modules/third-party-apis'
import {isBrowserExtension} from "@/services/Context";
import {extensionHomeRoute} from "@/services/BrowserExtension";
import {getFeatureConfig, configCatUser} from '@/utilities/ConfigCat'
// import {UcAccountSplitClient} from "@/utilities/split";
const apiActions = apiStore.actions;
const expect404s = e => {
    if (e.response.status !== 404)
        throw e;
};

export default {

    props : [],
    computed : {
        ...mapGetters({
            topics : 'topics',
            accounts : 'allAccounts',
            folders : 'folders',
            collections : 'collections',
            users : 'teamMembers',
            user : 'currentUser',
            account : 'currentAccount',
            topic : 'currentTopic',
            inviteToken: 'inviteToken',
            notes : 'notes',
            signupPath: 'signupPath',
        }),
        firstAccountTopic(){
            if (this.account){
                let topics = this.topics.filter(t => t.account.includes(this.account.id))
                if (topics.length){
                    return topics[0];
                }
            }
        }
    },
    data(){
        return {
            primaryAccount : false,
            primaryAccountLoaded: false,
            primaryAccountLoadedSucceeded: () => false,
            primaryAccountLoadedFailed: () => false,
            rootFolder : false,
            firstTopic: false,
            cancelTokens: [],
            primaryAccountTopics : []
        }
    },
    watch : {
        user(){
            this.primaryAccount = false;
            this.rootFolder = false;
            this.firstTopic = false;
            this.primaryAccountTopics = [];

            if (this.user){
                this.initialize();
            }

            this.cancelPreviousRequests();

        },
        primaryAccountTopics(current){
            this.setCurrentTopicAndGoToMainScreen(current)
        }
    },
    mounted(){
        if (this.user){
            this.initialize()
        }

        window.Events.$on('FetchAndLoadAccount', (accountId) => {
            this.fetchGlobals();
            this.fetchAccount("/accounts/"+accountId).then(() => {
                console.log("does this ever run?");
            });

        })

        window.Events.$on('ReloadUserAccounts', () => {
            this.initialize()
        })

        window.Events.$on('FetchAccountNotes', (e) => {
            this.$store.dispatch('beginDownloadingNotes')
            this.fetchNotes(e.accountId).finally(() => {
                // NOTE(casio): as we're not yet able to determine exactly when related suggestions have been downloaded,
                // we'll temporarily give this 2 seconds for downloading suggestions, hoping this will be acceptable for most customers
                // TODO(casio): fix while moving notes fetching to actions completely
                setTimeout(() => this.$store.dispatch('completeDownloadingNotes'), 2000 /* 2sec*/ );
            })
        })
    },
    methods : {
        initialize() {
            this.primaryAccountLoaded = new Promise((resolve, reject) => {
                this.primaryAccountLoadedSucceeded = resolve;
                this.primaryAccountLoadedFailed = reject;
            });
            // NOTE(casio): both these actions are unknown - can be removed?
            // this.$store.dispatch('allAccountsComplete', false);
            // this.$store.dispatch('primaryAccountLoaded', false);
            this.fetchGlobals();
            this.fetchAccounts();
        },
        cancelPreviousRequests(){
            this.cancelTokens.forEach(token => {
                token.cancel();
            })
            this.cancelTokens = [];
        },
        async fetchGlobals() {
            this.integrations = this.fetchIntegrations();
            return { integrations: this.integrations }
        },
        async fetchIntegrations() {
            let resp = await this.$api.limit.get('/integrations?meta=true');
            let integrations = resp.data;
            await this.$store.dispatch(apiActions.setIntegrationMetaAll.name, { integrations });
            return integrations;
        },
        async fetchFeatureData(account) {
            let configUser = configCatUser(this.$store.state.user.user, account)
            const featureData = await getFeatureConfig(configUser);
            await this.$store.dispatch('setFeatureData', {accountId: account.id, featureData});
        },
        fetchSubscription: function (account) {
            return this.$api.limit.get('/accounts/' + account.id + '/subscription')
                .then(resp => {
                    this.$store.dispatch('addSubscriptionToAccount', {subscription: resp.data, account});
                })
                .catch(() => {

                });
        },
        fetchAccountIntegrationSettings(account) {
            return this.integrations.then(integrations => {
                let integrationList = [...integrations.map(i => i.name), ...powerups];
                let loadPowerup = powerup => this.loadAccountPowerupSettings(powerup, account)
                return Promise.all(integrationList.map(loadPowerup));
            });
        },
        fetchAccount(accountUrl){
            var axiosSource = axios.CancelToken.source()
            var cancelToken = axiosSource.token;

            this.cancelTokens.push(axiosSource);

            return this.$api.limit.get(accountUrl, {cancelToken} ).then(async response => {

                let account = response.data;

               let featureAndSubscriptionLoaded = Promise.all([
                   this.fetchSubscription(account).catch(expect404s).catch(console.error),
                   this.fetchFeatureData(account).catch(e => console.error(e)),
               ]);

                this.$store.dispatch('completeDownloadingAccount', {account});

                let userPrimary = this.user && this.user.default_account;
                let deviceDefault = (JSON.parse(localStorage.getItem('defaultAccounts')||'{}')||{})[this.user&&this.user.id];
                let defaultAccount = deviceDefault || userPrimary;
                let accountToLoad = this.$route.query.account || defaultAccount
                let isAccountToLoad = accountToLoad && accountToLoad.includes(account.id);
                if (isAccountToLoad) {
                    this.primaryAccount = account;
                    if ( ! this.account){
                        this.$store.dispatch('setAccount', {account: account});
                    }
                } else {
                    await this.primaryAccountLoaded;
                }

                await featureAndSubscriptionLoaded;

                let integrationsLoaded = this.fetchAccountIntegrationSettings(account);
                let dependenciesLoaded = Promise.all([
                    this.fetchTopics(account.id),
                    this.fetchFolders(account.id),
                    this.fetchUsers(account.id),
                    this.fetchCollections(account.id),
                ]);

                let accountAdded = await this.$store.dispatch('addAccount', {account});

                await Promise.all([dependenciesLoaded, integrationsLoaded, accountAdded].map(x => x.catch(console.error)));

                if (isAccountToLoad) {
                    this.primaryAccountLoadedSucceeded(account);
                    // NOTE(casio): this action is unknown - remove?
                    // this.$store.dispatch('primaryAccountLoaded', true);
                }
                return account;

            })
            .catch(this.handleError)
        },
        loadAccountPowerupSettings(powerup, account) {
            return this.$api.limit.get(`/accounts/${account.id}/${powerup}-settings`).then(response => {
                let data = response && response.data;
                let token =  data && data.access_token;
                this.$store.dispatch('setPowerupDataForAccount', {data : token ? {token, ...data} : false, account, powerup});
            }).catch( () => {
                //No Settings Found
            })
        },
        fetchAccounts(){

            // Determine which account we're getting first
            // It will be either the user's main account, or it'll
            // have been provided in the URL

            if ( ! this.user){
                return;
            }

            return this.$api.limit.get('/accounts').then(response => {
                let urls = response.data;

                let allAccountsComplete = Promise.all(urls.map(async accountUrl => {

                    this.$store.dispatch('beginDownloadingAccount', {url : accountUrl});

                    let account = await this.fetchAccount(accountUrl);

                    this.$store.dispatch('completeDownloadingAccount', {account});
                    return account;
                }));

                // NOTE(casio): this action is unknown - remove?
                // this.$store.dispatch('allAccountsComplete', true);

                return allAccountsComplete;
            }).catch(this.handleError)
        },
        fetchNotes(accountId){
            var axiosSource = axios.CancelToken.source()
            var cancelToken = axiosSource.token;
            this.cancelTokens.push(axiosSource);

            return this.$api.limit.get(`/accounts/${accountId}/notes`, {cancelToken, ignoreCache: true})
            .then( response => { this.handleUrls(response.data, 'note', accountId, this.notes); } )
            .catch(this.handleError);
        },
        fetchTopics(accountId){
            var axiosSource = axios.CancelToken.source()
            var cancelToken = axiosSource.token;
            this.cancelTokens.push(axiosSource);
            return this.$api.limit.get(`/accounts/${accountId}/active-topics`, {cancelToken, ignoreCache: true})
            .then( response => {
                let topicUrls = response.data
                this.handleUrls(topicUrls, 'topic', accountId, this.topics);
                if (this.primaryAccount && this.primaryAccount.id == accountId){
                    this.primaryAccountTopics = topicUrls;
                    if (!topicUrls || !topicUrls.length)
                        this.setCurrentTopicAndGoToMainScreen([]);
                }
            } )
            .catch(e => {
                this.setCurrentTopicAndGoToMainScreen([]);
                this.handleError(e)
            });
        },
        fetchFolders(accountId){
            var axiosSource = axios.CancelToken.source()
            var cancelToken = axiosSource.token;
            this.cancelTokens.push(axiosSource);

            return this.$api.limit.get(`/accounts/${accountId}/folders`, {cancelToken, ignoreCache: true})
            .then( response => { this.handleUrls(response.data, 'folder', accountId, this.folders)} )
            .catch(this.handleError);
        },
        fetchUsers(accountId){
            var axiosSource = axios.CancelToken.source()
            var cancelToken = axiosSource.token;
            this.cancelTokens.push(axiosSource);

            return this.$api.limit.get(`/accounts/${accountId}/users`, {cancelToken, ignoreCache: true})
            .then( response => {
                this.handleUrls(response.data, 'user', accountId, this.users)
            })
            .catch(this.handleError);
        },
        fetchCollections(accountId){
            var axiosSource = axios.CancelToken.source()
            var cancelToken = axiosSource.token;
            this.cancelTokens.push(axiosSource);

            return this.$api.limit.get(`/accounts/${accountId}/collections`, {cancelToken, ignoreCache: true})
            .then(response => { this.handleUrls(response.data, 'collection', accountId, this.collections)} )
            .catch(this.handleError);
        },
        handleUrls(urls, objectType, accountId, objects){
            let ids = urls.map(url => url.substr(url.lastIndexOf('/') + 1));
            let itemsToRemove = objects.filter(t => t).filter(t => ! t.account || (t.account.includes(accountId) && ids.indexOf(t.id) === -1));
            let itemsToUpdate = ids.filter(id => itemsToRemove.indexOf(id) === -1)

            if (objectType == 'note'){
                itemsToUpdate.forEach(itemId => {
                    let url = urls.find(item => item.includes(itemId));
                    this.fetchItem(url, objectType, 'add' + this.$strings.capitalize(objectType), accountId);
                })
            }
            itemsToRemove.forEach(item => {
                this.removeItem(item, objectType, 'remove' + this.$strings.capitalize(objectType));
            })
        },
        fetchItem(url, type, action, accountId){
            var axiosSource = axios.CancelToken.source()
            var cancelToken = axiosSource.token;
            this.cancelTokens.push(axiosSource);

            return this.$api.limit.get(url, {cancelToken})
            .then(response => {
                var object = {};
                object[this.$strings.plural(type, 1)] = {...response.data, account : accountId};
                this.$store.dispatch(action, object);
                if (type == 'user'){
                    this.$store.dispatch('setRoleForUser', object );
                }
                if (type == 'note'){
                    window.Events.$emit('CheckForNoteSuggestions');
                }
                if (type == 'topic' && accountId == this.primaryAccount.id){
                    var topics = this.primaryAccountTopics
                    topics.splice(topics.indexOf(url), 1);
                    this.primaryAccountTopics = topics;
                }

            }).catch( () => {
                // console.error(e)
            })
        },
        removeItem(item, type, action){
            var object = {}
            object[this.$strings.plural(type, 1)] = item;
            this.$store.dispatch(action, object);
        },
        handleError(){
        },

        setCurrentTopicAndGoToMainScreen(current) {
            var route = "/"

            if ( ! this.primaryAccount || this.topic){
                return;
            }

            if (current.length == 0) {

                if (this.firstAccountTopic) {
                    let topic = this.firstAccountTopic;
                    this.$store.dispatch('setCurrentTopic', {topic});
                } else if (this.signupPath  && this.signupPath !== '') {
                    route = `/${this.signupPath}`;
                } else {
                    route = "/welcome"
                }

                if (isBrowserExtension()) {
                    route = extensionHomeRoute()
                }

                if (!this.inviteToken && this.$route.path !== route) {
                    this.$router.push(route);
                }
            }
        }
    },
    render(h){
        return h();
    }
}
</script>

<style>
</style>
