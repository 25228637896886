<script setup>

import InputTypeFile from "@/utilities/InputTypeFile.vue";
import {appApi} from "@/init/ApiInit"
import {ref, computed} from "vue";
import * as toastr from "toastr";
import store from "@/store";
const file = ref(null);
const preview = ref(null)
const account = computed(() => store.getters.account)
async function change(event) {
    console.log('change')
    file.value = event.target.files[0];
    preview.value = await file.value.text();
}

async function submit(){
    const url = `/accounts/${account.value.id}/import`;
    const headers = {"Content-Type": file.value.type}
    const resp = await appApi.post(url, preview.value, {headers});
    if (resp.status >= 200 && resp.status < 300) {
        toastr.success("Uploaded! You may need to log out and back in to see changes.")
    } else {
        toastr.error(`Failed! ${JSON.stringify(resp.data)}`)
    }
}
</script>

<template>
    <form @submit.prevent="submit">
        <input-type-file accept="application/json,application/yaml,text/yaml,application/x-yaml" name="importFile" file-type-label="File" @changed="change"/>
        <button type="submit" class="btn btn-primary" v-if="file">Upload</button>
    </form>
</template>

<style scoped>

</style>
