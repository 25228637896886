import * as types from '../mutation-types'

const state = {
    auth_token: false,
    user : false,
    signupPlan : false,
    signupPath : '', // 2022 version is 'trial-start'; 2021 version is 'settings#subscription';
    invite_token: false
}

const getters = {
    isUpContentPowerUser: state => state.user && state.user.is_upcontent_power_user,
    currentUser : state => state.user,
    authToken : state => state.auth_token,
    signupPlan : state => state.signupPlan,
    signupPath : state => state.signupPath,
    inviteToken : state => state.invite_token
}

const actions = {

}

const mutations = {

    [types.SET_USER_SIGNUP_PLAN](state, {plan}){
        state.signupPlan = plan
    },
    [types.SET_USER_SIGNUP_PATH](state, {path}){
        state.signupPath = path
    },
    [types.USER_DID_LOG_IN](state, {user, token}){
        state.auth_token = token
        state.user = user;
    },
    [types.SET_AUTH_TOKEN](state, {token}){
        state.auth_token = token
    },
    [types.SET_INVITE_TOKEN](state, { token }) {
        state.invite_token = token
    },
    [types.USER_DID_LOG_OUT](state){
        state.auth_token = false;
        state.user = false;
        window.sessionStorage.clear();
        let keep = ['defaultAccounts', 'deviceShare'].reduce((result, key) => ({...result, [key]: localStorage.getItem(key) }), {});
        // let remove = ['requestCache', 'vuex', 'intercom-state'];
        localStorage.clear();
        state.signupPlan = false;
        state.signupPath = '';
        Object.keys(keep).forEach(k => localStorage.setItem(k, keep[k]));
    },
    [types.UPDATE_USER_PROFILE](state, {payload}){
        let key = payload.key;
        let value = payload.value
        state.user[key] = value
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
