<script setup>

import Modal from "@/components/Controls/Modal.vue";
import {defineProps, ref, watch, defineExpose} from "vue"
import {unique} from "@/utilities/Arrays";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({open: Boolean});

const privacy = ref('opened');
const importText = ref('');
const toImport = ref([]);
const completed = ref([]);
const errors = ref([]);
const modalComponent = ref(null);

watch(() => props.open, () => {
    if (props.open) modalComponent.value.show()
});

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const doNext = async () => {
    const next = toImport.value.pop();
    if (!next) return;
    const accountId = window.$app.store.getters.account.id;
    try {
        await window.$app.api.post(`/accounts/${accountId}/amplify/topics`, {name: next, privacy: privacy.value});
        completed.value.push(next);
    } catch (error) {
        errors.value.push(next);
    }
    await sleep(1000)
    await doNext();
}
const start = () => {
    toImport.value = importText.value.split('\n').filter(x => x).filter(unique());
    completed.value = [];
    doNext();
    importText.value = ''
}

const show = () => modalComponent.value.show();
defineExpose({show});

</script>

<template>
    <Modal ref="modalComponent">
        <template v-slot:header>Amplify Topic Import</template>
        <label class="full-width">
            Privacy
            <select v-model="privacy" class="form-select">
                <option>opened</option>
                <option>closed</option>
            </select>
        </label>
        <label class="full-width">
            Topic Names
            <textarea v-model="importText" class="form-control">
            </textarea>
            <div class="text-muted small">Enter the name of each Amplify topic you want to add separated by a new line</div>
        </label>
        <button class="btn btn-primary full-width mb-4" type="button" @click="start" :disabled="toImport.length">Start</button>
        <div class="row">
            <div class="col col-4">
                <strong>To Do</strong>
                <div v-for="(item, index) in toImport" :key="item" :class="{'fw-bold': index === 0, 'text-muted': index !== 0}">
                    <i class="fa fa-regular fa-circle" />
                    {{item}}
                    <spinner v-if="index === 0" class="d-inline"/>
                </div>
            </div>
            <div class="col col-4">
                <strong>Done</strong>
                <div v-for="item in completed" :key="item" class="text-success">
                    <i class="fa fas fa-check-circle" /> {{item}}
                </div>
            </div>
            <div class="col col-4">
                <strong>Errors</strong>
                <div v-for="item in errors" :key="item" class="text-danger">
                    <i class="fa fas fa-exclamation-circle text-danger" /> {{item}}
                </div>
            </div>

        </div>

    </Modal>
</template>

<style scoped>

</style>
